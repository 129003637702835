// frontend/src/actions/pdfActions.js
import axios from 'axios';
import {
    DOWNLOAD_PDF_REQUEST,
    DOWNLOAD_PDF_SUCCESS,
    DOWNLOAD_PDF_FAILURE
} from '../constants/pdfConstants';

const downloadPdf = async (url, data, dispatch) => {
    try {
        dispatch({ type: DOWNLOAD_PDF_REQUEST });

        const response = await axios.post(url, data, {
            responseType: 'blob'
        });

        const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = pdfUrl;
        a.download = 'file.pdf';
        a.click();

        dispatch({ type: DOWNLOAD_PDF_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Failed to generate PDF:', error);
        dispatch({ type: DOWNLOAD_PDF_FAILURE, payload: error });
    }
};

export const staffListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/staff_list/${code}`, data, dispatch);

export const staffIdCards = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/staff_id_cards/${code}`, data, dispatch);

export const staffReport = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/staff_report/${code}`, data, dispatch);

export const furnitureListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/furniture_list/${code}`, data, dispatch);

export const givenFurnitureListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/given_furniture_list/${code}`, data, dispatch);

// education department
export const classesListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/classes/${code}`, data, dispatch);

export const resultPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/result/${code}`, data, dispatch);

export const markSheetPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/mark_sheet/${code}`, data, dispatch);

export const signSheetPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/sign_sheet/${code}`, data, dispatch);

export const admitCardtPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/admit_card/${code}`, data, dispatch);

export const admitFormPdf = (code) => (dispatch) =>
    downloadPdf(`api/pdf/education/admit_form/${code}`, '', dispatch);

// hostel pdf downloads
export const reportedStudentListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/hostel/reported_students/${code}`, data, dispatch);

export const hostelAttnRegisterPdf = (code, data) => (dispatch) =>
    downloadPdf(`api/pdf/hostel/attn_register/${code}`, data, dispatch);

export const hostelReportRegisterPdf = (code, data) => (dispatch) =>
    downloadPdf(`api/pdf/hostel/report_register/${code}`, data, dispatch);

// account pdf downloads
export const receiptListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/receipt_list/${code}`, data, dispatch);

export const receiptsPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/receipts/${code}`, data, dispatch);

export const voucherListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/voucher_list/${code}`, data, dispatch);

export const vouchersPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/vouchers/${code}`, data, dispatch);

export const countingPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/monthly_count/${code}`, data, dispatch);

export const countingReportPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/report/${code}`, data, dispatch);

export const salaryListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/salary_list/${code}`, data, dispatch);

export const salaryStatementPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/salary_statement/${code}`, data, dispatch);

export const salarySheetPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/salary_sheet/${code}`, data, dispatch);

export const collectionSheet = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/collection/${code}`, data, dispatch);

export const bookListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/library/book_list/${code}`, data, dispatch);

export const barcodePDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/library/barcode_of_books/${code}`, data, dispatch);

export const totalBooksCountPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/library/total_books_count/${code}`, data, dispatch);

export const providedBooksListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/library/provided_books/${code}`, data, dispatch);

export const sellingProductListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/others/selling_products/${code}`, data, dispatch);

export const soldProductListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/others/sold_products/${code}`, data, dispatch);

export const donorListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/donation/donor_list/${code}`, data, dispatch);

export const donationListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/donation/donation_list/${code}`, data, dispatch);

export const feesListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/boarding/fees/${code}`, data, dispatch);

export const userListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/auth/user_list/${code}`, data, dispatch);

export const staffReportListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/staff_report_list/${code}`, data, dispatch);

export const studentListPdf = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/student_list/${code}`, data, dispatch);

export const approvedReceiptBooksPDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/account/aproved_book_list/${code}`, data, dispatch);

export const studentIdCards = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/student_id_cards/${code}`, data, dispatch);

export const providedBooksFormPDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/library/provided_books_form/${code}`, data, dispatch);

export const staffAttendancePDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/staff_attendance_list/${code}`, data, dispatch);

export const leavesListPDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/leaves_list/${code}`, data, dispatch);

export const dutyListPDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/adminis/duty_list/${code}`, data, dispatch);

export const routineListPDF = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/routine_list/${code}`, data, dispatch);

export const certification = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/certification/${code}`, data, dispatch);

export const clearance = (data, code) => (dispatch) =>
    downloadPdf(`api/pdf/education/clearance/${code}`, data, dispatch);