// React and Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import {
    TextField, Grid, Box, Container, MenuItem, IconButton, OutlinedInput,
    InputLabel, InputAdornment, FormControl, Typography, Paper, CardMedia,
    Menu, Checkbox, FormControlLabel, Link, Chip, Avatar, Stack, Autocomplete
} from '@mui/material';

// Material UI Icons
import { Visibility, VisibilityOff, Language } from '@mui/icons-material';

// Custom Components and Actions
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { countries, getAcademicTypes } from '../../constants/commonContstants';
import { newAcademy, updateAcademy } from '../../actions/superAdminActions';
import { useSnackbar } from 'notistack';
import useAuth from '../hooks/UseAuth';
// import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';

// Redux Constants
import { CLEAR_ERRORS, NEW_ACADEMY_RESET, UPDATE_ACADEMY_RESET } from '../../constants/superAdminConstants';
import { MetaData } from '../utils/metaData';
import { changeLanguage } from '../hooks/i18n';
import { loadUser, logout } from '../../actions/authActions';

export default function REGISTERNEWACADEMY({ isUpdating }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const academicTypes = getAcademicTypes(t);
    const [checked, setChecked] = useState(true);
    const [logoPreview, setLogoPreview] = useState('');
    const [calligraphyPreview, setCalligraphyPreview] = useState(null);
    const [academicPhotoPreview, setAcademicPhotoPreview] = useState(null);

    const {
        code,
        type,
        email,
        academicName,
        academicAddress,
        englishName,
        englishAddress,
        academicMobile,
        academyType,
        country,
        logo,
        calligraphy,
        academyPhoto,
        numberOfStudents
    } = useAuth();

    // const [step, setStep] = useState(1);
    // const [otp, setOtp] = useState(new Array(6).fill(""));
    // const inputRefs = useRef([]);

    const formRef = useRef(null);

    // getting the value from resux according to the id for using by default
    const { isSaved, loading, error } = useSelector(state => state.newAcademy);
    const { loadingAction, isUpdated } = useSelector(state => state.academy);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            name: '',
            address: '',
            englishName: '',
            englishAddress: '',
            academyType: '',
            numberOfStudents: '',
            mobile: '',
            email: '',
            country: '',
            logo: '',
            calligraphy: '',
            academicPhoto: ''
        },
    });

    useEffect(() => {
        if (isUpdating !== undefined && academicName !== undefined) {
            reset({
                name: isUpdating ? academicName : '',
                address: isUpdating ? academicAddress : '',
                englishName: isUpdating ? englishName : '',
                englishAddress: isUpdating ? englishAddress : '',
                academyType: isUpdating ? academyType : '',
                numberOfStudents: isUpdating ? numberOfStudents : '',
                mobile: isUpdating ? academicMobile : '',
                email: isUpdating ? email : '',
                country: isUpdating ? country : '',
            });
        }
    }, [isUpdating, academicName, academicAddress, englishName, englishAddress, academyType, numberOfStudents, academicMobile, email, country, reset]);

    useEffect(() => {
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(loadUser(code))
            dispatch({ type: UPDATE_ACADEMY_RESET })
        }
        if (logo) {
            setLogoPreview(logo);
        }
        if (calligraphy) {
            setCalligraphyPreview(calligraphy);
        }
        if (academyPhoto) {
            setAcademicPhotoPreview(academyPhoto);
        }
    }, [dispatch, t, enqueueSnackbar, isUpdated, logo, calligraphy, academyPhoto, code])


    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (!isUpdating) { // for clear localstorage and cookies from the browser
            localStorage.clear();
            dispatch(logout(code))
        }

        if (isSaved) {
            dispatch({ type: NEW_ACADEMY_RESET })
            navigate('/login');
            // setStep(2)
        }

        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch({ type: CLEAR_ERRORS })
        }
    }, [dispatch, error, isSaved, enqueueSnackbar, t, navigate, code, isUpdating])

    // handle submit
    const onSubmit = (data) => {
        // password confirmation
        if (data.password !== data.cpassword) {
            return enqueueSnackbar(t('notConfirmedPassword'), { variant: 'error' });
        }

        // result is String
        if (data.mobile.length >= 30) {
            return enqueueSnackbar(t('checkMobile'), { variant: 'error' });
        }

        // const expirationTime = dayjs().add(10, 'minute').toDate(); // Code valid for 10 minutes

        // Create FormData object
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            if (key === 'logo' || key === 'calligraphy' || key === 'academicPhoto') {
                if (data[key] instanceof File) {
                    formData.append(key, data[key]);
                }
            } else {
                formData.append(key, data[key]);
            }
        });

        // store academy type to the local storage
        const basics = JSON.parse(localStorage.getItem('basics')) || {};
        basics.academyType = data.academyType; // Update the academyType property
        localStorage.setItem('basics', JSON.stringify(basics));

        if (isUpdating) {
            dispatch(updateAcademy(data, code));
        } else {
            dispatch(newAcademy(data));
        }
    };

    const lbls = ['name', 'address', 'englishName', 'englishAddress', 'country', 'mobile', 'academyType', 'numberOfStudents', 'email'];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLang = (lng) => {
        changeLanguage(lng);
        setAnchorEl(null);
    };

    // ######### DONT DELETE THIS #########
    // const handleChange = (element, index) => {
    //     if (isNaN(element.value)) return;

    //     setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //     // Move to the next input box if filled
    //     if (element.value !== "" && index < inputRefs.current.length - 1) {
    //         inputRefs.current[index + 1].focus();
    //     }
    //     // Move to the previous input box if deleted
    //     else if (element.value === "" && index > 0) {
    //         inputRefs.current[index - 1].focus();
    //     }
    // };

    // const verifyCode = () => {
    //     const email = getValues('email')
    //     const data = {
    //         otp: otp
    //     }
    //     dispatch(emailVarify(data, email));
    // };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const PasswordField = ({ name, label }) => (
        <Controller
            name={name}
            control={control}
            defaultValue={""}
            render={({ field }) => (
                <OutlinedInput
                    {...field}
                    type={showPassword ? 'text' : 'password'}

                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t(label)}
                    required
                />
            )}
        />
    );

    return (
        <>
            <Container maxWidth={"sm"} sx={{
                borderRadius: '10px',
                height: !isUpdating ? '100vh' : '',
                alignItems: 'center',
                display: 'flex',
                // width: '600px',
                marginLeft: isUpdating ? '-20px' : ''
            }}>
                <MetaData title={!isUpdating ? 'REGISTER ACADEMY' : 'ACADEMIC INFORMATION'} />
                <Box component="form" onSubmit={handleSubmit(onSubmit)} ref={formRef} encType="multipart/form-data">
                    {isUpdating ?
                        <>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {t('academicLogo')}
                                </Typography>
                            </Box>
                            <Paper
                                sx={{
                                    padding: '1.2rem',
                                    borderRadius: '8px',
                                    marginBottom: '1.5rem',
                                    gap: '1rem'
                                }}
                            >
                                <Avatar sx={{ width: '80px', height: '80px', display: 'flex', margin: 'auto', marginBottom: '1rem' }} alt="LOGO" src={logoPreview} />
                                <Box>
                                    <Typography variant='body2'>
                                        {t('logoGuide')}
                                    </Typography>
                                    <Stack direction="row" spacing={1} mt={1} justifyContent={'right'}>
                                        <Chip
                                            sx={{ marginTop: '10px', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                                            label={t('change')}
                                            clickable
                                            disabled={type !== 'admin' && type !== 'superUser'}
                                            onClick={() => document.getElementById('logo-input').click()}
                                        />
                                        <Controller
                                            name="logo"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input id="logo-input" type="file" hidden
                                                        // onChange={(e) => e.target.files[0] && field.onChange(e.target.files[0])}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.files[0]);
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.onload = () => {
                                                                setLogoPreview(reader.result);
                                                            };
                                                            if (file) {
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Box>
                            </Paper>

                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {t('academicPhoto')}
                                </Typography>
                            </Box>
                            <Paper
                                sx={{
                                    padding: '1.2rem',
                                    borderRadius: '8px',
                                    marginBottom: '1.5rem',
                                    gap: '1rem'
                                }}
                            >
                                <Box>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        width="225"
                                        sx={{
                                            objectFit: 'contain'
                                        }}
                                        image={academicPhotoPreview}
                                        alt='Academic Photo'
                                    />
                                    <Typography variant='body2' mt={1}>
                                        {t('phototGuide')}
                                    </Typography>
                                    <Stack direction="row" spacing={1} mt={1} justifyContent={'right'}>
                                        <Chip
                                            sx={{ marginTop: '10px', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                                            label={t('change')}
                                            clickable
                                            disabled={type !== 'admin' && type !== 'superUser'}
                                            onClick={() => document.getElementById('photo-input').click()}
                                        />
                                        <Controller
                                            name="academicPhoto"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input id="photo-input" type="file" hidden
                                                        // onChange={(e) => e.target.files[0] && field.onChange(e.target.files[0])}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.files[0]);
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.onload = () => {
                                                                setAcademicPhotoPreview(reader.result);
                                                            };
                                                            if (file) {
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Box>
                            </Paper>
                        </>
                        : null}

                    {isUpdating ?
                        <>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {t('calligraphicName')}
                                </Typography>
                            </Box>

                            <Paper
                                sx={{
                                    padding: '1.2rem',
                                    borderRadius: '8px',
                                    marginBottom: '1.5rem',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                {calligraphyPreview ?
                                    <CardMedia
                                        component="img"
                                        height="30"
                                        image={calligraphyPreview}
                                        alt='Calligraphy name'
                                        sx={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            contentStart: 'left',
                                            filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none', // Invert colors for dark mode
                                        }}
                                    />
                                    :
                                    <Chip sx={{ borderRadius: '5px', width: '100%', height: '40px' }} />
                                }
                                <Box mt={2} >
                                    <Typography variant='body2'>
                                        {t('calligraphyGuide')}
                                    </Typography>

                                    <Stack direction="row" spacing={1} mt={1} justifyContent={'right'}>
                                        <Chip
                                            sx={{ marginTop: '10px', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                                            label={t('change')}
                                            clickable
                                            disabled={type !== 'admin' && type !== 'superUser'}
                                            onClick={() => document.getElementById('calligraphy-input').click()}
                                        />
                                        <Controller
                                            name="calligraphy"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <>
                                                    <input id="calligraphy-input" type="file" hidden
                                                        // onChange={(e) => e.target.files[0] && field.onChange(e.target.files[0])}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.files[0]);
                                                            const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.onload = () => {
                                                                setCalligraphyPreview(reader.result);
                                                            };
                                                            if (file) {
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Box>
                            </Paper>
                        </>
                        : null}

                    {isUpdating ? <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t('basicInfo')}
                        </Typography>
                    </Box>
                        : ''}
                    <Paper elevation={1}>
                        <Box
                            sx={{
                                padding: '1.2rem',
                                borderRadius: '8px',
                                boxShadow: !isUpdating ? '0px 0px 15px 1px #e0ecff' : '',
                                position: 'relative'
                            }}
                        >
                            {!isUpdating ?
                                <>
                                    <IconButton color="secondary" aria-label="add an alarm"
                                        onClick={handleClick}
                                        sx={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                        }}
                                    >
                                        <Language />
                                    </IconButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => changeLang('en')}>ENGLISH</MenuItem>
                                        <MenuItem onClick={() => changeLang('bn')}>BENGALI</MenuItem>
                                        <MenuItem onClick={() => changeLang('ar')}>ARABIC</MenuItem>
                                    </Menu>
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} marginBottom={'1rem'}>
                                        <img
                                            src="https://res.cloudinary.com/plsshopit/image/upload/v1735219463/public/tgtx3jgcexpwbicaikwh.png"
                                            alt="Logo"
                                            style={{ width: 60, height: 60, borderRadius: '50%', backgroundColor: '#f50057' }}
                                        />
                                        <Typography variant="h6" sx={{ marginBottom: '0px', textAlign: 'left' }}>
                                            {t('giveTheAcademicInfo')}
                                        </Typography>
                                    </Box>
                                </>
                                : ''}
                            <Grid container spacing={1.4}>
                                {lbls.map((lbl, index) => (
                                    <Grid item xs={12} sm={lbl === 'academyType' || lbl === 'numberOfStudents' || lbl === 'mobile' || lbl === 'country' ? 6 : 12} key={index}>
                                        {lbl !== 'country' ?
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        select={lbl === 'academyType'}
                                                        label={t(lbl)}
                                                        type={lbl === 'numberOfStudents' ? 'number' : 'text'}
                                                        disabled={isUpdating && (lbl === 'email' || lbl === 'numberOfStudents')}
                                                        required
                                                    >
                                                        {lbl === 'academyType' && academicTypes.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                            :
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field: { onChange, value, ...field } }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={countries}
                                                        autoHighlight
                                                        size="small"
                                                        getOptionLabel={(option) => option.label}
                                                        // isOptionEqualToValue={(option, value) => option.code === value.code}
                                                        value={countries.find(option => option.label === value) || null}
                                                        // onChange={(event, newValue) => onChange(newValue)}
                                                        onChange={(event, newValue) => onChange(newValue ? newValue.label : '')}
                                                        renderOption={(props, option) => {
                                                            const { key, ...otherProps } = props;
                                                            return (
                                                                <Box
                                                                    key={key}
                                                                    component="li"
                                                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                                    {...otherProps}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                        alt={`${option.label} flag`}
                                                                    />
                                                                    {/* {option.label} ({option.code}) +{option.phone} */}
                                                                    {option.label}
                                                                </Box>
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t(lbl)}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />

                                        }
                                    </Grid>
                                ))}
                                {!isUpdating ?
                                    <>
                                        <Grid item sm={6} xs={12}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel htmlFor="password">{t('password')}</InputLabel>
                                                <PasswordField name="password" label="password" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel htmlFor="cpassword">{t('confirmPassword')}</InputLabel>
                                                <PasswordField name="cpassword" label="confirmPassword" />
                                            </FormControl>
                                        </Grid>
                                    </>
                                    : ''}
                                {!isUpdating ?
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleChange} size="small" defaultChecked />}
                                        label={<Link color="inherit" href="http://localhost:3000/terms" sx={{ textDecoration: 'none', fontSize: '14px' }}>
                                            {t('iAgreeWithTerms')}</Link>}
                                        sx={{ pl: '10.4px', pt: '5px', pb: '5px' }}
                                    />
                                    :
                                    ''}
                            </Grid>
                            <Grid container justifyContent="flex-end" spacing={1} mt={isUpdating ? 2 : ''}>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading || loadingAction}
                                        variant="contained"
                                        disabled={!checked || (isUpdating && type !== 'admin' && type !== 'superUser')}
                                    >
                                        {t(isUpdating ? 'update' : 'submit')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                            {!isUpdating ?
                                <Grid container mt={1}>
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            {t('askSignUp')}
                                        </Link>
                                    </Grid>
                                </Grid>
                                : null}
                        </Box>
                    </Paper>
                </Box>
            </Container >
        </>
    );
}