import React, { useEffect, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Stack,
    Autocomplete,
    Chip,
    MenuItem,
    Grid
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import useAuth from "../hooks/UseAuth";
import { CustomCrossButton } from "../styles/style";
import { Close } from '@mui/icons-material';
import { useForm, Controller } from "react-hook-form";
import { allStaff } from "../../actions/adminisActions";
import { newArticle, updateArticle } from "../../actions/othersActions";
import { articleSubjects } from "../../constants/commonContstants";
import RICHTEXTEDITOR from "../others/editor/DraftEditor";

export const NEWARTICLE = ({ rowData, closeDialog }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const subjects = articleSubjects(t)

    const [content, setContent] = useState("");

    const { staff } = useSelector(state => state.allStaff);
    const officiants = [...new Set(staff?.map(staff => staff.name))];

    const { control, handleSubmit } = useForm({
        defaultValues: {
            title: rowData?.title || "",
            subject: rowData?.subject2 || "",
            writer: rowData?.author || "",
        },
    });

    const onSubmit = (data) => {
        if (!rowData) {
            dispatch(newArticle({ ...data, content: content }, code));
        } else {
            dispatch(updateArticle({ ...data, id: rowData.id, content: content }, code));
        }
    };

    useEffect(() => {
        dispatch(allStaff(code));
    }, [dispatch, code]);


    useEffect(() => {
        if (rowData) {
            setContent(rowData.styledContent);
        }
    }, [dispatch, code, rowData]);

    const handleContentChange = (newContent) => {
        setContent(newContent);
    };

    return (
        <Box p={4}>
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize="small" />
            </CustomCrossButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item xs={12} textAlign={'center'}>
                        <Chip sx={{ fontSize: '1rem' }} label={`${t('new')} ${t('article')}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={t("heading")}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                    {/* Subject Field */}
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="subject"
                            control={control}
                            render={({ field: { onChange, value = '', ...field } }) => (
                                <TextField
                                    select
                                    value={value}
                                    label={t('subject')}
                                    onChange={(e) => onChange(e.target.value)}
                                    size="small"
                                    {...field}
                                    fullWidth
                                >
                                    {subjects.map((s) => (
                                        <MenuItem key={s.value} value={s.value}>
                                            {t(s.label)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>

                    {/* Writer Field */}
                    <Grid item xs={12} sm={8}>
                        <Controller
                            name="writer"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    freeSolo
                                    options={officiants || []}
                                    value={value}
                                    onInputChange={(event, newInputValue) => onChange(newInputValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label={t('writer')}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* <Paper variant="outlined"> */}
                <RICHTEXTEDITOR onContentChange={handleContentChange} editContent={rowData?.styledContent} />
                {/* </Paper> */}

                {/* Action Buttons */}
                <Stack direction="row" justifyContent="flex-end" spacing={1} marginTop={1}>
                    <Button size="small" variant="contained" color="success" type="submit">
                        {rowData ? t("update") : t("save")}
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};