import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MetaData } from '../utils/metaData';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import WebsiteAppBar from './AppBar';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export default function FullArticle() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { articles } = useSelector(state => state.articles);
    const article = articles.find(article => article.id === id);

    return (
        <>
            <Box sx={{ alignItems: 'center' }} padding={1}>
                <RECEIPTHEADING visitor={true} />
            </Box>
            <WebsiteAppBar />
            <Grid container spacing={2} sx={{ p: 4, mt: 3 }}>
                {/* <Grid item xs={12} md={9}> */}
                <Container>
                    <Box sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
                        <MetaData title="FULL ARTICLE" />
                        {article ? (
                            <>
                                <Typography variant="h4">{article?.title}</Typography>
                                <Typography variant="h6" color="textSecondary">
                                    {article?.author}
                                </Typography>
                                <Divider />
                                <div dangerouslySetInnerHTML={{ __html: article?.content }} />
                            </>
                        ) : (
                            <Typography variant="h6" color="textSecondary">
                                {t('sorryNotFound')}
                            </Typography>
                        )}
                    </Box>
                </Container>
                {/* </Grid> */}
                {/* <Grid item xs={12} md={3} sx={{ marginTop: 5 }}>
                    {articles.map(article => (
                        <Chip sx={{ margin: 0.5 }} key={article.id} label={article.title} />
                    ))}
                </Grid> */}
            </Grid>
        </>
    );
}
