// React and related hooks
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, Chip } from '@mui/material';
import { GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Edit, Delete, AddCircle, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Actions and utilities
import { leavesListPDF } from '../../actions/pdfActions';

// Utility Libraries
import dayjs from 'dayjs';

function CustomToolbar({ handleOpen, isStudent }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions')
            .map(column => column.headerName || column.field);

        const data = {
            isStudent: isStudent,
            heading: isStudent ? `${t('leavesManage')} ${t('students')}` : `${t('leavesManage')} ${t('staff')}`,
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(leavesListPDF(currentRows, code));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={4} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'principal' && type !== 'education' && type !== 'superUser'} onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}><FileDownloadOutlined /></Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={4} order={{ sm: 2, xs: 1 }} item display="flex" justifyContent="center">
                    <Chip
                        sx={{
                            fontSize: '1rem',
                        }}
                        label={`${t('leavesManage')} (${isStudent ? t('students') : t('staff')})`}
                    />
                </Grid>

                <Grid item xs={6} sm={4} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'right' }}>
                    <GridToolbarQuickFilter
                        placeholder={localeText.toolbarQuickFilterPlaceholder}
                    />
                </Grid>

            </Grid>
        </GridToolbarContainer >
    );
}

export default function LeaveTable({ isStudent, fetchDataAction, deleteAction, resetActions, leavesData, leaveForm: LeaveFormComponent }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    const [selectedData, setSelectedData] = useState('');
    const [open, setOpen] = useState(false);
    const { PDFLoading } = useSelector(state => state.PDF);

    // Memoized selectors
    const { success, isUpdated, isDeleted } = useSelector(state => ({
        success: isStudent ? state.newStudentLeave.success : state.newStaffLeave.success,
        isUpdated: isStudent ? state.studentLeave.isUpdated : state.staffLeave.isUpdated,
        isDeleted: isStudent ? state.studentLeave.isDeleted : state.staffLeave.isDeleted,
    }), (prev, next) =>
        prev.success === next.success &&
        prev.isUpdated === next.isUpdated &&
        prev.isDeleted === next.isDeleted
    );

    useEffect(() => {
        dispatch(fetchDataAction(code));
    }, [dispatch, code, fetchDataAction]);

    useEffect(() => {
        if (success || isUpdated || isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            resetActions.forEach(action => dispatch({ type: action }));
            dispatch(fetchDataAction(code));
            setOpen(false);
        }
    }, [dispatch, success, isUpdated, isDeleted, t, enqueueSnackbar, fetchDataAction, resetActions, code]);

    const handleEdit = (rowData) => {
        setSelectedData(rowData);
        setOpen(true);
    };

    const handleDelete = (id) => {
        dispatch(deleteAction({ id }, code));
    };

    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, width: 80, flex: 0, disableColumnMenu: false },
        { field: 'idNo', headerName: t('idNo'), minWidth: 80, width: 80, flex: 0, sortable: true },
        {
            field: 'photo_url',
            headerName: t('picture'),
            minWidth: 80,
            width: 80,
            flex: 0,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginTop: '2.5px' }}
                />
            ),
        },
        { field: 'name', headerName: t('name') },
        { field: isStudent ? 'admitted_class' : 'designation', headerName: t(isStudent ? 'class' : 'designation') },
        { field: 'leaveReason', headerName: t('leaveReason') },
        { field: 'leaveStart', headerName: t('leaveStart') },
        { field: 'leaveEnd', headerName: t('leaveEnd') },
        { field: 'leaveTime', headerName: t('leaveTime'), minWidth: 100, width: 100, flex: 0 },
        { field: 'leaveType', headerName: t('leaveType') },
        { field: isStudent ? 'father_mobile' : 'mobile', headerName: t('mobile') },
        { field: 'address', headerName: t('address') },
        { field: 'grantedTime', headerName: t('grantedTime'), minWidth: 100, width: 100 },
        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            width: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem disabled={type !== 'principal' && type !== 'education' && type !== 'superUser'} icon={<Edit />} label="edit" onClick={() => handleEdit(params.row)} />,
                <GridActionsCellItem
                    disabled={type !== 'principal' && type !== 'education' && type !== 'superUser'}
                    icon={<Delete sx={{ color: 'red' }} />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.dataId)}
                    showInMenu
                />,
            ],
        },
    ]);

    const rows = leavesData?.map((item, index) => {
        const startDate = new Date(item.start_date);
        const endDate = new Date(item.end_date);
        const diffInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        return {
            id: index + 1,
            dataId: item.id,
            idNo: isStudent ? item.admit_no : item.staff_id,
            photo_url: item.photo_url,
            name: item.name,
            [isStudent ? 'admitted_class' : 'designation']: isStudent ? item.admitted_class : item.designation,
            leaveReason: item.reason,
            leaveStart: dayjs(startDate).format('YYYY-MM-DD'),
            leaveEnd: dayjs(endDate).format('YYYY-MM-DD'),
            leaveTime: `${diffInDays} ${t('day')}`,
            leaveType: item.leave_type,
            [isStudent ? 'father_mobile' : 'mobile']: isStudent ? item.father_mobile : item.mobile,
            address: `${item.village}, ${item.district}`,
            grantedTime: dayjs(item.created_at).format('YYYY-MM-DD'),
        };
    });

    return (
        <Box className="globalShapeDesign">
            <MetaData title={isStudent ? t('STUDENT LEAVS') : t('STAFF LEAVS')} />
            <Dialog open={open} maxWidth="md">
                <LeaveFormComponent closeDialog={() => setOpen(false)} selectedData={selectedData} />
            </Dialog>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                loading={PDFLoading}
                initialState={{
                    columns: { columnVisibilityModel: { address: false, __check__: false, mobile: false } },
                }}
                slots={{
                    toolbar: () => <CustomToolbar handleOpen={() => setOpen(true)} isStudent={isStudent} />
                }}
            />
        </Box>
    );
}
