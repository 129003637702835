// React and related hooks
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, Chip } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, GridActionsCellItem, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Delete, FactCheckOutlined, FileDownloadOutlined, ReplayCircleFilled } from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';
import OFFICIANTLOGS from './OfficiantLogs';

// Actions
import { addOfficiantAttendance, allStaff, deleteOffciantAttend, getAllOffciantAttend } from '../../actions/adminisActions';
import { staffAttendancePDF } from '../../actions/pdfActions';

// Utility Libraries
import dayjs from 'dayjs';

function CustomToolbar({ refresh }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();

    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions' && column.field !== 'imageUrl' && column.field !== 'logs')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('officiantAttendancePannel'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(staffAttendancePDF(currentRows, code));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={refresh}>
                            <ReplayCircleFilled />
                        </Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('officiantAttendancePannel')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function STUFFATTENDANCELIST() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const { code, type } = useAuth();
    const { officiantAttendance, loading } = useSelector(state => state.allOfficiantAttend);
    const { success, isDeleted } = useSelector(state => state.officinatAttend);
    const { PDFLoading } = useSelector(state => state.PDF);

    const [selectedData, setSelectedData] = useState('');

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    // Fetch initial data once when the component mounts
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            dispatch(allStaff(code));
            dispatch(addOfficiantAttendance(code))
        }
    }, [dispatch, code]);

    // Log and clear attendance logs after they are fetched
    useEffect(() => {
        // if (success) {
        //     dispatch(getAllOffciantAttend(code));
        // }
        if (isDeleted) {
            dispatch(getAllOffciantAttend(code));
        }
    }, [dispatch, code, isDeleted]);

    // // Fetch new logs if the code changes
    useEffect(() => {
        if (!isFirstRender.current || success) {
            dispatch(getAllOffciantAttend(code));
        }
    }, [code, dispatch, success]);

    const handleRefresh = () => {
        // dispatch(getZkTecoLogs(code));
    }

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'officiantId', headerName: t('idNo'), minWidth: 80, sortable: true, flex: 0 },

        {
            field: 'imageUrl',
            headerName: t('picture'),
            headerClassName: 'CustomHeader',
            width: 70,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '28px', height: '28px', borderRadius: '50%', marginTop: '4px' }}
                />
            ),
        },

        { field: 'name', headerName: t('name') },
        { field: 'level', headerName: t('designation') },
        { field: 'teamName', headerName: t('teamName') },
        { field: 'dutyTime', headerName: t('dutyTime') },
        { field: 'checkIn', headerName: t('checkIn') },
        { field: 'late', headerName: t('late') },
        { field: 'checkOut', headerName: t('checkOut') },
        { field: 'overTime', headerName: t('overTime') },
        { field: 'workTime', headerName: t('workTime') },
        { field: 'status', headerName: t('status') },
        { field: 'shift', headerName: t('shift') },
        { field: 'date', headerName: t('date') },
        { field: 'residenceType', headerName: t('residenceType') },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<FactCheckOutlined />}
                    label="Logs"
                    onClick={() => handleLogs(params.row)}
                    sx={{
                        visibility: params.row.logs.length >= 2 ? 'visible' : 'hidden', // Conditional visibility
                    }}
                />,
                <GridActionsCellItem
                    disabled={type !== 'principal' && type !== 'superUser'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Function to validate if time values are valid
    const isValidTime = (hours, minutes) => {
        return Number.isInteger(hours) && Number.isInteger(minutes) && hours >= 0 && minutes >= 0;
    };

    // Transform furnitures array into the desired format
    const rows = officiantAttendance?.map((o, index) => {
        // Extract just the time from both duty_start and check_in using Day.js's format method
        const dutyStartTime = dayjs(o.duty_start).format('HH:mm');  // 24-hour format time
        const checkInTime = dayjs(o.logs[0].check_in).format('HH:mm');  // 24-hour format time
        const checkOutTime = dayjs(o.logs[0].check_out).format('HH:mm');  // 24-hour format time

        // Convert the times back to Day.js objects for the same day (ignoring the date)
        const dutyStart = dayjs(`1970-01-01 ${dutyStartTime}`);  // Use a placeholder date
        const checkIn = dayjs(`1970-01-01 ${checkInTime}`);  // Use a placeholder date
        const checkOut = dayjs(`1970-01-01 ${checkOutTime}`);  // Use a placeholder date

        // Calculate the time difference (late time) in minutes
        const lateMinutes = checkIn.diff(dutyStart, 'minute');  // Get the difference in minutes
        const lateMinutesCheckOut = checkIn.diff(checkOut, 'minute');  // Get the difference in minutes
        const lateMinutesWorkTime = checkOut.diff(checkIn, 'minute');  // Get the difference in minutes

        // Convert minutes to hours and minutes
        const lateHours = Math.floor(lateMinutes / 60);
        const remainingMinutes = lateMinutes % 60;
        // for counting overtime
        const lateHoursCheckOut = Math.floor(lateMinutesCheckOut / 60);
        const remainingMinutesCheckOut = lateMinutesCheckOut % 60;
        // for counting work time
        const lateHoursWorkTime = Math.floor(lateMinutesWorkTime / 60);
        const remainingMinutesWorkTime = lateMinutesWorkTime % 60;

        // Validate and format overtime
        let overTime = '';
        if (isValidTime(lateHoursCheckOut, remainingMinutesCheckOut)) {
            overTime = `${lateHoursCheckOut}h ${remainingMinutesCheckOut}m`;
        } else {
            overTime = '';
        }

        // Validate and format work time
        let workTime = '';
        if (isValidTime(lateHoursWorkTime, remainingMinutesWorkTime)) {
            workTime = `${lateHoursWorkTime}h ${remainingMinutesWorkTime}m`;
        } else {
            workTime = '';
        }

        return {
            id: index + 1,
            officiantId: o.id,
            imageUrl: o.photo_url,
            name: o.name,
            level: o.designation,
            teamName: o.team_name,
            dutyTime: `${dayjs(o.duty_start).format('h:mm A')} - ${dayjs(o.duty_end).format('h:mm A')}`,  // Formatted time
            checkIn: dayjs(o.logs[0].check_in).format('h:mm A'),
            checkOut: o.logs[0].check_out ? dayjs(o.logs[0].check_out).format('h:mm A') : '',
            late: `${lateHours}h ${remainingMinutes}m`,
            overTime: overTime,
            workTime: workTime,
            status: o.logs[o.logs.length - 1].check_out === '' ? t('present') : t('absent'),
            shift: o.shift,
            date: dayjs(o.date).format('YYYY-MM-DD'),
            residenceType: o.residence_type,
            logs: o.logs
        };
    });

    const handleDelete = (rowData) => {
        const data = {
            id: rowData.officiantId,
            date: rowData.date
        }
        dispatch(deleteOffciantAttend(data, code))
    }

    // for open the edit dialouge
    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleLogs = (rowData) => {
        const data = officiantAttendance.find(o => o.id === rowData.officiantId && dayjs(o.date).format('YYYY-MM-DD') === dayjs(rowData.date).format('YYYY-MM-DD'));

        setSelectedData(data);
        setAnchorEl(true);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'OFFICIANTS ATTENDANCE'} />
            <Dialog
                open={anchorEl}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <OFFICIANTLOGS closeDialog={handleClose} rowData={selectedData} />
            </Dialog>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading || PDFLoading}
                localeText={localeText}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            workTime: false,
                            overTime: false,
                            residenceType: false,
                            shift: false,
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar refresh={handleRefresh} />
                }}
            />
        </Box>
    );
}