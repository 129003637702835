// React and Hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/UseAuth';
import { useComponent } from '../../hooks/ComponentContext';

// Material UI Components
import {
    Grid, Button, ButtonGroup, Box, Menu, MenuItem, Chip, Dialog
} from '@mui/material';

// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Custom Components and Utilities
import { MetaData } from '../../utils/metaData';
import { CustomDataGrid, useDataGridColumns } from '../../utils/useDataGridColumns';

// Redux Actions
import {
    clearErrors, deleteProduct, getProducts
} from '../../../actions/othersActions';
import { sellingProductListPdf } from '../../../actions/pdfActions';

// Constants
import { getUserTypes } from '../../../constants/commonContstants';
import {
    DELETE_PRODUCT_RESET, DELETE_SOLD_PRODUCT_RESET, NEW_PRODUCT_RESET,
    NEW_SELLING_PRODUCT_RESET, UPDATE_PRODUCT_RESET, UPDATE_SOLD_PRODUCT_RESET
} from '../../../constants/othersConstants';

// Product Forms
import NEWPRODUCTFORM from './NewProductForm';
import SELLINGPRODUCTFORM from './SellingProductForm';

// Other Utilities
import dayjs from 'dayjs';
import { GridActionsCellItem, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';

function CustomToolbar({ handleOpen, handleSelling }) {
    const { t } = useTranslation();
    const { changeComponent } = useComponent();
    const [currentRows, setCurrentRows] = React.useState('');
    const dispatch = useDispatch();

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('buyeAndSellsCount'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // open list window
    const OpenProductSoldListWindow = () => {
        changeComponent('soldProductList')
    };

    const handlePdf = () => {
        dispatch(sellingProductListPdf(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={OpenProductSoldListWindow}><FormatListBulletedOutlinedIcon /></Button>
                        <Button onClick={handleSelling}><ChecklistOutlinedIcon /></Button>
                        <Button onClick={handleOpen}><AddCircleIcon /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlinedIcon />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('buyeAndSellsCount')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function SELLINRPODUCTS() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const userTypes = getUserTypes(t)
    const { code } = useAuth();

    const [selectedRows, setSelectedRows] = useState([]);
    const [open1, setOpen1] = React.useState(false);
    const { products, error, loading } = useSelector(state => state.allProduct);
    const { success, isProductUpdated, isProductDeleted } = useSelector(state => state.newProduct);
    const { sellingSuccess, isUpdated, isDeleted } = useSelector(state => state.newSellingProduct);
    const { PDFLoading } = useSelector(state => state.PDF);

    useEffect(() => {
        dispatch(getProducts(code))
    }, [dispatch, code])

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(getProducts(code))
            dispatch({ type: NEW_PRODUCT_RESET })
        }

        if (sellingSuccess) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            setOpen1(false);
            dispatch({ type: NEW_SELLING_PRODUCT_RESET })
            dispatch(getProducts(code));
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_SOLD_PRODUCT_RESET })
            dispatch(getProducts(code));
        }

        if (isProductUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_PRODUCT_RESET })
            dispatch(getProducts(code));
            setOpen(false);
        }

        if (isProductDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_PRODUCT_RESET })
            dispatch(getProducts(code));
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_SOLD_PRODUCT_RESET })
            dispatch(getProducts(code));
        }

        if (error) {
            dispatch(clearErrors());
        }

    }, [dispatch, t, success, enqueueSnackbar, error, isDeleted, isUpdated, sellingSuccess, code, isProductUpdated, isProductDeleted]);

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('dataId') },
        { field: 'productName', headerName: t('productName') },
        { field: 'totalHas', headerName: t('totalHas'), width: 100, minWidth: 100, flex: 0 },
        { field: 'sold', headerName: t('sold'), width: 100, minWidth: 100, flex: 0 },
        { field: 'remain', headerName: t('remain'), width: 100, minWidth: 100, flex: 0 },
        { field: 'buyingPrice', headerName: `${t('buyingPrice')} (${t('unit')})` },
        { field: 'sellingPrice', headerName: `${t('sellingPrice')} (${t('unit')})` },
        { field: 'responsible', headerName: t('responsible'), width: 80, minWidth: 80, flex: 0 },
        { field: 'date', headerName: t('addingDate') },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            width: 80,
            minWidth: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform furnitures array into the desired format
    const rows = products?.map((p, index) => {
        // Find the matching user type
        const responsible = userTypes.find(type => type.value === p.responsible);

        return {
            serial: index + 1,
            id: p.id,
            productName: p.product_name,
            totalHas: `${p.total_has} ${p.unit}`,
            sold: `${p.total_sold} ${p.unit}`,
            remain: `${p.total_has - p.total_sold} ${p.unit}`,
            buyingPrice: `${p.per_product_price} ${t('moneySymbol')}`,
            sellingPrice: `${p.per_product_selling_price} ${t('moneySymbol')}`,
            date: dayjs(p.created_at).format('YYYY-MM-DD'),
            responsible: responsible.label
        };
    });

    const handleEdit = (singleRowData) => {
        if (selectedRows.length === 0 || selectedRows.length === 1) { // for editing single data
            setSelectedRows([singleRowData])
        }
        setOpen(true);
    };

    const handleDelete = (rowData) => {
        if (parseInt(rowData.sold) !== 0) {
            enqueueSnackbar(t('sorryToDeleteForSold'), { variant: 'error' });
        } else {
            dispatch(deleteProduct(rowData.id, code))
        }
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setSelectedRows([]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelling = () => {
        if (selectedRows.length > 0) {
            setOpen1(true);
        } else {
            enqueueSnackbar(t('pleaseSelect'), { variant: 'info' });
        }
    };

    // handle selection model change
    const handleSelectionModelChange = (selectionModel) => {
        const selectedIDs = new Set(selectionModel);
        const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
        setSelectedRows(selectedRowData);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };



    return (
        <Box className="globalShapeDesign">
            <MetaData title={'OFFICIANTS LIST'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <NEWPRODUCTFORM closeDialog={handleClose} selectedItem={selectedRows} />
            </Dialog>

            <Dialog
                open={open1}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <SELLINGPRODUCTFORM closeDialog={handleClose1} selectedItem={selectedRows} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading || PDFLoading}
                localeText={localeText}
                onRowSelectionModelChange={(selectionModel) => {
                    handleSelectionModelChange(selectionModel);
                }}
                selectionModel={selectedRows}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} handleSelling={handleSelling} />
                }}
            />
        </Box>
    );
}
