import {
    NEW_ACADEMY_REQUEST,
    NEW_ACADEMY_SUCCESS,
    NEW_ACADEMY_FAIL,
    NEW_ACADEMY_RESET,
    ALL_ACADEMIES_REQUEST,
    ALL_ACADEMIES_SUCCESS,
    ALL_ACADEMIES_FAIL,
    UPDATE_ACADEMY_REQUEST,
    UPDATE_ACADEMY_FAIL,
    UPDATE_ACADEMY_SUCCESS,
    DELETE_ACADEMY_REQUEST,
    DELETE_ACADEMY_FAIL,
    DELETE_ACADEMY_SUCCESS,
    DELETE_ACADEMY_RESET,
    CLEAR_ERRORS,
    EMAIL_VARIFY_REQUEST,
    EMAIL_VARIFY_SUCCESS,
    EMAIL_VARIFY_FAIL,
    UPDATE_ACADEMY_RESET
} from '../constants/superAdminConstants'

// for add new academy
export const newAcademyReducer = (state = {}, action) => {
    switch (action.type) {
        case NEW_ACADEMY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_ACADEMY_SUCCESS:
            return {
                loading: false,
                isSaved: true,
            }

        case NEW_ACADEMY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_ACADEMY_RESET:
            return {
                isSaved: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }

        default:
            return state
    }
}

// For all academies
export const allAcadmiesReducer = (state = { academies: [] }, action) => {
    switch (action.type) {
        case ALL_ACADEMIES_REQUEST:
            return {
                loading: true,
                academies: []
            }

        case ALL_ACADEMIES_SUCCESS:
            return {
                loading: false,
                academies: action.payload.academies
            }

        case ALL_ACADEMIES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const academyReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_ACADEMY_REQUEST:
        case DELETE_ACADEMY_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_ACADEMY_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_ACADEMY_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_ACADEMY_FAIL:
        case DELETE_ACADEMY_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_ACADEMY_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_ACADEMY_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const emailVarifyReducer = (state = {}, action) => {
    switch (action.type) {
        case EMAIL_VARIFY_REQUEST:
            return {
                loading: true,
            }

        case EMAIL_VARIFY_SUCCESS:
            return {
                loading: false,
                isVarify: action.payload
            }

        case EMAIL_VARIFY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}