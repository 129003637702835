// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

// MUI components and icons
import { Button, TextField, Grid, Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, IconButton } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and styles
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions
import { allStaff, allStaffReports, newStaffReport, updateStaffReport } from '../../actions/adminisActions';

// Constants
import { officiantsReportText } from '../../constants/commonContstants';

// Utilities
import { MetaData } from '../utils/metaData';
import { NEW_STAFF_REPORT_RESET } from '../../constants/adminisConstants';

export default function OFFICIANTSREPORTFORM({ closeDialog, id }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(id);
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { staff } = useSelector(state => state.allStaff);
    const { staffReports } = useSelector(state => state.allStaffReports);
    const { success } = useSelector(state => state.newStaffReport)

    // Find staff according to the form no
    const report = staffReports?.find(report => report.id === catchID && report.code === code);

    const defaultValues = {
        idNo: report?.staff_id || '',
        name: report?.name || '',
        designation: report?.designation || '',
        reportType: report?.report_type || '',
    };

    // Dynamically add reportSubject fields
    for (let i = 0; i < 10; i++) {
        defaultValues[`reportSubject${i}`] = report?.[`report_subject${i}`] || officiantsReportText[i] || '';
        defaultValues[`officeReport${i}`] = report?.[`office_report${i}`] || '';
        defaultValues[`studentReport${i}`] = report?.[`student_report${i}`] || '';
    }
    const { handleSubmit, control, reset, getValues, setValue } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allStaff(code));
    }, [dispatch, code])

    useEffect(() => {
        if (success) {
            reset()
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_STAFF_REPORT_RESET })
            dispatch(allStaffReports(code));
        }
    }, [dispatch, code, success, enqueueSnackbar, t, reset]);

    const handleCheck = () => {
        const findStaff = staff?.find(s => s.staff_id === parseInt(getValues('idNo')) && s.code === code);
        if (findStaff) {
            setValue('name', findStaff.name)
            setValue('designation', findStaff.designation)
        } else {
            enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });
        }
    };

    // handle clear
    const handleClear = () => {
        const emptyValues = {
            idNo: '',
            name: '',
            reportType: '',
        };

        // Dynamically add reportSubject fields
        for (let i = 0; i < 10; i++) {
            emptyValues[`officeReport${i}`] = report?.[`office_report${i}`] || '';
            emptyValues[`studentReport${i}`] = report?.[`student_report${i}`] || '';
        }

        reset(emptyValues);
        setCatchID('')
    };

    // handle submit
    const onSubmit = (data) => {
        const date = new Date();
        if (!catchID) { // when catchIID is not gotted it means user try to update
            dispatch(newStaffReport({ ...data, date: date }, code));
        } else {
            dispatch(updateStaffReport({ ...data, id: catchID, date: date }, code));
        }
    };

    const lbls = ['idNo', 'name', 'designation', 'reportType'];

    return (
        <Container component="main" maxWidth="md" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'REPORT FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('reportForm')} department={t('adminis')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'}>
                    <Grid container
                        sx={{
                            paddingTop: '1rem',
                            overflow: 'auto',
                            position: 'relative',
                            '&::-webkit-scrollbar-track': {
                                marginTop: '10px',
                            },
                        }}
                    >
                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={lbl === 'idNo' ? 2 : lbl === 'name' ? 4 : 3}
                                mb={1}
                                paddingLeft={lbl === 'idNo' ? '' : 0.5}
                                key={`grid-${index}`}
                            >
                                <Controller
                                    key={`controller-${lbl}`} // Ensure keys are unique
                                    name={lbl}
                                    control={control} // pass the control object
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="given-name"
                                            fullWidth
                                            autoFocus={lbl === 'idNo'}
                                            required
                                            type={lbl === 'idNo' ? 'number' : 'text'}
                                            disabled={catchID && lbl === 'idNo' ? true : false}
                                            size="small"
                                            id={`id${index}`}
                                            label={t(lbl)}
                                            InputProps={{
                                                endAdornment: field.name === 'idNo' && (
                                                    <InputAdornment position="end">
                                                        <IconButton size='small' onClick={() => handleCheck(field.value)}>
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}

                        <TableContainer>
                            <Table aria-label="custom table" sx={{ borderTop: '0px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={2} sx={{ fontSize: '1rem', width: '33%', padding: '8px' }}>
                                            {t('reportSubject')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '33%', padding: '8px' }}>
                                            {t('reportFromStudent')}
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} sx={{ width: '33%', fontSize: '1rem', padding: '8px' }}>{t('officeObserve')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {officiantsReportText.map((lbl, index) => (
                                        <TableRow key={`row-${index}`}>
                                            <TableCell align="center" sx={{ padding: '5px' }}>
                                                <Controller
                                                    name={`reportSubject${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '2px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="center" sx={{ padding: '5px' }}>
                                                <Controller
                                                    name={`studentReport${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '2px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="center" sx={{ padding: '5px' }}>
                                                <Controller
                                                    name={`officeReport${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                            variant="standard"

                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '2px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                    <Grid item container columnSpacing='5' marginBottom={1} marginTop={1} justifyContent={'right'}>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchID ?
                            <Grid item xs={2}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>

                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>

                </Box>
            </Box>
        </Container >
    );
}