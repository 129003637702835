// React and Hooks
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material UI components
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// MUI Icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SchoolIcon from '@mui/icons-material/School';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SettingsIcon from '@mui/icons-material/Settings';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import MessageIcon from '@mui/icons-material/Message';

// Custom Hooks
import useAuth from '../hooks/UseAuth';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { GETCONFIRMATION } from './MiniComponents';

export default function SideBarMenu({ handleClickMenu }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [openStates, setOpenStates] = useState({});
    const [selected, setSelected] = useState(null);
    const { language } = JSON.parse(localStorage.getItem('basics')) || {};
    const { code, activation, expiryDate, type } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const isDateValid = dayjs().isBefore(dayjs(expiryDate));

    const handleClick = (key) => {
        if (!activation || !isDateValid) return;

        if (key === 'homePage') {
            return setAnchorEl(true);
        }

        // check authentication
        const allowedTypes = {
            adminis: ['principal', 'admin', 'councilMember', 'superUser'],
            education: ['education', 'principal', 'admin', 'councilMember', 'superUser'],
            accounts: ['accountant', 'principal', 'admin', 'councilMember', 'superUser'],
            boarding: ['boardingSuper', 'principal', 'admin', 'councilMember', 'superUser'],
            donation: ['accountant', 'education', 'principal', 'admin', 'councilMember', 'superUser'],
        };

        if (allowedTypes[key] && !allowedTypes[key].includes(type)) {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
            return;
        }

        setSelected(key); // for highlighting selected list

        setOpenStates((prevState) => ({ ...prevState, [key]: !prevState[key] })); // for opening that dropdown which one i clicked on, if sub menu or sub sub menu dosen't matter
        if (key !== 'adminis' && key !== 'education' && key !== 'aboutExam' && key !== 'hostelManager' && key !== 'accounts' && key !== 'income' && key !== 'expense' && key !== 'reportAccount' && key !== 'boarding' && key !== 'library' && key !== 'academicLibrary' && key !== 'admission' && key !== 'donation' && key !== 'attendance' && key !== 'others' && key !== 'website' && key !== 'msg') {
            handleClickMenu(key)
        } // for ignoring menu that i don't want to close sidebar after clicking on it
    };

    const adminis_list = ['officiants', 'duty', 'leavesManage', 'officiantsReport', 'store'];
    const edu_sec_list = ['classPanel', 'routine', 'leavesManage', 'aboutExam'];
    const hostel_list = ['studentReport', 'registers'];
    const accounts_list = ['receipt', 'voucher', 'income', 'expense', 'reportAccount', 'salaryGiving', 'approvedReceiptBooks', 'regardingChanging'];
    const boarding_list = ['receipt', 'voucher', 'acceptMoney'];
    const libray_options = ['bookList', 'givingBook', 'receivingBook'];
    const admission_list = ['newAdmission', 'studentList'];
    const donor_list = ['donorList', 'donationRecord1'];
    const attendance_list = ['officiants', 'students', 'attendanceRegister'];
    // const others_list = ['buyAndSell'];
    const website_list = ['homePage', 'articles'];
    const msg_list = ['messaging', 'balanceInquery'];

    const handleClickSettings = () => {
        window.open(`/${code}/settings`, '_blank');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOk = () => {
        navigate(`/${code}/wb/${language}`);
        return null; // To ensure nothing is rendered
    };

    return (
        <>
            <Box>
                <GETCONFIRMATION
                    handleOpen={open}
                    confirmationText={t('leaveStatus')}
                    handleClose={handleClose}
                    handleOk={handleOk}
                />
            </Box>
            <List
                sx={{ width: '100%', height: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton selected={selected === 'dashboard'} onClick={() => handleClick('dashboard')}>
                    <ListItemIcon>
                        <DashboardIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('dashboard')} />
                </ListItemButton>

                <ListItemButton selected={selected === 'adminis'} onClick={() => handleClick('adminis')}>
                    <ListItemIcon>
                        <PsychologyIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('adminis')} />
                    {openStates['adminis'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['adminis']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {adminis_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`adminis-${index}`)}
                            selected={selected === `adminis-${index}`}

                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>
                {/* education */}
                <ListItemButton selected={selected === `education`} onClick={() => handleClick('education')}>
                    <ListItemIcon>
                        <SchoolIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('education')} />
                    {openStates['education'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['education']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {edu_sec_list.map((key, index) => (
                        <React.Fragment key={key}>
                            <ListItemButton
                                sx={{ paddingLeft: '12px' }}
                                onClick={() => handleClick(key)}
                                selected={selected === `${key}`}
                            >
                                <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                <ListItemText primary={t(key)} />
                                {key === 'aboutExam' && (openStates[key] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />)}
                            </ListItemButton>
                            {key === 'aboutExam' && (
                                <Collapse in={openStates['aboutExam']} timeout="auto" unmountOnExit sx={{ ml: 1.8 }} className='customBorderLeft'>
                                    {/* Replace the following array with your actual sub-menu items for 'aboutExam' */}
                                    {['papers', 'result', 'gradings'].map((subKey, subIndex) => (
                                        <ListItemButton key={subKey} sx={{ paddingLeft: '5px' }}
                                            onClick={() => handleClick(`education-${subIndex}`)}
                                            selected={selected === `education-${subIndex}`}
                                        >
                                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '5px' }} />
                                            <ListItemText primary={t(subKey)} />
                                        </ListItemButton>
                                    ))}
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </Collapse>

                {/* darul iqama */}
                <ListItemButton selected={selected === `hostelManager`} onClick={() => handleClick('hostelManager')}>
                    <ListItemIcon>
                        <GavelIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('hostelManager')} />
                    {openStates['hostelManager'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['hostelManager']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {hostel_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`hostelManager-${index}`)}
                            selected={selected === `hostelManager-${index}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>
                {/* accounts */}
                <ListItemButton selected={selected === `accounts`} onClick={() => handleClick('accounts')}>
                    <ListItemIcon>
                        <AccountTreeIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('accounts')} />
                    {openStates['accounts'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['accounts']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {accounts_list.map((key, index) => (
                        <React.Fragment key={key}>
                            <ListItemButton
                                sx={{ paddingLeft: '12px' }}
                                onClick={() => handleClick(key)}
                                selected={selected === `${key}`}
                            >
                                <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                <ListItemText primary={t(key)} />
                                {(key === 'income' || key === 'expense' || key === 'reportAccount') && (openStates[key] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />)}
                            </ListItemButton>
                            {(key === 'income' || key === 'expense' || key === 'reportAccount') && (
                                <Collapse in={openStates[key]} timeout="auto" unmountOnExit sx={{ ml: 1.8 }} className='customBorderLeft'>
                                    {/* Replace the following arrays with your actual sub-menu items for 'income' and 'expense' and 'reportAccount' */}
                                    {key === 'income' && ['mci', 'mgi', 'yci', 'ygi'].map((subKey, subIndex) => (
                                        <ListItemButton key={subKey} sx={{ paddingLeft: '5px' }}
                                            onClick={() => handleClick(`income-${subIndex}`)}
                                            selected={selected === `income-${subIndex}`}
                                        >
                                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                            <ListItemText primary={t(subKey)} />
                                        </ListItemButton>
                                    ))}
                                    {key === 'expense' && ['mce', 'mge', 'yce', 'yge'].map((subKey, subIndex) => (
                                        <ListItemButton key={subKey} sx={{ paddingLeft: '5px' }}
                                            onClick={() => handleClick(`expense-${subIndex}`)}
                                            selected={selected === `expense-${subIndex}`}
                                        >
                                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                            <ListItemText primary={t(subKey)} />
                                        </ListItemButton>
                                    ))}
                                    {key === 'reportAccount' && ['mcr', 'mgr', 'ycr', 'ygr'].map((subKey, subIndex) => (
                                        <ListItemButton key={subKey} sx={{ paddingLeft: '5px' }}
                                            onClick={() => handleClick(`reportAccount-${subIndex}`)}
                                            selected={selected === `reportAccount-${subIndex}`}
                                        >
                                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                            <ListItemText primary={t(subKey)} />
                                        </ListItemButton>
                                    ))}
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </Collapse>
                {/* boarding */}
                <ListItemButton selected={selected === `boarding`} onClick={() => handleClick('boarding')}>
                    <ListItemIcon>
                        <RamenDiningIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('boarding')} />
                    {openStates['boarding'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['boarding']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {boarding_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`boarding-${index}`)}
                            selected={selected === `boarding-${index}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>

                {/* library*/}
                <ListItemButton selected={selected === `library`} onClick={() => handleClick('library')}>
                    <ListItemIcon>
                        <LocalLibraryIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('library')} />
                    {openStates['library'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['library']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {libray_options.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`library-${index}`)}
                            selected={selected === `library-${index}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>

                {/* Attendance */}
                <ListItemButton selected={selected === `attendance`} onClick={() => handleClick('attendance')}>
                    <ListItemIcon>
                        <AssuredWorkloadIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('attendance')} />
                    {openStates['attendance'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['attendance']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {attendance_list.map((key, index) => (
                        <React.Fragment key={key}>
                            <ListItemButton
                                sx={{ paddingLeft: '12px' }}
                                onClick={() => handleClick(key)}
                                selected={selected === `${key}`}
                            >
                                <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                <ListItemText primary={t(key)} />
                            </ListItemButton>
                        </React.Fragment>
                    ))}
                </Collapse>

                {/* donation  */}
                <ListItemButton selected={selected === 'donation'} onClick={() => handleClick('donation')}>
                    <ListItemIcon>
                        <VolunteerActivismIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('donation')} />
                    {openStates['donation'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['donation']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {donor_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`donation-${index}`)}
                            selected={selected === `donation-${index}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>

                {/* SMS services */}
                <ListItemButton selected={selected === `msg`} onClick={() => handleClick('msg')}>
                    <ListItemIcon>
                        <MessageIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('msgService')} />
                    {openStates['msg'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['msg']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {msg_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(key)}
                            selected={selected === key}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>

                {/* admission */}
                <ListItemButton selected={selected === `admission`} onClick={() => handleClick('admission')}>
                    <ListItemIcon>
                        <AddHomeIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('admission')} />
                    {openStates['admission'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['admission']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {admission_list.map((key, index) => (
                        <ListItemButton
                            key={key}
                            sx={{
                                paddingLeft: '12px',
                            }}
                            onClick={() => handleClick(`admission-${index}`)}
                            selected={selected === `admission-${index}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    ))}
                </Collapse>

                {/* others */}
                <ListItemButton onClick={() => handleClick('buyAndSell')} selected={selected === 'buyAndSell'}>
                    <ListItemIcon>
                        <ShoppingBagIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('buyAndSell')} />
                </ListItemButton>
                {/* <ListItemButton selected={selected === `others`} onClick={() => handleClick('others')}>
                <ListItemIcon>
                    <QueueOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary={t('others')} />
                {openStates['others'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
            </ListItemButton>
            <Collapse in={openStates['others']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                {others_list.map((key, index) => (
                    <React.Fragment key={key}>
                        <ListItemButton
                            sx={{ paddingLeft: '12px' }}
                            onClick={() => handleClick(key)}
                            selected={selected === `${key}`}
                        >
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                            <ListItemText primary={t(key)} />
                        </ListItemButton>
                    </React.Fragment>
                ))}
            </Collapse> */}

                {/* website */}
                <ListItemButton selected={selected === `website`} onClick={() => handleClick('website')}>
                    <ListItemIcon>
                        <WebAssetIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('website')} />
                    {openStates['website'] ? <ExpandLess sx={{ fontSize: '1rem' }} /> : <ExpandMore sx={{ fontSize: '1rem' }} />}
                </ListItemButton>
                <Collapse in={openStates['website']} timeout="auto" unmountOnExit sx={{ ml: 3.5 }} className='customBorderLeft'>
                    {website_list.map((key, index) => (
                        <React.Fragment key={key}>
                            <ListItemButton
                                sx={{ paddingLeft: '12px' }}
                                onClick={() => handleClick(key)}
                                selected={selected === `${key}`}
                            >
                                <FiberManualRecordIcon sx={{ fontSize: '0.5rem', mr: '10px' }} />
                                <ListItemText primary={t(key)} />
                            </ListItemButton>
                        </React.Fragment>
                    ))}
                </Collapse>

                {/* settings */}
                <ListItemButton onClick={handleClickSettings}>
                    <ListItemIcon>
                        <SettingsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('settings')} />
                </ListItemButton>
            </List>
        </>
    );
}
