// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

// MUI components and icons
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, Chip, Autocomplete, TextField } from '@mui/material';
import { GridActionsCellItem, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import { Delete, AddCircle, Edit, FileDownloadOutlined, Close } from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import ACCEPTMONEYFORM from './MoneyAcceptForm';
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Utility Libraries
import dayjs from 'dayjs';

// Actions and Constants
import { allFees, allReceipt, approvedBooks, deleteFee } from '../../actions/accountActions';
import { DELETE_FEE_RESET, NEW_FEE_RESET, NEW_RECEIPT_RESET, UPDATE_FEE_RESET } from '../../constants/accountConstants';
import { allClass, allStudents } from '../../actions/eduActions';
import { allStaff } from '../../actions/adminisActions';
import { collectionSheet, feesListPdf } from '../../actions/pdfActions';
import { CustomCrossButton } from '../styles/style';
import { getPastTenYears } from '../utils/commonUtils';
import { months_values } from '../../constants/commonContstants';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom filter function
const customFilter = (rows, query) => {
    if (!query) return rows; // No search query

    const searchWords = query.toLowerCase().split(' ');

    return rows.filter(row => {
        return searchWords.every(word => {
            return Object.keys(row).some(key => {
                return row[key].toString().toLowerCase().includes(word);
            });
        });
    });
};

function CustomToolbar({ handleOpen, handleCollection }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);

        const data = {
            heading: t('receiptMoneyRecord'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(feesListPdf(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'boardingSuper' && type !== 'superUser'} onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        <MenuItem onClick={handleCollection}>{t('collectionNoneCollectionSheet')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('receiptMoneyRecord')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function ACCEPTMONEY() {
    const { t } = useTranslation();
    // useRef to handle first render
    const isFirstRender = useRef(true);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const [dataId, setDataId] = useState('')
    const [openDialouge, setOpenDialouge] = useState(false);
    const [clickedButton, setClickedButton] = useState('');
    const pastTenYears = getPastTenYears();
    const months = months_values(t)

    const { fees, loading } = useSelector(state => state.allFees);
    const { PDFLoading } = useSelector(state => state.PDF);

    // get redux state
    const { classes } = useSelector(state => state.allClass);
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];
    const { isSaved } = useSelector(state => state.newReceipt);
    const { isSavedFee } = useSelector(state => state.newFee);
    const { isDeleted, isUpdated } = useSelector(state => state.fee);
    const { handleSubmit, control, getValues } = useForm('');
    const formRef = useRef(null);

    // making dialogs combobox
    // Reusable Autocomplete Component
    const CustomAutocomplete = ({
        name,
        label,
        control,
        defaultValue = '',
        options = [],
        isNumber = false,
        onInputChange = null,
    }) => (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ...field } }) => (
                <Autocomplete
                    freeSolo
                    options={options}
                    fullWidth
                    size="small"
                    value={value || ''} // Fallback for value
                    onInputChange={(event, newInputValue) => {
                        onChange(newInputValue);
                        if (typeof onInputChange === 'function') {
                            onInputChange(newInputValue);
                        }
                    }}
                    getOptionLabel={(option) => option?.toString()} // Convert option to string
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            type={isNumber ? 'number' : 'text'}
                        />
                    )}
                    {...field}
                />
            )}
        />
    );

    // Reusable Select Component
    const CustomSelect = ({ name, label, control, options = [], required = false }) => (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
                <TextField
                    {...field}
                    select
                    fullWidth
                    size="small"
                    label={label}
                    required={required}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );

    // Fetch initial data on component mount
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            dispatch(allFees(code));
            dispatch(allStudents(code));
            dispatch(allStaff(code));
            dispatch(allClass(code));
        }
    }, [dispatch, code]);

    useEffect(() => {
        if (isSaved) {
            dispatch(approvedBooks({ department: 'boarding' }, code));
            dispatch(allReceipt({ department: 'boarding' }, code));
            dispatch({ type: NEW_RECEIPT_RESET })
        }

        if (isSavedFee) {
            dispatch(allFees(code));
            dispatch({ type: NEW_FEE_RESET })
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_FEE_RESET })
            dispatch(allFees(code));
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_FEE_RESET })
            dispatch(allFees(code));
        }
    }, [dispatch, isSavedFee, isSaved, isDeleted, enqueueSnackbar, t, isUpdated, code]);

    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 70, width: 70, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('dataId'), minWidth: 70, width: 70, sortable: true, flex: 0 },
        { field: 'idNo', headerName: t('idNo') },
        { field: 'name', headerName: t('name') },
        { field: 'fatherName', headerName: t('fatherName') },
        { field: 'classOrLevel', headerName: t('classOrLevel') },
        { field: 'months', headerName: t('month') },
        { field: 'year', headerName: t('year') },
        { field: 'regarding', headerName: t('regarding') },
        { field: 'amount', headerName: t('amount') },
        { field: 'date', headerName: t('date') },
        { field: 'note', headerName: t('note') },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 70, width: 70,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'boardingSuper' && type !== 'superUser'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row.id)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'boardingSuper' && type !== 'superUser'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform fees array into the desired format
    let rowCounter = 1;
    const rows = fees?.flatMap((fee) => {
        return fee.receipt_data.map((receiptItem) => {
            return {
                serial: rowCounter++,
                id: `${fee.id}|${receiptItem.id}`, // Fee id for entire row and receiptItem id for JSON data which is storing for months datas
                idNo: fee.provider_id, // Student or staff id
                name: fee.name,
                fatherName: fee.father_name,
                classOrLevel: fee.class_or_level,
                months: receiptItem.selectedMonths.map((month) => t(month)).join(', '),
                year: fee.year,
                regarding: t(receiptItem.subject),
                amount: receiptItem.totalAmount,
                date: `${dayjs(fee.created_at).format('YYYY-MM-DD')}`,
                note: fee.note || ''
            };
        });
    });

    // for handle search by multiple words
    const filteredRows = customFilter(rows);

    const handleEdit = (id) => {
        setOpen(true)
        setDataId(id)
    };

    const handleDelete = (id) => {
        dispatch(deleteFee({ id: id }, code))
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
        setDataId('')
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickDialougeCollection = () => {
        setOpenDialouge(true);
    };

    const handleCloseDialougeCollection = () => {
        setOpenDialouge(false);
    };

    const onSubmitCollection = (data) => {
        dispatch(allStudents(code, { className: data.selectedClass }));
    };

    const subjects = [
        { label: t('boardingFee'), value: 'boardingFee' },
        { label: t('monthlyFee'), value: 'monthlyFee' },
        { label: t('residentialFee'), value: 'residentialFee' },
        { label: t('electricityFee'), value: 'electricityFee' }
    ];

    const handleClick = (buttonType) => {
        setClickedButton(buttonType);

        const data = {
            type: buttonType,
            ...getValues()
        };
        dispatch(collectionSheet(data, code));
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'MONEY ACCEPTED LIST'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <ACCEPTMONEYFORM closeDialog={handleClose} id={dataId} />
            </Dialog>

            <Dialog
                open={openDialouge}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={false}
                PaperProps={{
                    style: {
                        overflow: 'visible',
                        maxWidth: '400px',
                    }
                }}
            >
                <CustomCrossButton
                    onClick={handleCloseDialougeCollection}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                >
                    <Close fontSize='small' />
                </CustomCrossButton>
                <Box component='form' onSubmit={handleSubmit(onSubmitCollection)} ref={formRef} encType='multipart/form-data'>
                    <Grid container spacing={2} padding={3} paddingTop={4} justifyContent="center">
                        {/* Class Name Autocomplete */}
                        <Grid item xs={12}>
                            <CustomAutocomplete
                                name="className"
                                label={t('className')}
                                control={control}
                                options={classnames}
                            />
                        </Grid>

                        {/* Subject Select */}
                        <Grid item xs={12}>
                            <CustomSelect
                                name="subject"
                                label={t('subject')}
                                control={control}
                                options={subjects}
                            />
                        </Grid>

                        {/* Month Select */}
                        <Grid item xs={12}>
                            <CustomSelect
                                name="month"
                                label={t('month')}
                                control={control}
                                options={months}
                            />
                        </Grid>

                        {/* Year Autocomplete */}
                        <Grid item xs={12}>
                            <CustomAutocomplete
                                name="year"
                                label={t('year')}
                                control={control}
                                defaultValue={pastTenYears[0]}
                                options={pastTenYears}
                                isNumber
                            />
                        </Grid>

                        {/* Action Buttons */}
                        <Grid item xs={12} mt={1}>
                            <Grid container columnSpacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <LoadingButton type="submit" color="error" variant="contained"
                                        onClick={() => handleClick('noneCollectionSheet')}
                                        loading={clickedButton === 'noneCollectionSheet' && PDFLoading}
                                    >
                                        {t('noneCollectionSheet')}
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <LoadingButton type="submit" color="success" variant="contained"
                                        onClick={() => handleClick('collectionSheet')}
                                        loading={clickedButton === 'collectionSheet' && PDFLoading}
                                    >
                                        {t('collectionSheet')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>

            <CustomDataGrid
                rows={filteredRows}
                columns={columns}
                loading={loading || PDFLoading}
                localeText={localeText}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} handleCollection={handleClickDialougeCollection} />
                }}
            />
        </Box>
    );
}
