// React and related hooks
import React, { useEffect } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { allApprovedBooks, deleteApprovedBooks, updateApprovedBooks } from '../../actions/accountActions';
import { approvedReceiptBooksPDF } from '../../actions/pdfActions';

// MUI components and icons
import {
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';

import {
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Box,
    Dialog,
    Menu,
    MenuItem
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    FileDownloadOutlined
} from '@mui/icons-material';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

// Custom components and hooks
import APROVINGFORM from './ApprovingForm';
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Utilities and constants
import { MetaData } from '../utils/metaData';
import {
    DELETE_RECEIPT_BOOK_RESET,
    NEW_RECEIPT_BOOK_RESET,
    UPDATE_RECEIPT_BOOK_RESET
} from '../../constants/accountConstants';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { code, type } = useAuth();

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // handle pdf makin using python
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('approvedReceiptBooks'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(approvedReceiptBooksPDF(currentRows, code));
    };


    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'councilMember' && type !== 'superUser'} onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('approvedReceiptBooks')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function APPROVEDRECEIPTLIST() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, type } = useAuth();
    const apiRef = useGridApiRef();

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    // fetch data from redux store
    const { all_approved_books } = useSelector(state => state.allApprovedBooks);
    const { isDeleted, isUpdated } = useSelector(state => state.approvedBook);
    const { isSaved } = useSelector(state => state.newApprovedBook);
    const { PDFLoading } = useSelector(state => state.PDF);

    const [pageCutted, setPageCuttedAmount] = React.useState('');

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    useEffect(() => {
        dispatch(allApprovedBooks(code));

        if (isSaved || isDeleted || isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });

            if (isSaved) {
                dispatch({ type: NEW_RECEIPT_BOOK_RESET });
                setOpen(false);
            }

            if (isDeleted) {
                dispatch({ type: DELETE_RECEIPT_BOOK_RESET });
            }

            if (isUpdated) {
                dispatch({ type: UPDATE_RECEIPT_BOOK_RESET });
            }
        }
    }, [dispatch, isSaved, isDeleted, isUpdated, code, enqueueSnackbar, t, setOpen]);

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('dataId') },
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'book_no', headerName: t('bookNo'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'pages', headerName: t('pages'), editable: true },
        { field: 'cutted', headerName: t('pageCutted') },
        { field: 'remain', headerName: t('remain') },
        { field: 'department', headerName: t('department') },

        {
            field: 'aprover',
            headerName: t('aprover'),

            minWidth: 110,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="Aprover"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        {
            field: 'aprover1',
            headerName: t('aprover'),

            minWidth: 110,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="Aprover"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        { field: 'date', headerName: t('date') },

        {
            field: 'actions',
            headerName: t('actions'),

            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'councilMember' && type !== 'superUser'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'councilMember' && type !== 'superUser'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform all_approved_books array into the desired format
    const rows = all_approved_books?.map((b, index) => {
        return {
            id: b.id,
            serial: index + 1,
            book_no: b.book_no,
            pages: b.pages,
            cutted: b.cutted || 0,
            remain: b.pages - b.cutted,
            department: t(b.department),
            aprover: b.aporver_sign,
            aprover1: b.aporver1_sign,
            date: dayjs(b.date).format('YYYY-MM-DD'),
        };
    });

    const handleEdit = (rowData) => {
        setPageCuttedAmount(rowData.cutted)
        apiRef.current.startCellEditMode({ id: rowData.id, field: 'pages' });
    };

    const handleCellEditStart = (params) => {
        setPageCuttedAmount(params.row.cutted)
    };

    const handleProcessRowUpdate = (newRow) => {

        // handle not allowed amounts
        const newAprovedAmount = parseInt(newRow.pages);

        if (newAprovedAmount < pageCutted || newAprovedAmount <= 0) {
            apiRef.current.stopCellEditMode({ id: newRow.id, field: 'pages' });
            enqueueSnackbar(t('notWorked'), { variant: 'info' });
            return newRow;
        }

        // updating furniture given amount
        const info = {
            id: newRow.id,
            amount: newAprovedAmount
        }

        if (newRow.pages !== newAprovedAmount) {
            dispatch(updateApprovedBooks(info, code));
        }
        return newRow;
    };

    const handleDelete = (rowData) => {
        if (rowData.remain === 0) return enqueueSnackbar(t('notWorked'), { variant: 'error' });

        dispatch(deleteApprovedBooks(rowData.id, code))
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'APPROVED BOOKS'} />
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <APROVINGFORM closeDialog={handleClose} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                apiRef={apiRef}
                loading={PDFLoading}
                onCellEditStart={handleCellEditStart}
                processRowUpdate={handleProcessRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => {
                    console.error('Row update failed: ', error);
                }}
                getRowClassName={(params) =>
                    params.row.remain === 0 ? 'zeroRemainRow' : ''
                }
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            aprover1: false,
                            id: false,
                        },
                    },
                }}
                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />
                }}
            />
        </Box>
    );
}