import React from "react";
import {
    Box,
    Grid,
    CardActionArea,
    CardMedia,
    Chip,
    Card,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { idBackgrounds } from "../../constants/commonContstants";

export default function IDCardDesigns({ handleBackgroundSelect }) {
    const { t } = useTranslation();

    return (
        <Box sx={{ padding: 4 }} textAlign={'center'}>
            <Chip sx={{ fontSize: '1.5rem', marginBottom: 2 }} label={t('chooseAFrame')} />

            <Grid container spacing={2}>
                {idBackgrounds.map((bg) => (
                    <Grid item xs={12} sm={6} md={4} key={bg.id} display={'flex'} justifyContent={'center'}>
                        <Card
                            elevation={3}
                            sx={{
                                width: 300,
                                height: 450,
                                borderRadius: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <CardActionArea
                                onClick={() => handleBackgroundSelect(bg.src)}
                            >
                                <CardMedia
                                    component="img"
                                    height='450'
                                    image={bg.src}
                                    alt={`Background ${bg.id}`}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
