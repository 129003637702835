// React and Related Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Material-UI Imports
import { GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';

// Constants and Actions Imports
import { allStaff, clearErrors, deleteStaff } from '../../actions/adminisActions';
import { staffIdCards, staffListPdf } from '../../actions/pdfActions';
import { DELETE_STAFF_RESET, UPDATE_STAFF_RESET } from '../../constants/adminisConstants';

// Custom Component Imports
import { MetaData } from '../utils/metaData';
import STUFFREGISTERFORM from './NewStaffForm';
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';
import Chip from '@mui/material/Chip';
import IDCardDesigns from '../education/IdCards';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();

    const [currentRows, setCurrentRows] = React.useState('');
    const [openIdFrame, setOpenIdFrame] = React.useState(false);

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns().map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('officiantsList'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(staffListPdf(currentRows, code));
    };

    // handle Id cards making
    const handleIdCards = async (e) => {
        setOpenIdFrame(true);
    };

    const handleCloseIdFrame = () => {
        setOpenIdFrame(false);
    };

    const handleBackgroundSelect = (selectedBackground) => {
        setOpenIdFrame(false);

        const data = {
            info: [t('academicName'), t('fullAddress'), t('idNo'), t('designation'), t('name'), t('fatherName'), t('mobile'), t('address')],
            background: selectedBackground,
            rows: currentRows.rows
        }

        dispatch(staffIdCards(data, code));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Dialog
                open={openIdFrame}
                onClose={handleCloseIdFrame}
                maxWidth='lg'
            >
                <IDCardDesigns handleBackgroundSelect={handleBackgroundSelect} />
            </Dialog>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={4} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        {/* <Button disabled={type !== 'principal'} onClick={handleOpen}><AddCircleIcon /></Button> */}
                        <Button onClick={handleOpen}><AddCircleIcon /></Button>
                        <Button onClick={handleDownloadFile}><FileDownloadOutlined /></Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        <MenuItem onClick={handleIdCards}>{t('officiantsIdCard')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={4} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('officiantsList')} />
                </Grid>
                <Grid item xs={6} sm={4} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'right' }}>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>

            </Grid>
        </GridToolbarContainer >
    );
}

export default function OFICIANTS() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    // import these info from reducers those are bound in store.js
    const { error, staff, loading } = useSelector(state => state.allStaff);
    const { isUpdated, isDeleted } = useSelector(state => state.staff)
    const { PDFLoading } = useSelector(state => state.PDF);

    const [selectedId, setSelectedId] = useState('');

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allStaff(code));
    }, [dispatch, code])

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors())
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_STAFF_RESET })
            setOpen(false);
            dispatch(allStaff(code));
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allStaff(code));
            dispatch({ type: DELETE_STAFF_RESET })
        }
    }, [dispatch, isDeleted, isUpdated, t, enqueueSnackbar, error, code]);

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), disableColumnMenu: false, minWidth: 80, width: 80, flex: 0 },
        { field: 'idNo', headerName: t('idNo'), sortable: true, minWidth: 80, width: 80, flex: 0 },
        {
            field: 'imageUrl',
            headerName: t('picture'),
            width: 80,
            flex: 0,
            minWidth: 80,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '32px', height: '32px', borderRadius: '50%', marginTop: '1.4px' }}
                />
            ),
        },

        { field: 'name', headerName: t('name') },
        { field: 'type', headerName: t('type') },
        { field: 'designation', headerName: t('designation') },
        { field: 'mobile', headerName: t('mobile') },
        { field: 'nid', headerName: t('nid') },
        { field: 'address', headerName: t('address') },
        { field: 'salaryAmount', headerName: t('salaryAmount') },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            width: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'principal' && type !== 'superUser'}
                    icon={<EditIcon />}
                    label="edit"
                    onClick={() => handleEdit(params.row.dataId)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'principal' && type !== 'superUser'}
                    icon={<DeleteIcon
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.nid)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform staff array into the desired format
    const rows = staff?.map((staff, index) => {
        return {
            id: index + 1,
            idNo: staff.staff_id,
            dataId: staff.id,
            nid: staff.nid,
            imageUrl: staff.photo_url,
            name: staff.name,
            type: t(staff.candidate_type),
            designation: staff.designation,
            mobile: staff.mobile,
            address: `${staff.village}, ${staff.sub_district}, ${staff.district}`,
            salaryAmount: staff.salary,
        };
    });

    const handleEdit = (dataId) => {
        setSelectedId(dataId);
        setOpen(true);
    };

    const handleDelete = (nid) => {
        dispatch(deleteStaff({ nid: nid }, code))
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setSelectedId('');
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(allStaff(code));
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign" >
            <MetaData title={'OFFICIANTS LIST'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <STUFFREGISTERFORM closeDialog={handleClose} staffId={selectedId} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                localeText={localeText}
                loading={loading || PDFLoading}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            nid: false,
                            salaryAmount: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                }}
            />
        </Box>
    );
}
