// React and Hooks
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import {
    Grid, Button, ButtonGroup, Box, Menu, MenuItem, Chip, Dialog
} from '@mui/material';

// MUI Icons
import {
    ChecklistOutlined, FileDownloadOutlined
} from '@mui/icons-material';

// MUI DataGrid Components
import {
    GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';

// Custom Components & Utilities
import { MetaData } from '../utils/metaData';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';
import useAuth from '../hooks/UseAuth';

// Redux Actions
import { allBooks } from '../../actions/libraryActions';
import { allStudents } from '../../actions/eduActions';
import { totalBooksCountPdf } from '../../actions/pdfActions';

// Constants
import { NEW_PROVIDED_BOOK_RESET } from '../../constants/libraryConstants';

// Forms and Dialogs
import GIVENBOOKFORM from './GivingBookForm';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { code } = useAuth();

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('totalBooks'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(totalBooksCountPdf(currentRows, code))
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleOpen}><ChecklistOutlined /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('totalBooks')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function GIVENBOOKS() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useAuth();

    const { books, loading } = useSelector(state => state.allBooks);
    const { isSaved } = useSelector(state => state.newProvidedBooks);
    const { PDFLoading } = useSelector(state => state.PDF);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allBooks(code))
        dispatch(allStudents(code));
    }, [dispatch, code])


    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_PROVIDED_BOOK_RESET })
            dispatch(allBooks(code))
        }

    }, [dispatch, code, isSaved, t, enqueueSnackbar])



    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'bookName', headerName: t('bookName') },
        { field: 'writer', headerName: t('writer') },
        { field: 'part', headerName: t('part') },
        { field: 'totalHas', headerName: t('totalHas') },
        { field: 'totalGiven', headerName: t('given') },
        { field: 'totalRemain', headerName: t('remain') },
    ]);

    // Prepare the rows for mui data grid
    // Step 1: Group the books by 'book_name' and 'writer'
    const groupedBooks = books.reduce((acc, book, index) => {
        const key = `${book.book_name}-${book.writer}-${book.part}`;

        // Check if the group already exists
        if (!acc[key]) {
            acc[key] = {
                id: index + 1,
                bookName: book.book_name,
                writer: book.writer,
                part: book.part,
                totalHas: 0,
                totalGiven: 0,
                totalRemain: 0
            };
        }

        // Increment total books
        acc[key].totalHas++;

        // Count books with 'out' condition as totalGiven
        if (book.condition === 'out') {
            acc[key].totalGiven++;
        }

        // Calculate remaining books
        acc[key].totalRemain = acc[key].totalHas - acc[key].totalGiven;

        return acc;
    }, {});

    // Step 2: Convert grouped data into an array format for MUI Data Grid
    const rows = Object.keys(groupedBooks).map(key => groupedBooks[key]);

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'TOTAL BOOKS'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <GIVENBOOKFORM closeDialog={handleClose} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                loading={loading || PDFLoading}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />
                }}
            />
        </Box>
    );
}
