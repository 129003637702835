import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../layout/MiniComponents';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to dashboard if authenticated and loading is complete
        if (!loading && isAuthenticated && user?.code) {
            navigate(`/${user.code}`, { replace: true });
        }
        if (isAuthenticated === false && loading === false) {
            navigate('/login', { replace: true });
        }
    }, [isAuthenticated, loading, navigate, user?.code]);

    // Show loading screen while authentication status is being checked
    if (loading) return <LoadingScreen />;

    // Render children if user is authenticated and loading is complete
    return isAuthenticated && user?.id ? children : null; // user.id is checking visitor
};

export default ProtectedRoute;