// React and Hooks
import React, { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import {
    TextField, Grid, Box, Container, Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Custom Components and Actions
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

// Redux Constants
import { CLEAR_ERRORS, NEW_ACADEMY_RESET } from '../../constants/superAdminConstants';
import { MetaData } from '../utils/metaData';
import { updateAcademyBalances } from '../../actions/superAdminActions';
import Switch from '@mui/material/Switch';

export default function UPDATEACADEMYFORM({ academy }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formRef = useRef(null);

    // getting the value from resux according to the id for using by default
    const { isSaved, loading, error } = useSelector(state => state.newAcademy);

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: academy.name || '',
            numberOfStudents: academy.numberOfStudents || 0,
            email: academy.email || '',
            balance: academy.balance || '',
            expiryDate: academy.expiryDate || '',
            payment: academy.payment || '',
            activation: academy.activation || ''
        },
    });

    // import these info from reducers those are bound in store.js
    useEffect(() => {
        if (isSaved) {
            dispatch({ type: NEW_ACADEMY_RESET })
            navigate('/'); // redirect to the login page
            // setStep(2)
        }
        if (error) {
            dispatch({ type: CLEAR_ERRORS })
        }
    }, [dispatch, error, isSaved, enqueueSnackbar, t, navigate])


    // handle submit
    const onSubmit = (data) => {
        dispatch(updateAcademyBalances(data, academy.id));
    };

    const lbls = ['numberOfStudents', 'email', 'balance', 'expiryDate', 'payment', 'activation'];

    return (
        <>
            <Container maxWidth={"xs"} sx={{
                borderRadius: '10px',
                alignItems: 'center',
                display: 'flex',
                padding: 2
            }}>
                <MetaData title={'UPDATE ACADEMIC INFO'} />
                <Box component="form" onSubmit={handleSubmit(onSubmit)} ref={formRef} encType="multipart/form-data">
                    <Paper elevation={1}>
                        <Box
                            sx={{
                                padding: '1.2rem',
                                borderRadius: '8px',
                                position: 'relative'
                            }}
                        >
                            <Grid container spacing={1.3}>
                                {lbls.map((lbl, index) => (
                                    <Grid item xs={12} key={index}>
                                        {lbl === 'expiryDate' ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    key={`controller-${lbl}`}
                                                    name={lbl}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            value={field.value ? dayjs(field.value) : null}
                                                            label={t(lbl)}
                                                            format="YYYY-MM-DD"
                                                            slotProps={{
                                                                textField: {
                                                                    size: 'small',
                                                                    fullWidth: true,
                                                                    required: true,
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        ) : lbl === 'activation' ? (
                                            <Controller
                                                name="activation"
                                                control={control}
                                                render={({ field }) => (
                                                    <Switch {...field} checked={!!field.value} onChange={(e) => field.onChange(e.target.checked)} />
                                                )}
                                            />
                                        ) : (
                                            <Controller
                                                key={`controller-${lbl}`}
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        label={t(lbl)}
                                                        type={
                                                            lbl === 'numberOfStudents' || lbl === 'balance' || lbl === 'payment'
                                                                ? 'number'
                                                                : 'text'
                                                        }
                                                        required
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container justifyContent="flex-end" spacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading}
                                        variant="contained"
                                    >
                                        {t('update')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container >
        </>
    );
}