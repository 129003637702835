// React and related hooks
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI components and icons
import { Grid, Button, ButtonGroup, Chip, Box, Menu, MenuItem, Dialog } from '@mui/material';
import { Delete, AddCircle, Edit, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import { useSnackbar } from 'notistack';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Actions and constants
import { reportedStudentListPdf } from '../../actions/pdfActions';
import { allStudentReports, clearErrors, deleteStudentReport } from '../../actions/hostelActions';
import { allStudents } from '../../actions/eduActions';
import { getReportStages } from '../../constants/commonContstants';
import { DELETE_STUDENT_REPORT_RESET, NEW_STUDENT_REPORT_RESET, UPDATE_STUDENT_REPORT_RESET } from '../../constants/hostelConstants';

// Other libraries
import dayjs from 'dayjs';

// Components
import NEWREPORTFORM from './ReportForm';
import { GridActionsCellItem, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import useAuth from '../hooks/UseAuth';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions' && column.field !== 'giver')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('reportedStudentList'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(reportedStudentListPdf(currentRows, code));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('reportedStudentList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function REPORTEDSTUDENTS() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const reportStages = getReportStages(t)
    const isFirstRender = useRef(true);

    // import these info from reducers those are bound in store.js
    const { error, studentReports } = useSelector(state => state.std_reports);
    const { isSaved } = useSelector(state => state.newStudentReport)
    const { isUpdated, isDeleted } = useSelector(state => state.studentReport);
    const { PDFLoading } = useSelector(state => state.PDF);

    const [rowData, setRowData] = useState('');

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;
            dispatch(allStudentReports(code));

            // Dispatch allStudents in the background
            dispatch(allStudents(code));
        }
    }, [dispatch, code]);

    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_STUDENT_REPORT_RESET })
            dispatch(allStudentReports(code));
        }

        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors())
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_STUDENT_REPORT_RESET })
            dispatch(allStudentReports(code));
            setOpen(false);
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_STUDENT_REPORT_RESET })
            dispatch(allStudentReports(code));
        }
    }, [dispatch, isDeleted, isUpdated, t, enqueueSnackbar, error, isSaved, code]);

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('dataId') },
        { field: 'admitNo', headerName: t('admitNo'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'name', headerName: t('studentName') },
        { field: 'department', headerName: t('department') },
        { field: 'class', headerName: t('class') },
        { field: 'date', headerName: t('date') },
        { field: 'reportStage', headerName: t('reportStage') },
        { field: 'giver', headerName: t('giver') },
        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row.id)}
                />,
                <GridActionsCellItem
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform staffs array into the desired format
    const rows = studentReports?.map((report, index) => {
        return {
            serial: index + 1,
            id: report.id,
            admitNo: report.admit_no,
            name: report.student_name,
            department: report.department,
            class: report.class_name,
            date: `${dayjs(report.date).format('YYYY-MM-DD')}`,
            reportStage: t(report.report_stage),
            giver: report.giver
        };
    });

    const handleEdit = async (id) => {
        const rowData = await studentReports.find(report => report.id === id);
        setRowData(rowData);
        setOpen(true);
    };

    const handleDelete = (id) => {
        dispatch(deleteStudentReport({ id: id }, code))
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
        setRowData('')
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'REPORTED STUDENTS'} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <NEWREPORTFORM closeDialog={handleClose} rowData={rowData} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                loading={PDFLoading}
                getRowClassName={(params) => {
                    const stage = reportStages.find(stage => stage.label === params.row.reportStage);
                    const reportStage = stage ? stage.value : undefined;
                    return reportStage === 'intermediate' ? 'warning-row' :
                        reportStage === 'difficult' ? 'danger-row' : '';
                }}

                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false,
                            giver: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />
                }}
            />
        </Box>
    );
}
