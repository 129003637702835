import React, { useState, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { useTranslation } from 'react-i18next';

export default function RICHTEXTEDITOR({ onContentChange, editContent }) {
    const { t } = useTranslation();
    const [content, setContent] = useState(''); // Content of the editor

    // Jodit configuration (you can customize it further)
    const config = useMemo(() => ({
        readonly: false, // Allow editing
        placeholder: t('writeHereYourContent'),
        toolbar: true, // Ensure toolbar is enabled
    }), [t]);

    // Update the content when `editContent` prop changes
    useEffect(() => {
        if (editContent) {
            setContent(editContent);
        }
    }, [editContent]);

    // Handle editor changes and update content
    const onChange = (newContent) => {
        setContent(newContent); // Update the editor state
        onContentChange(newContent); // Pass the content to the parent component
    };

    // Handle editor blur (optional)
    const onBlur = (newContent) => {
        return
    };

    return (
        <div>
            <JoditEditor
                value={content}
                config={config}
                tabIndex={1} // Set tabIndex if needed
                onBlur={onBlur} // Handle blur event
                onChange={onChange} // Handle content change
            />
        </div>
    );
}
