// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Button, TextField, Grid, Box, Container, MenuItem, Card, CardMedia,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Autocomplete, Chip
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and utilities
import { FORMHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions and Constants
import { newStudent, allClass, updateStudent, allStudents } from '../../actions/eduActions';
import { allUsers } from '../../actions/authActions';
import { inspectorComments, approvals } from '../../constants/commonContstants';
import { nanoid } from 'nanoid';
import { NEW_STUDENT_RESET, UPDATE_STUDENT_RESET } from '../../constants/eduConstants';
import { allStaff } from '../../actions/adminisActions';
import { containsNonLanguageLetter } from '../utils/commonUtils';
import { getSettings, sendSMS } from '../../actions/othersActions';
import { SEND_SMS_RESET } from '../../constants/othersConstants';

// custom text fields for principal departments
const CustomTextField = ({ name, label, control, setValue, t, additionalStyles }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <TextField
                {...field}
                size="small"
                fullWidth
                type='number'
                onChange={(event) => {
                    field.onChange(event);
                    setValue('principalSign', '');
                }}
                label={t(label)}
                InputProps={{
                    style: { ...additionalStyles, borderRadius: '0px' },
                    disableUnderline: true
                }}
                variant='filled'
            />
        )}
    />
);

export default function ADMISSIONFORM({ closeDialog, id, visitor }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, email, type, smsBalance } = useAuth();

    const { enqueueSnackbar } = useSnackbar();
    const [catchID, setCatchID] = useState(id);
    const [inspectorSgnPreview, setInspectorSgnPreview] = useState('');
    const [examinySignPreview, setExaminySignPreview] = useState('');
    const [eduSecSgnPreview, setEduSecSgnPreview] = useState('');
    const [principalSingPreview, setPrincipalSignPreview] = useState('');
    const [photoView, setPhotoView] = useState('');
    const [photo, setPhoto] = useState('');
    const formRef = useRef(null);

    // getting the value from redux
    const { students } = useSelector(state => state.allStudent);
    const { users } = useSelector(state => state.allUsers);
    const { classes } = useSelector(state => state.allClass);
    const { isSaved } = useSelector(state => state.newStudent);
    const { isUpdated } = useSelector(state => state.student);
    const { staff } = useSelector(state => state.allStaff);

    // Find student according to the form no
    const student = students?.find(student => student.id === id && student.code === code);
    const departments = [...new Set(classes?.map(depart => depart.department))];
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];
    const groupName = [...new Set(classes?.map(classItem => classItem.group_name))];
    const staffs = [...new Set(staff?.map(s => s.name))];
    const { allSettings } = useSelector(state => state.settings);

    // Find the maximum form_no and admit_no, handling empty or undefined students
    const maxFormNo = students?.reduce((max, student) =>
        Math.max(max, student.form_no || 0), 0);

    const maxAdmitNo = students?.reduce((max, student) =>
        Math.max(max, student.admit_no || 0), 0);

    // First, ensure departments array is defined and includes student.department if necessary
    let updatedDepartments = departments ? [...departments] : [];

    if (student?.department && !updatedDepartments.includes(student.department)) {
        updatedDepartments.push(student.department);
    }

    const basicValues = [
        ['new', 'old'],
        updatedDepartments,
        ['residential', 'nonResidential']
    ];

    const defaultValues = {
        id: student?.id || nanoid(),
        code: code,
        newOld: student?.new_old || '',
        department: student?.department || '',
        residenceType: student?.residence || '',
        formNo: student?.form_no || maxFormNo + 1,
        admitNo: student?.admit_no || maxAdmitNo + 1,
        name: student?.name || '',
        fatherName: student?.father_name || '',
        village: student?.village || '',
        postOffice: student?.post_office || '',
        subDistrict: student?.sub_district || '',
        district: student?.district || '',
        birthDate: student?.birth_date || '',
        age: student?.age || '',
        fatherMobile: student?.father_mobile || '',
        fatherJob: student?.father_job || '',
        guardian2: student?.guardian2 || '',
        preAcademy: student?.pre_academy || '',
        preClass: student?.pre_class || '',
        choosingClass: student?.choosing_class || '',
        inspectorComment: student?.inspector_comment || '',
        examiner: student?.examiner || '',
        book1: student?.book1 || '',
        number1: student?.number1 || '',
        average: student?.average || '',
        book2: student?.book2 || '',
        number2: student?.number2 || '',
        approvedClass: student?.admitted_class || '',
        groupName: student?.group_name || '',
        admissionFee: student?.admission_fee || '',
        boardingFee: student?.boarding_fee || '',
        monthlyFee: student?.monthly_fee || '',
        residentialFee: student?.residential_fee || '',
        electricityFee: student?.electricity_fee || '',
        developmentFee: student?.development_fee || '',
        approval: student?.approval || '',
        inspectorSign: '',
        examinerSign: '',
        eduSecretarySign: '',
        principalSign: ''
    };

    const { handleSubmit, control, reset, setValue, getValues } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (!visitor) {
            dispatch(allUsers({ email: email }, code))
            dispatch(allClass(code))
            dispatch(allStaff(code))
            dispatch(getSettings(code));
        }
    }, [dispatch, code, email, student, visitor])

    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            setPhoto('')
            setPhotoView('https://')
            reset();
            setValue('id', nanoid())
            dispatch({ type: NEW_STUDENT_RESET })
            dispatch(allStudents(code))
        }
        if (isUpdated) {
            closeDialog();
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_STUDENT_RESET })
            dispatch(allStudents(code));
        }
    }, [dispatch, code, isSaved, enqueueSnackbar, t, reset, setValue, closeDialog, isUpdated, getValues, smsBalance])

    const [storeValues, setStoreValues] = useState({ inspectorComment: '', exmnyName: '', book1: '', book2: '', number1: '', number2: '', average: '', approvedClass: '', admissionFee: '', boardingFee: '', monthlyFee: '', residentialFee: '', electricityFee: '', developmentFee: '', approval: '' });

    useEffect(() => {
        setStoreValues({
            inspectorComment: student?.inspector_comment || '',
            exmnyName: student?.examiner || '',
            book1: student?.book1 || '',
            book2: student?.book2 || '',
            number1: student?.number1 || '',
            number2: student?.number2 || '',
            average: student?.average || '',
            approvedClass: student?.admitted_class || '',
            admissionFee: student?.admission_fee || '',
            boardingFee: student?.boarding_fee || '',
            monthlyFee: student?.monthly_fee || '',
            residentialFee: student?.residential_fee || '',
            electricityFee: student?.electricity_fee || '',
            developmentFee: student?.development_fee || '',
            approval: student?.approval || ''
        });

        if (student && student.inspector_sign) {
            setInspectorSgnPreview(student.inspector_sign)
            setValue('inspectorSign', student.inspector_sign)
        }

        if (student && student.examiner_sign) {
            setExaminySignPreview(student.examiner_sign)
            setValue('examinerSign', student.examiner_sign)
        }

        if (student && student.edu_sec_sign) {
            setEduSecSgnPreview(student.edu_sec_sign)
            setValue('eduSecretarySign', student.edu_sec_sign)
        }

        if (student && student.principal_sign) {
            setPrincipalSignPreview(student.principal_sign)
            setValue('principalSign', student.principal_sign)
        }

        if (student && student.photo_url) {
            setPhotoView(student.photo_url)
        } else {
            setPhotoView('')
        }
    }, [student, setValue]);

    // handle clear
    const handleClear = () => {
        setPhoto('')
        setPhotoView('https://')
        reset('');
        setCatchID('')
    };

    const lbls = ['newOld', 'department', 'residenceType', 'formNo', 'admitNo'];
    const lbls1 = ['name', 'fatherName', 'village', 'postOffice', 'subDistrict', 'district', 'birthDate', 'age', 'fatherMobile', 'fatherJob', 'guardian2', 'preAcademy', 'preClass', 'choosingClass'];

    // handle submit
    const onSubmit = (data) => {

        if (type !== 'superUser') {
            if ((data.inspectorComment !== storeValues.inspectorComment || data.examiner !== storeValues.exmnyName) && data.inspectorSign === '') {
                return enqueueSnackbar(t('inspectorSignRequire'), { variant: 'error' });
            } else if ((data.inspectorComment !== storeValues.inspectorComment || data.examiner !== storeValues.exmnyName) && data.inspectorSign !== '') {
                const findInspector = users?.find(inspector => inspector.password === data.inspectorSign);
                if (!findInspector) {
                    setValue('inspectorSign', '');
                    return enqueueSnackbar(t('incorrectCode'), { variant: 'error' });
                } else {
                    setInspectorSgnPreview(findInspector.sign_url)
                    data.inspectorSign = findInspector.sign_url;
                }
            }

            if ((data.book1 !== storeValues.book1 || data.book2 !== storeValues.book2 || data.number1 !== storeValues.number1 || data.number2 !== storeValues.number2 || data.average !== storeValues.average) && data.examinerSign === '') {
                setValue('examinerSign', '');
                return enqueueSnackbar(t('rquireExmnySgn'), { variant: 'error' });
            } else if ((data.book1 !== storeValues.book1 || data.book2 !== storeValues.book2 || data.number1 !== storeValues.number1 || data.number2 !== storeValues.number2) && data.examinerSign !== '') {
                const findExaminer = users?.find(examiner => examiner.password === data.examinerSign);
                if (!findExaminer) {
                    setValue('examinerSign', '');
                    return enqueueSnackbar(t('incorrectCode'), { variant: 'error' });
                } else {
                    setExaminySignPreview(findExaminer.sign_url)
                    data.examinerSign = findExaminer.sign_url;
                }
            }

            if ((data.approvedClass !== storeValues.approvedClass) && data.eduSecretarySign === '') {
                setValue('eduSecretarySign', '');
                return enqueueSnackbar(t('eduSecCode'), { variant: 'error' });
            } else if ((data.approvedClass !== storeValues.approvedClass) && data.eduSecretarySign !== '') {
                const findEduSecretary = users?.find(edu_sec => edu_sec.password === data.eduSecretarySign && edu_sec.type === 'education');

                if (!findEduSecretary) {
                    setValue('eduSecretarySign', '');
                    return enqueueSnackbar(t('incorrectCode'), { variant: 'error' });
                } else {
                    setEduSecSgnPreview(findEduSecretary.sign_url)
                    data.eduSecretarySign = findEduSecretary.sign_url;
                }
            }

            if ((data.admissionFee !== storeValues.admissionFee || data.boardingFee !== storeValues.boardingFee || data.monthlyFee !== storeValues.monthlyFee || data.residentialFee !== storeValues.residentialFee || data.electricityFee !== storeValues.electricityFee || data.developmentFee !== storeValues.developmentFee || data.approval !== storeValues.approval) && data.principalSign === '') {
                setValue('principalSign', '');
                return enqueueSnackbar(t('principalCode'), { variant: 'error' });
            } else if ((data.admissionFee !== storeValues.admissionFee || data.boardingFee !== storeValues.boardingFee || data.monthlyFee !== storeValues.monthlyFee || data.residentialFee !== storeValues.residentialFee || data.electricityFee !== storeValues.electricityFee || data.developmentFee !== storeValues.developmentFee || data.approval !== storeValues.approval) && data.principalSign !== '') {
                const findPrincipal = users?.find(p => p.password === data.principalSign && p.type === 'principal');
                if (!findPrincipal) {
                    setValue('principalSign', '');
                    return enqueueSnackbar(t('incorrectCode'), { variant: 'error' });
                } else {
                    setPrincipalSignPreview(findPrincipal.sign_url)
                    data.principalSign = findPrincipal.sign_url;
                }
            }
        }

        const finalData = {
            ...data,
            photo: photo
        }

        const student = students?.find(student => student.admit_no === parseInt(data.admitNo) && student.code === code);
        if (!catchID) {
            // check duplicates
            if (student) {
                return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'info' });
            } else {
                dispatch(newStudent(finalData, code));
            }
        } else {
            dispatch(updateStudent(finalData, code));
        }

        if (data.admitNo && !student && allSettings.admission_sms && !visitor) {
            // sending auto sms
            const fullSMS = `${allSettings.admission_sms}\n${t('name')}: ${data.name}\n${t('class')}: ${data.approvedClass}\n${t('admitNo')}: ${data.admitNo}`;
            const batchSize = containsNonLanguageLetter(fullSMS) ? 60 : 150;
            const messageCount = Math.ceil(fullSMS.length / batchSize) || 1;
            dispatch(sendSMS({ mobile: data.fatherMobile, message: fullSMS, messageCount: messageCount }, code));
            dispatch({ type: SEND_SMS_RESET })
        }
    };

    // handle profile
    const handlephoto = (data) => {
        setPhoto(data);
    };

    const fields = [
        { name: 'admissionFee', label: 'admissionFee' },
        { name: 'boardingFee', label: 'boardingFee' },
        { name: 'monthlyFee', label: 'monthlyFee' },
        { name: 'residentialFee', label: 'residentialFee' },
        { name: 'electricityFee', label: 'electricityFee' },
        { name: 'developmentFee', label: 'developmentFee' },
    ];

    return (
        <Box className="globalShapeDesign" paddingRight={'5px'}>
            <MetaData title={'ADMISSION FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Container className='customGridClass' maxWidth={'md'} sx={{ marginTop: '5px', marginBottom: '5px', borderRadius: '10px' }}>
                <Box sx={{
                    boxShadow: '0px 3px 2px -2px rgba(0, 0, 0, 0.2)',
                    width: '100%'
                }}>
                    <FORMHEADING onSendData={handlephoto} photoView={photoView} text={'admissionForm'} />
                </Box>
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container sx={{
                        height: {
                            xs: 'auto',
                            sm: '480px'
                        },
                        padding: '3px',
                        overflow: 'auto',
                        '&::-webkit-scrollbar-track': {
                            marginTop: '130px',
                            marginBottom: '5px'
                        },

                    }}>
                        <Grid container spacing={1.5} paddingBottom={0.2} paddingTop={1}>
                            {lbls.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={lbl === 'department' || lbl === 'residenceType' ? 3 : 2}
                                    key={`grid-${index}`}
                                >
                                    <Controller
                                        name={`id`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                sx={{
                                                    display: 'none'
                                                }}
                                            />
                                        )}
                                    />

                                    {lbl === 'newOld' || lbl === 'department' || lbl === 'residenceType' ?
                                        <Controller
                                            key={`controller-${index}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => {
                                                // Determine the correct list of options
                                                const options = basicValues[lbl === 'newOld' ? 0 : lbl === 'department' ? 1 : 2];

                                                // Check if the current value is valid
                                                const isValueValid = options.includes(field.value);

                                                return (
                                                    <TextField
                                                        {...field}
                                                        autoComplete="given-name"
                                                        fullWidth
                                                        size="small"
                                                        id={`idno${index}`}
                                                        select
                                                        label={
                                                            lbl === 'newOld'
                                                                ? t('newOld')
                                                                : lbl === 'department'
                                                                    ? t('department')
                                                                    : t('residenceType')
                                                        }
                                                        required
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: '0px',
                                                            },
                                                        }}
                                                        value={isValueValid ? field.value : ''} // Ensure the value is valid
                                                        onChange={(event) => field.onChange(event.target.value)} // Update value
                                                    >
                                                        {options.map((option, index) => (
                                                            <MenuItem key={`${option}-${index}`} value={option}>
                                                                {t(option)}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                );
                                            }}
                                        />
                                        :
                                        <Controller
                                            key={`controller-${index}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type='number'
                                                    fullWidth
                                                    sx={{ display: lbl === 'admitNo' && visitor ? 'none' : 'block' }}
                                                    size="small"
                                                    id={`idno${index}`}
                                                    label={t(lbl)}
                                                    disabled={visitor}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                            ))}

                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent={'center'}
                                mt={2}
                            >
                                <Chip sx={{
                                    fontSize: '1rem',
                                }} label={t('studentAddress')} />
                            </Grid>
                            {lbls1.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={`grid2-${index}`}
                                >
                                    <Controller
                                        key={`controller-${index}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                required
                                                type={lbl === 'age' ? 'number' : 'text'}
                                                size="small"
                                                id={`id${index}`}
                                                label={t(lbl)}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: '0px'
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Box sx={{ display: visitor ? 'none' : 'block' }}>
                            <Grid container mt={3}>
                                <Grid item xs={12} sx={{ padding: '8px', textAlign: 'center' }}>
                                    <Chip sx={{
                                        fontSize: '1rem',
                                    }} label={t('forOffice')} />
                                </Grid>
                                {['inspectorComment', 'examiner'].map((name) => (
                                    <Grid item key={name} xs={12} sm={name === 'inspectorComment' ? 5 : 4} className='customGridClass'>
                                        <Controller
                                            name={name}
                                            control={control}
                                            render={({ field: { onChange, value, ...field } }) => (
                                                <Autocomplete
                                                    freeSolo
                                                    options={
                                                        name === 'inspectorComment'
                                                            ? inspectorComments
                                                            : staffs
                                                    }
                                                    fullWidth
                                                    value={value}
                                                    onInputChange={(event, newInputValue) => {
                                                        onChange(newInputValue);
                                                        setValue('inspectorSign', '');
                                                        setInspectorSgnPreview('');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            label={t(name === 'inspectorComment' ? 'inspectorComment' : 'examinerName')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: {
                                                                    borderRadius: '0px'
                                                                },
                                                                disableUnderline: true
                                                            }}
                                                            variant='filled'
                                                        />
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                ))}
                                <Grid item xs={12} sm={3} className='customGridClass'>
                                    {catchID && inspectorSgnPreview ?
                                        <Button component='label'
                                            sx={{
                                                padding: '0px'
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={inspectorSgnPreview}
                                                alt="signpreview"
                                            />
                                        </Button>
                                        :
                                        <Controller
                                            name={'inspectorSign'}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    size="small"
                                                    type='password'
                                                    label={t('inspectorSign')}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px'
                                                        },
                                                        disableUnderline: true
                                                    }}
                                                    variant='filled'
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                            </Grid>

                            <TableContainer sx={{ marginTop: 4 }}>
                                <Table aria-label="custom table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" rowSpan={2} sx={{ fontSize: '1rem', width: '5%', padding: '8px' }}>
                                                {t('serialNo1')}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '1rem', width: '50%', padding: '10px' }}>
                                                {t('examResult')}
                                            </TableCell>

                                            <TableCell align="center" rowSpan={2} sx={{ width: '10%', fontSize: '1rem', padding: '8px' }}>{t('gainedNumber')}</TableCell>

                                            <TableCell align="center" rowSpan={2} sx={{ fontSize: '1rem', width: '10%', padding: '8px' }}>
                                                {t('percentage')}
                                            </TableCell>
                                            <TableCell align="center" rowSpan={2} sx={{ fontSize: '1rem', width: '15%', padding: '8px' }}>
                                                {t('examinySign')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" sx={{ width: '50%', fontSize: '1rem', padding: '8px' }}>{t('bookName')}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Array.from({ length: 2 }, (_, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                <TableCell align="center" sx={{ fontSize: '1rem', padding: '0px' }}>{rowIndex + 1}</TableCell>
                                                <TableCell align="center" sx={{ padding: '5px' }}>
                                                    <Controller
                                                        name={`book${rowIndex + 1}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="small"
                                                                fullWidth
                                                                onChange={(event) => {
                                                                    field.onChange(event); // ensure to call the original onChange
                                                                    setValue('examinerSign', '');
                                                                    setExaminySignPreview('')
                                                                }}
                                                                InputProps={{
                                                                    disableUnderline: true
                                                                }}
                                                                variant="standard"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '0px' }}>
                                                    <Controller
                                                        name={`number${rowIndex + 1}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="small"
                                                                fullWidth
                                                                type="number"
                                                                onChange={(event) => {
                                                                    field.onChange(event); // ensure to call the original onChange
                                                                    setValue('examinerSign', '');
                                                                    setExaminySignPreview('')
                                                                }}
                                                                InputProps={{
                                                                    sx: {
                                                                        alignItems: 'center',
                                                                        '& input': {
                                                                            textAlign: 'center'
                                                                        },
                                                                    },
                                                                    disableUnderline: true
                                                                }}
                                                                variant="standard"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                {rowIndex === 0 && (
                                                    <>
                                                        <TableCell key="average" rowSpan={2} align="center" sx={{ padding: '0px' }}>
                                                            <Controller
                                                                name="average"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        size="small"
                                                                        fullWidth
                                                                        onChange={(event) => {
                                                                            field.onChange(event); // ensure to call the original onChange
                                                                            setValue('examinerSign', '');
                                                                            setExaminySignPreview('')
                                                                        }}
                                                                        InputProps={{
                                                                            sx: {
                                                                                alignItems: 'center',
                                                                                '& input': {
                                                                                    height: '50px',
                                                                                    textAlign: 'center'
                                                                                },
                                                                            },
                                                                            disableUnderline: true
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell key="examinerSign" rowSpan={2} align="center" sx={{ padding: '0px' }}>
                                                            {catchID && examinySignPreview ?
                                                                <Button component='label'
                                                                    sx={{
                                                                        padding: '0px'
                                                                    }}
                                                                >
                                                                    <Card elevation={0}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="40"
                                                                            image={examinySignPreview}
                                                                            alt="signpreview"
                                                                        />
                                                                    </Card>
                                                                </Button>
                                                                :
                                                                <Controller
                                                                    name="examinerSign"
                                                                    control={control} // pass the control object
                                                                    render={({ field }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            size="small"
                                                                            fullWidth
                                                                            type="password"
                                                                            InputProps={{
                                                                                sx: {
                                                                                    alignItems: 'center',
                                                                                    '& input': {
                                                                                        height: '50px',
                                                                                        textAlign: 'center'
                                                                                    },
                                                                                },
                                                                                disableUnderline: true
                                                                            }}
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container mt={3}>
                                <Grid item xs={12} sx={{ padding: '8px', textAlign: 'center' }}>
                                    <Chip sx={{ fontSize: '1rem' }} label={t('approvalOfEduSecr')} />
                                </Grid>

                                {/** Autocomplete Fields for Class and Group Name */}
                                {['approvedClass', 'groupName'].map((fieldName) => (
                                    <Grid item xs={12} sm={fieldName === 'approvedClass' ? 5 : 4} key={fieldName} className='customGridClass'>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            render={({ field: { onChange, value, ...fieldProps } }) => (
                                                <Autocomplete
                                                    freeSolo
                                                    options={fieldName === 'approvedClass' ? classnames : groupName}
                                                    fullWidth
                                                    value={value}
                                                    onInputChange={(event, newInputValue) => {
                                                        onChange(newInputValue);
                                                        setValue('eduSecretarySign', '');
                                                        setEduSecSgnPreview('');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            label={t(fieldName === 'approvedClass' ? 'approvedClass' : 'group')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { borderRadius: '0px' },
                                                                disableUnderline: true
                                                            }}
                                                            variant='filled'
                                                        />
                                                    )}
                                                    {...fieldProps}
                                                />
                                            )}
                                        />
                                    </Grid>
                                ))}

                                {/** Conditional Rendering for Signature Preview or Input Field */}
                                <Grid item xs={12} sm={3} className='customGridClass'>
                                    {catchID && eduSecSgnPreview ? (
                                        <Button component="label" sx={{ padding: '0px' }}>
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={eduSecSgnPreview}
                                                alt="signature preview"
                                            />
                                        </Button>
                                    ) : (
                                        <Controller
                                            name="eduSecretarySign"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    size="small"
                                                    type="password"
                                                    label={t('eduSecretarySign')}
                                                    InputProps={{
                                                        style: { borderRadius: '0px' },
                                                        disableUnderline: true
                                                    }}
                                                    variant='filled'
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} mt={3} sx={{ padding: '8px', textAlign: 'center' }}>
                                <Chip sx={{
                                    fontSize: '1rem',
                                }} label={t('principalApproval')} />
                            </Grid>
                            <Grid container>
                                {/* Field Rows */}
                                {fields.map((field, index) => (
                                    <Grid item xs={4} key={`grid3-${index}`} className='customGridClass'>
                                        <CustomTextField
                                            name={field.name}
                                            label={field.label}
                                            control={control}
                                            setValue={setValue}
                                            t={t}
                                            additionalStyles={field.additionalStyles}
                                        />
                                    </Grid>
                                ))}

                                {/* Approval Autocomplete Row */}
                                <Grid item xs={12} sm={8} className='customGridClass'>
                                    <Controller
                                        name="approval"
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Autocomplete
                                                freeSolo
                                                options={approvals}
                                                fullWidth
                                                value={value}
                                                onInputChange={(event, newInputValue) => {
                                                    onChange(newInputValue);
                                                    setValue('principalSign', '');
                                                    setPrincipalSignPreview('')
                                                }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('approval')}
                                                        size='small'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            style: {
                                                                borderRadius: '0px',
                                                            }
                                                        }}
                                                        variant='filled'
                                                    />
                                                )}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* Principal Sign or Preview Row */}
                                <Grid item xs={12} sm={4} className='customGridClass'>
                                    {catchID && principalSingPreview ? (
                                        <Button component="label" sx={{ padding: '0px' }}>
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={principalSingPreview}
                                                alt="sign preview"
                                            />
                                        </Button>
                                    ) : (
                                        <Controller
                                            name="principalSign"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    size="small"
                                                    type="password"
                                                    fullWidth
                                                    label={t('principalSign')}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            borderRadius: '0px'
                                                        }
                                                    }}
                                                    variant='filled'
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 0.5, sm: 1 }} mb={1} mt={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchID ?
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    // loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>

                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Container>
        </Box >
    );
}
