// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

// MUI components and icons
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Autocomplete,
    InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

// Custom components and styles
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions
import { allStaff, newGivenFurniture } from '../../actions/adminisActions';
import { allClass } from '../../actions/eduActions';

// Utilities
import { MetaData } from '../utils/metaData';
import { nanoid } from 'nanoid';

export default function GIVINGFURNITURE({ closeDialog, id, selectedItem }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [catchID, setCatchID] = useState(id);
    const formRef = useRef(null);

    // getting the value from redux according to the history info for using by default
    const { loading } = useSelector(state => state.newFurniture);
    const { user } = useSelector(state => state.auth);
    const { classes } = useSelector(state => state.allClass);
    const { staffs } = useSelector(state => state.allStaff);
    // First, ensure departments array is defined and includes student.department if necessary
    const receivers = staffs?.map(staff => staff.name);
    let updatedStaffs = receivers ? [...receivers] : [];
    const departments = classes?.map(depart => depart.department);
    let updatedDepartments = departments ? [...departments] : [];

    // Transform selectedItem into defaultValues
    const defaultValues = selectedItem.reduce((acc, item, index) => {
        acc[`id${index}`] = item.id || '';
        acc[`totalGiven${index}`] = parseInt(item.totalGiven.match(/\d+/)[0], 10) || ''; // clear other text except the number
        acc[`remain${index}`] = parseInt(item.remains.match(/\d+/)[0], 10) || '';
        acc[`givingAmount${index}`] = '';
        return acc;
    }, {});

    const finalDefaultValues = {
        ...defaultValues,
        receiverDepartment: '',
        receiverResponsible: ''
    };

    const { handleSubmit, control } = useForm({ defaultValues: finalDefaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allClass(code))
        dispatch(allStaff(code))
    }, [dispatch, code])

    // handle clear
    const handleClear = () => {
        // setRows(updatedRows);
        setCatchID('') // check it
    };

    // handle submit
    const onSubmit = (data) => {
        // Function to get the length of keys with a specific prefix
        const getTotalGivingCount = (data, prefix) => {
            return Object.keys(data).filter(key => key.startsWith(prefix)).length;
        };
        const totalGivingItems = getTotalGivingCount(data, 'furnitureName');

        let items = [];
        for (let i = 0; i < totalGivingItems; i++) {
            const idKey = `id${i}`;
            const totalGivenKey = `totalGiven${i}`;
            const remainKey = `remain${i}`;
            const amountKey = `givingAmount${i}`;
            const furnitureNameKey = `furnitureName${i}`;

            const given = parseInt(data[totalGivenKey]) || 0;
            const remain = data[remainKey]
            const giving = parseInt(data[amountKey])
            if (giving > remain) {
                const maxNotPossibleMessage = t('maxNotPossible', { remain: remain, furniture: data[furnitureNameKey] });
                return enqueueSnackbar(maxNotPossibleMessage, { variant: 'error' });
            } else if (giving && data[furnitureNameKey]) {
                items.push([`${nanoid(10)}-${data[idKey]}`, data[furnitureNameKey], giving, given]);
                id++;
            }
        }

        dispatch(newGivenFurniture({ data: items, receiverDepartment: data.receiverDepartment, receiverResponsible: data.receiverResponsible, responsible: user.type }, code));

    };

    return (
        <Container component="main" maxWidth="md" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'GIVEN FURNITURE FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Box sx={{
                marginTop: '1.5rem'
            }}>
                <RECEIPTHEADING text={t('givingFurnitureForm')} department={t('adminis')} />
                <CssBaseline />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2}>
                    <Grid container
                        sx={{
                            overflow: 'auto',
                            position: 'relative',
                            '&::-webkit-scrollbar-track': {
                                marginTop: '10px'
                            }
                        }}
                    >
                        <TableContainer>
                            <Table aria-label="custom table" sx={{ border: '1px solid #ecc' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '4%', padding: '5px' }}>
                                            {t('serialNo1')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '18%', padding: '5px' }}>
                                            {t('furnitureName')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '10%', padding: '5px' }}>
                                            {t('remain')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ width: '10%', fontSize: '1rem', padding: '5px' }}>
                                            {t('givingAmount')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {selectedItem.map((lbl, index) => (
                                        <TableRow key={`item-${index}`}>
                                            <TableCell sx={{ display: 'none' }}>
                                                <TextField
                                                    name={`id${index + 1}`}
                                                    type="number"
                                                />
                                                <TextField
                                                    name={`totalGiven${index + 1}`}
                                                    type="number"
                                                />
                                            </TableCell>


                                            <TableCell align="center" sx={{ fontSize: '1rem', padding: '0px' }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`furnitureName${index}`}
                                                    control={control}
                                                    defaultValue={lbl?.name || ''}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: { '& input': { textAlign: 'left' } },
                                                                disableUnderline: true,
                                                                readOnly: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`remain${index}`}
                                                    control={control}
                                                    defaultValue={lbl?.remains || ''}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            type="number"
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{t('the')}</InputAdornment>,
                                                                sx: { '& input': { textAlign: 'center' } },
                                                                disableUnderline: true,
                                                                readOnly: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '5px' }}>
                                                <Controller
                                                    name={`givingAmount${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type="number"
                                                            required
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{t('the')}</InputAdornment>,
                                                                sx: { alignItems: 'center', '& input': { textAlign: 'center' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow key={`receiver-row`}>
                                        {['receiverDepartment', 'receiverResponsible'].map((lbl, index) => (
                                            <TableCell key={`receiver-cell-${index}`} colSpan={2} sx={{ fontSize: '1rem', padding: '0px' }}>
                                                <Controller
                                                    name={lbl}
                                                    control={control}
                                                    render={({ field: { onChange, value = '', ...field } }) => (
                                                        <Autocomplete
                                                            key={`receiver-autocomplete-${index}`}
                                                            freeSolo
                                                            options={lbl === 'receiverDepartment' ? updatedDepartments : updatedStaffs}
                                                            value={value}
                                                            onInputChange={(event, newInputValue) => {
                                                                onChange(newInputValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="filled"
                                                                    size="small"
                                                                    label={t(lbl)}
                                                                    required
                                                                    sx={lbl === 'receiverDepartment' ? { borderRight: '0.5px solid #ecc' } : {}}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        disableUnderline: true
                                                                    }}
                                                                />
                                                            )}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item container columnSpacing='4' marginBottom={2} justifyContent='right'>
                        {catchID ?
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Container >
    );
}