// React and Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import {
    Button, CssBaseline, TextField, Grid, Box, Container,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    InputAdornment, Autocomplete
} from '@mui/material';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

// Custom Components & Utilities
import { RECEIPTHEADING } from '../../layout/MiniComponents';
import { MetaData } from '../../utils/metaData';
import { CustomCrossButton } from '../../styles/style';

// Redux Actions
import { newProduct, updateMatchedProducts, updateProduct } from '../../../actions/othersActions';

// Constants
import { CLEAR_ERRORS } from '../../../constants/adminisConstants';
import { units } from '../../../constants/commonContstants';

// Other Utilities
import { nanoid } from 'nanoid';
import useAuth from '../../hooks/UseAuth';
import { LoadingButton } from '@mui/lab';

export default function NEWPRODUCTFORM({ closeDialog, selectedItem }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchItems, setCatchItems] = useState(selectedItem);
    const { code, type } = useAuth();
    const [unit, setUnit] = useState({
        unit0: '',
        unit1: ''
    });
    const formRef = useRef(null);

    // getting the value from redux according to the history info for using by default
    const { products } = useSelector(state => state.allProduct);

    const { loading, error } = useSelector(state => state.newProduct);

    // Transform selectedItem into defaultValues
    const updatingDefaultValues = selectedItem.reduce((acc, item, index) => {
        acc[`id${index}`] = item.id || '';
        acc[`productName${index}`] = item.productName || '';
        acc[`unit${index}`] = item.totalHas.replace(/\d+/g, '').trim() || ''; // Extracting only the text part (non-digit characters)
        acc[`amount${index}`] = parseInt(item.totalHas) || '';
        acc[`buyingPrice${index}`] = parseFloat(item.buyingPrice) || '';
        acc[`sellingPrice${index}`] = parseFloat(item.sellingPrice) || '';
        return acc;
    }, {});

    const intialDefaultValues = {
        productName0: '',
        productName1: '',
        productName2: '',
        productName3: '',
        productName4: '',
        productName5: '',
        productName6: '',
        productName7: '',
        productName8: '',
        productName9: '',
        amount0: '',
        amount1: '',
        amount2: '',
        amount3: '',
        amount4: '',
        amount5: '',
        amount6: '',
        amount7: '',
        amount8: '',
        amount9: '',
        buyingPrice0: '',
        buyingPrice1: '',
        buyingPrice2: '',
        buyingPrice3: '',
        buyingPrice4: '',
        buyingPrice5: '',
        buyingPrice6: '',
        buyingPrice7: '',
        buyingPrice8: '',
        buyingPrice9: '',
        sellingPrice0: '',
        sellingPrice1: '',
        sellingPrice2: '',
        sellingPrice3: '',
        sellingPrice4: '',
        sellingPrice5: '',
        sellingPrice6: '',
        sellingPrice7: '',
        sellingPrice8: '',
        sellingPrice9: '',
    };

    const defaultValues = catchItems.length === 0 ? intialDefaultValues : updatingDefaultValues;

    const { handleSubmit, control, reset } = useForm({ defaultValues });

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('notWorked'), { variant: 'error' });
            dispatch({ type: CLEAR_ERRORS })
        }
    }, [error, t, dispatch, enqueueSnackbar])


    // handle clear
    const handleClear = () => {
        reset('');
        setCatchItems('')
    };

    // handle submit
    const onSubmit = (data) => {
        // Determine the maximum index dynamically if needed
        let items = [];
        let updateItems = [];
        if (catchItems.length === 0) { // that means this is for inserting
            const maxIndex = 10;
            for (let i = 0; i < maxIndex; i++) {
                const amountKey = `amount${i}`;
                const productNameKey = `productName${i}`;
                const unitKey = `unit${i}`;
                const buyingPriceKey = `buyingPrice${i}`;
                const sellingPriceKey = `sellingPrice${i}`;

                // Find duplicate
                const duplicate = products?.find(product => product.product_name === data[productNameKey]);

                if (data[productNameKey] && data[amountKey] && data[buyingPriceKey] && data[sellingPriceKey] && !duplicate) {
                    items.push([nanoid(10), data[productNameKey], data[unitKey], data[amountKey], data[buyingPriceKey], data[sellingPriceKey]]);
                } else if (duplicate) {
                    updateItems.push([data[productNameKey], data[unitKey], data[amountKey], data[buyingPriceKey], data[sellingPriceKey], duplicate.id])
                }
            }
            if (items.length > 0) {
                dispatch(newProduct({ data: items, responsible: type }, code));
                handleClear();
            }
        } else {
            // controling update functionality
            const maxIndex = catchItems.length;
            for (let i = 0; i < maxIndex; i++) {
                const idKey = `id${i}`;
                const productNameKey = `productName${i}`;
                const unitKey = `unit${i}`;
                const amountKey = `amount${i}`;
                const buyingPriceKey = `buyingPrice${i}`;
                const sellingPriceKey = `sellingPrice${i}`;

                if (data[productNameKey] && data[unitKey] && data[amountKey] && data[buyingPriceKey] && data[sellingPriceKey] && parseInt(catchItems[0].sold) <= parseInt(data[amountKey])) {
                    items.push([data[productNameKey], data[unitKey], data[amountKey], data[buyingPriceKey], data[sellingPriceKey], data[idKey]]);
                } else {
                    const minNotPossible = t('minNotPossible', { furniture: data[productNameKey] });
                    enqueueSnackbar(minNotPossible, { variant: 'error' });
                }
            }
            dispatch(updateProduct({ data: items }, code));
        }

        // update amount of matching furnitures
        if (updateItems.length > 0) {
            dispatch(updateMatchedProducts({ data: updateItems }));
            enqueueSnackbar(t('matchedFurniture'), { variant: 'success' });
            handleClear();
        };
    };

    const handleUnitChange = (unitData) => {
        setUnit(prevState => ({
            ...prevState,
            [`unit${unitData.index}`]: unitData.unitValue,  // Use computed property name
        }));
    };

    return (
        <Container component="main" maxWidth="md" sx={{
            background: 'white',
            borderRadius: '10px'
        }}>
            <MetaData title={'NEW PRODUCT FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={selectedItem.length > 0 ? t('updateProduct') : t('addNewProduct')} department={t('buyAndSell')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2}>
                    <Grid container
                        sx={{
                            overflow: 'auto',
                            position: 'relative',
                            '&::-webkit-scrollbar-track': {
                                marginTop: '10px',
                            },
                        }}
                    >

                        <TableContainer>
                            <Table aria-label="custom table" sx={{ border: '1px solid #ecc' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '4%', padding: '5px' }}>
                                            {t('serialNo1')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '18%', padding: '5px' }}>
                                            {t('productName')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '10%', padding: '5px' }}>
                                            {t('unit')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                            {t('amount')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                            {t('buyingPrice')} ({t('unit')})
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                            {t('sellingPrice')} ({t('unit')})
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Array.from({ length: selectedItem.length !== 0 ? selectedItem.length : 10 }, (_, index) =>
                                        <TableRow key={`item-${index}`}>
                                            {selectedItem.length !== 0 ?
                                                <>
                                                    <TableCell sx={{ display: 'none' }}>
                                                        <TextField
                                                            name={`id${index}`}
                                                            type="number"
                                                        />
                                                    </TableCell>
                                                </>
                                                : null}
                                            <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '0px' }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`productName${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: { '& input': { textAlign: 'left' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`unit${index}`}
                                                    control={control}
                                                    render={({ field: { onChange, value = '', ...field } }) => (
                                                        <Autocomplete
                                                            key={`unit-${index}`}
                                                            freeSolo
                                                            options={units}
                                                            value={value}
                                                            onInputChange={(event, newInputValue) => {
                                                                onChange(newInputValue);
                                                                handleUnitChange({
                                                                    index: index,
                                                                    unitValue: newInputValue
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    size="small"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        disableUnderline: true
                                                                    }}
                                                                />
                                                            )}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`amount${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            // disabled={catchItems && index !== 0 && index !== 1 ? true : false}
                                                            // required={index === 0 || index === 1 ? true : false}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{unit[`unit${index}`]}</InputAdornment>,
                                                                sx: { '& input': { textAlign: 'center' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`buyingPrice${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{t('moneySymbol')}</InputAdornment>,
                                                                sx: { '& input': { textAlign: 'center' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`sellingPrice${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{t('moneySymbol')}</InputAdornment>,
                                                                sx: { '& input': { textAlign: 'center' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item container columnSpacing='4' marginBottom={2} justifyContent='right'>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchItems.length !== 0 ?
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}