import React from 'react'
import { useSelector } from 'react-redux';
import RESULTMANAGEMENT from '../education/ResultManagement';
import { Box, Typography } from '@mui/material';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import WebsiteAppBar from './AppBar';
import { MetaData } from '../utils/metaData';
import { useTranslation } from 'react-i18next';

export const ResultShowing = () => {
    const { t } = useTranslation();
    const { allSettings } = useSelector(state => state.settings);
    const { loadingResult } = useSelector(state => state.result);
    return (
        <div>
            <MetaData title={'RESULT'} />
            <Box sx={{ alignItems: 'center' }} padding={1}>
                <RECEIPTHEADING visitor={true} />
            </Box>
            <WebsiteAppBar />
            <Box pt={2} textAlign={'center'}>
                {!loadingResult && allSettings.result ? <RESULTMANAGEMENT visitor={true} /> :
                    <Typography variant="h6" color="textSecondary">
                        {t('sorryNotFound')}
                    </Typography>}
            </Box>
        </div>
    )
}
