// React and related hooks
import React, { useState, useEffect, useRef, useMemo } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import {
    allRegardings,
    approvedBooks,
    newReceipt,
    updateReceipt
} from '../../actions/accountActions';
import { allDonors, newDonation } from '../../actions/donorActions';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { nanoid } from 'nanoid';

// MUI components and icons
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    MenuItem,
    Divider,
    Card,
    CardMedia,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and utilities
import { RECEIPTHEADING, GETCODE } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { numberToBanglaWords } from '../utils/converter';
import { getFundTypes } from '../../constants/accountConstants';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';
import { Controller, useForm } from 'react-hook-form';
import { getSettings, sendSMS } from '../../actions/othersActions';
import { containsNonLanguageLetter } from '../utils/commonUtils';
import { SEND_SMS_RESET } from '../../constants/othersConstants';

export default function RECEIPTFORM({ closeDialog, history, receiptDepartment, text, onSave, receiptInfo }) {
    const { t } = useTranslation();
    const fundTypes = getFundTypes(t);
    const dispatch = useDispatch();
    const { code, email, type, sign_url } = useAuth();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(history);
    const formRef = useRef(null);
    const [rcvrSgnPreview, setrcvrSgnPreview] = useState('')
    const [principalSignPreview, setPrincipalSignPreview] = useState('')
    const [totalCutted, setTotalCutted] = useState('')
    const [receiptType, setReceiptType] = useState('')

    // Redux selectors to get the required state
    const { receipts } = useSelector(state => state.allReceipt);
    const { users } = useSelector(state => state.allUsers);
    const { loading, isSaved } = useSelector(state => state.newReceipt);
    const { regardings } = useSelector(state => state.allRegarding);
    const { approved_books } = useSelector(state => state.approvedBooks);
    const { donors } = useSelector(state => state.allDonors);
    const { loadingDonation } = useSelector(state => state.donation);
    const { allSettings } = useSelector(state => state.settings);

    // useRef to handle first render
    const isFirstRender = useRef(true);
    // Find receipt according to the bookNo and receiptTill
    const bookNo = history?.bookNo || '';
    const receiptTill = history?.receiptTill || '';
    const receipt = receipts.find(receipt => receipt.book_no === bookNo && receipt.receipt_no_till === receiptTill && receipt.code === code);
    // Fetch initial data on component mount
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            dispatch(allRegardings(code));
            dispatch(getSettings(code));
            dispatch(allDonors(code));
            dispatch(approvedBooks({ department: receiptDepartment }, code));

            if (sign_url) {
                setrcvrSgnPreview(sign_url)
            }

            if (receipt?.type) {
                setReceiptType(receipt.type)
            } else {
                setReceiptType('autoReceipt')
            }
        }
    }, [dispatch, sign_url, code, email, receipt?.type, receiptDepartment]);

    // Initialize form with default values
    const defaultValues = {
        memberNo: '',
        donationAmount: '',
        bookNo: receipt?.book_no || '',
        receiptFrom: receipt?.receipt_no_from || '',
        receiptTill: receipt?.receipt_no_till || '',
        donorName: receipt?.name || '',
        address: receipt?.address || '',
        fund: receipt?.fund || '',
        regarding: receipt?.regarding || '',
        amount: receipt?.amount || '',
        inWords: receipt?.in_words || '',
        mobile: ''
    };
    const { handleSubmit, control, setValue, getValues, watch } = useForm({ defaultValues });

    // handle clear
    const handleClear = () => {
        setValue('donorName', '')
        setValue('address', '')
        setValue('regarding', '')
        setValue('amount', '')
        setValue('inWords', '')
        setValue('fund', '')
        setPrincipalSignPreview('')
        setCatchID('')
    };

    // for handling boarding receipts
    useEffect(() => {
        if (receiptInfo) {
            console.log(receiptInfo);

            setValue('fund', 'generalFund')
            // setValue('donorName', `${receiptInfo?.userInfo.name}, ${t('admitNo')}- ${receiptInfo?.userInfo.idNo}`)
            setValue('donorName', receiptInfo?.userInfo.name)
            setValue('address', receiptInfo?.userInfo.address)
            setValue('amount', receiptInfo?.receiptData.totalAmount)
            setValue('mobile', receiptInfo?.userInfo.mobile.split(',')[0])
            setValue('inWords', `${numberToBanglaWords(receiptInfo?.receiptData.totalAmount)} ${t('money')} ${t('just')}`)
        }

        if (isSaved) {
            // clear the receipt form
            setValue('donorName', '')
            setValue('address', '')
            setValue('regarding', '')
            setValue('amount', '')
            setValue('inWords', '')

        }
    }, [receiptInfo, setValue, t, dispatch, isSaved, enqueueSnackbar, code, receipt?.regarding]);

    // for handling automatically receipt book no
    useEffect(() => {
        if (receiptType === 'autoReceipt' && !catchID) {
            // Process approved receipts to find missing receipt numbers
            const storeReceiptNo = receipts
                .filter(entry => entry.book_no === approved_books[0]?.book_no)
                .map(entry => entry.receipt_no_till);

            const min = Math.min(...storeReceiptNo);
            const max = Math.max(...storeReceiptNo);

            const missingValues = Array.from({ length: max - min + 1 }, (_, i) => min + i)
                .filter(i => !storeReceiptNo.includes(i));

            // Determine the possible receipt number
            const possibleReceiptNo = missingValues.length > 0 ?
                missingValues[0]
                : (storeReceiptNo.length > 0 ? Math.max(...storeReceiptNo) + 1
                    : approved_books[0] ? 1 : ''); // at first receipt no till will 1

            // Update form fields with the calculated values
            setValue('bookNo', approved_books[0]?.book_no);
            setValue('receiptFrom', possibleReceiptNo);
            setValue('receiptTill', possibleReceiptNo);

            setTotalCutted(storeReceiptNo.length)
            setReceiptType('autoReceipt');
        }
    }, [dispatch, approved_books, receipts, setValue, receiptType, code, catchID]);

    let lbls = [];
    if (receiptDepartment !== 'donation') {
        lbls = ['bookNo', 'receiptFrom', 'receiptTill', 'donorName', 'address', 'fund', 'regarding', 'amount', 'mobile', 'inWords'];
    } else {
        lbls = ['memberNo', 'bookNo', 'receiptFrom', 'receiptTill', 'donorName', 'address', 'fund', 'regarding', 'donationAmount', 'amount', 'mobile', 'inWords'];
    }

    // handle submit
    const onSubmit = (data) => {
        const finished = getValues('receiptTill') === approved_books[0]?.pages ? true : false; // check approved books is finished or not
        const formattedDate = new Date().toISOString().split('T')[0];
        if (catchID) {
            if (principalSignPreview) {
                dispatch(updateReceipt({ ...data, principalSign: principalSignPreview }, code))
            } else {
                setGetCodeOpen(true);
            }
        } else {
            // checking duplicate
            const duplicate = receipts?.find(r => r.book_no === parseInt(data.bookNo) && r.receipt_no_till === parseInt(data.receiptTill));
            if (duplicate) {
                return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
            }

            // checking approved receipts books when user try to cut menual receipt
            const approved = approved_books?.find(r => r.book_no === parseInt(data.bookNo));
            if (approved && receiptType === 'menualReceipt') {
                return enqueueSnackbar(t('sorryThisIsApproved'), { variant: 'error' });
            }

            dispatch(newReceipt(code, {
                ...data,
                department: receiptDepartment,
                receiverSign: sign_url,
                date: formattedDate,
                type: receiptType,
                principalSign: principalSignPreview,
                totalCutted: totalCutted,
                finished: finished
            }));
        }

        // for handling boarding receipts
        if (onSave) {
            const cuttedReceiptData = {
                ...data,
                department: 'accounts',
                receiverSign: sign_url,
                date: formattedDate,
                type: t(receiptType),
                principalSign: principalSignPreview,
            }

            onSave(cuttedReceiptData);
        }

        // for handling donations
        if (receiptDepartment === 'donation') {
            const donor = donors.find(d => d.member_no === parseInt(data.memberNo));
            const final = {
                id: nanoid(10),
                ...data,
                fatherName: donor.father_name,
                mobile: donor.mobile,
                responsible: type
            };
            dispatch(newDonation(final, code))
        }

        // sending auto sms
        const sendReceiptSMS = (messageTemplate) => {
            const fullSMS = `${messageTemplate}\n${t('amount')}: ${data.amount}\n${t('regarding')}: ${data.regarding}`;
            const batchSize = containsNonLanguageLetter(fullSMS) ? 60 : 150;
            const messageCount = Math.ceil(fullSMS.length / batchSize) || 1;

            dispatch(sendSMS({ mobile: data.mobile, message: fullSMS, messageCount: messageCount }, code));
            dispatch({ type: SEND_SMS_RESET })
        };

        // const messageTemplate = receiptDepartment !== 'donation'
        //     ? allSettings.receipt_sms
        //     : allSettings.donation_sms || null;

        if (allSettings.receipt_sms) {
            sendReceiptSMS(allSettings.receipt_sms);
        }
    };

    // for controling getcode window
    const [password, setPassword] = useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);
    const handleOpenGetCode = () => {
        setGetCodeOpen(true);
    };

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    // handle principal sign by the code
    const handleUserSign = async (e) => {
        const userType = users.find(user => user.password === password);
        e.preventDefault();

        if (userType && userType.type === 'principal') {
            setPrincipalSignPreview(userType.sign_url)
            setGetCodeOpen(false);
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    // State for selected user type and change regarding according to fund dynamically
    // Memoize mci_regardings and mgi_regardings
    const mci_regardings = useMemo(() => regardings?.mci_regardings ?? [], [regardings]);
    const mgi_regardings = useMemo(() => regardings?.mgi_regardings ?? [], [regardings]);

    // Function to parse regarding JSON strings into arrays
    const parseRegardings = (regarding) => {
        try {
            return JSON.parse(regarding); // Parse the JSON string into an array
        } catch (error) {
            console.error('Error parsing regarding:', error);
            return []; // Return an empty array in case of an error
        }
    };

    // Set receipt regardings based on parsed regarding values
    const [receiptRegardings, setReceiptRegardings] = useState([]);

    // Effect to update receiptRegardings based on fundType
    const fundType = watch('fund');
    useEffect(() => {
        if (fundType === 'charityFund') {
            setReceiptRegardings(
                mci_regardings.flatMap((option) =>
                    parseRegardings(option.regarding).map((value) => ({
                        value: value,
                        label: value,
                    }))
                )
            );
        } else if (fundType === 'generalFund') {
            setReceiptRegardings(
                mgi_regardings.flatMap((option) =>
                    parseRegardings(option.regarding).map((value) => ({
                        value: value,
                        label: value,
                    }))
                )
            );
        }
    }, [mci_regardings, mgi_regardings, t, fundType]);

    const handleFundChange = (e) => {
        const changedFund = e.target.value;
        setValue('regarding', ''); // Reset only the 'regarding' field
        setValue('fund', changedFund); // Update the form state
    };

    const handleReceiptType = (type) => {
        if (type === 'autoReceipt') {
            setReceiptType('autoReceipt')
            setValue('bookNo', approved_books[0]?.book_no);
            setValue('receiptFrom', totalCutted + 1);
            setValue('receiptTill', totalCutted + 1);
        } else {
            setReceiptType('menualReceipt')
            setValue('bookNo', '');
            setValue('receiptFrom', '');
            setValue('receiptTill', '');
        }
    };

    // check donor
    const checkDonor = () => {
        const donor = donors.find(d => d.member_no === parseInt(getValues('memberNo')));
        console.log(donor);

        if (donor) {
            setValue('donorName', donor.donor_name)
            setValue('address', `${donor.village}, ${donor.sub_district}, ${donor.district}`)
            setValue('donationAmount', donor.donation_amount)
            setValue('mobile', donor.mobile.split(',')[0])
        } else {
            enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });
        }
    };

    const handleInWords = (value) => {
        setValue('inWords', `${numberToBanglaWords(value)} ${t('money')} ${t('just')}`)
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'RECEIPT FORM'} />
            <GETCODE
                headingText={t('principalCode')}
                handleSign={handleUserSign}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                password={password}
                setPassword={setPassword}
            />
            {
                receiptInfo?.type !== 'acceptMoney' ? (
                    <CustomCrossButton
                        onClick={closeDialog}
                        disableElevation
                        disableRipple
                        disableFocusRipple
                    >
                        <Close fontSize='small' />
                    </CustomCrossButton>
                ) : (
                    undefined
                )
            }

            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                mt={3}
            >
                <RECEIPTHEADING text={text} department={t(receiptDepartment)} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container spacing={1.2}
                        sx={{ mt: 0.5 }}
                    >
                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={receiptDepartment !== 'donation' ?
                                    lbl === 'bookNo' ? 4
                                        : lbl === 'receiptFrom' ? 4
                                            : lbl === 'receiptTill' ? 4
                                                : lbl === 'fund' ? 6
                                                    : lbl === 'regarding' ? 6
                                                        : lbl === 'amount' ? 6
                                                            : lbl === 'mobile' ? 6
                                                                : 12
                                    : lbl === 'memberNo' ? 3
                                        : lbl === 'bookNo' ? 3
                                            : lbl === 'receiptFrom' ? 3
                                                : lbl === 'receiptTill' ? 3
                                                    : lbl === 'fund' ? 6
                                                        : lbl === 'regarding' ? 6
                                                            : lbl === 'donationAmount' ? 6
                                                                : lbl === 'amount' ? 6
                                                                    : 12}
                                key={`grid-${index}`}
                            >
                                {lbl === 'fund' ? (
                                    <Controller
                                        key={`controller-${lbl}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                size="small"
                                                id={`id${index}`}
                                                select
                                                label={t('fund')}
                                                required
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleFundChange(e);
                                                }}
                                            >
                                                {fundTypes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                ) : lbl === 'regarding' ? (
                                    <Controller
                                        key={`controller-${lbl}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => {
                                            // Check if the label is missing in the receiptRegardings
                                            const isLabelPresent = receiptRegardings.some(option => option.label === field.value);

                                            // If not, add the label to the receiptRegardings array temporarily
                                            const updatedReceiptRegardings = isLabelPresent
                                                ? receiptRegardings
                                                : [...receiptRegardings, { value: field.value, label: field.value }];

                                            return (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    size="small"
                                                    id={`id${index}`}
                                                    select
                                                    label={t('regarding')}
                                                    required
                                                    fullWidth
                                                >
                                                    {updatedReceiptRegardings.map((option, index) => (
                                                        <MenuItem key={`key-${index}`} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            );
                                        }}
                                    />
                                ) : (
                                    <Controller
                                        key={`controller-${lbl}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                type={['memberNo', 'donationAmount', 'bookNo', 'receiptFrom', 'receiptTill', 'amount', 'mobile'].includes(lbl) ? 'number' : 'text'}
                                                size="small"
                                                id={`id${index}`}
                                                label={receiptDepartment !== 'donation' ? t(lbl) : lbl === 'amount' ? t('donationGivingAmount') : t(lbl)}
                                                disabled={catchID && ['bookNo', 'receiptFrom', 'receiptTill'].includes(lbl) ? true : undefined}
                                                autoFocus={index === 0}
                                                required={lbl !== 'mobile'}
                                                onChange={(event) => {
                                                    field.onChange(event);  // Ensure the field state is updated
                                                    if (lbl === 'amount' || lbl === 'amount') {
                                                        handleInWords(event.target.value);  // Only run for 'amount'
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: receiptType === 'autoReceipt' && ['bookNo', 'receiptFrom', 'receiptTill'].includes(lbl) ? true : undefined
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        ))}

                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {rcvrSgnPreview ?
                                    <Card elevation={0}>
                                        <CardMedia
                                            component="img"
                                            height="40"
                                            image={rcvrSgnPreview}
                                            alt="rcvrSgnPreview"
                                        />
                                    </Card>
                                    :
                                    <Button>
                                        {t('receiverSign')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} display='flex' justifyContent={'center'}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {principalSignPreview ?
                                    <Button component='label'>
                                        <Card elevation={0}
                                            onClick={handleOpenGetCode}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={principalSignPreview}
                                                alt="principalSignPreview"
                                            />
                                        </Card>
                                    </Button>
                                    :
                                    <Button
                                        onClick={handleOpenGetCode}
                                    >
                                        {t('principalSign')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container mt={1} mb={2}>
                        <Grid item xs={5}>
                            <FormControl component="fieldset">
                                <Controller
                                    name="receiptType"
                                    control={control}
                                    defaultValue={receiptType}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            row
                                            // sx={{ display: 'flex' }}
                                            value={receiptType}
                                            onChange={(event) => {
                                                field.onChange(event); // Ensure the field's onChange event is triggered
                                                handleReceiptType(event.target.value); // Custom handler
                                            }}

                                            sx={{
                                                display: 'flex',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 18,
                                                },
                                            }}
                                        >
                                            <FormControlLabel value="autoReceipt" control={<Radio />} label={t("autoReceipt")} />
                                            <FormControlLabel value="menualReceipt" control={<Radio />} label={t("menualReceipt")} />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={7}>
                            <Grid container columnSpacing={1} justifyContent={'right'}>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color='error'
                                        onClick={handleClear}
                                    >
                                        {t('clear')}
                                    </Button>
                                </Grid>
                                {receiptDepartment === 'donation' && (
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={checkDonor}
                                        >
                                            {t('check')}
                                        </Button>
                                    </Grid>
                                )}
                                {catchID ? (
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="success"
                                        >
                                            {t('update')}
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            color="success"
                                            loading={loading || loadingDonation}
                                            variant="contained"
                                        >
                                            <span>{t('save')}</span>
                                        </LoadingButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}