// React and related hooks
import React, { useState, useEffect, useRef } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, newReceiptBook } from '../../actions/accountActions';

// Third-party libraries
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    FormControl,
    Divider,
    Card,
    CardMedia,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

// Custom components, styles, and utilities
import { RECEIPTHEADING, GETCODE } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import { getDepartments } from '../../constants/accountConstants';
import useAuth from '../hooks/UseAuth';

export default function APPROVINGFORM({ closeDialog, history }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(history);
    const formRef = useRef(null);
    const [aproverSgnPreview, setAproverSgnPreview] = useState('')
    const [aproverSgnPreview1, setAproverSgnPreview1] = useState('')
    const departments = getDepartments(t)

    // getting the value from redux according to the history info for using by default
    const { users } = useSelector(state => state.allUsers);
    const { error, user } = useSelector(state => state.auth);
    const { loading } = useSelector(state => state.newApprovedBook);
    const { all_approved_books } = useSelector(state => state.allApprovedBooks);

    const defaultValues = {
        bookNo: '',
        receiptTill: '',
        ...Array.from({ length: 10 }, (_, i) => ({
            [`bookNo${i}`]: '',
            [`department${i}`]: '',
            [`receiptFrom${i}`]: '',
            [`receiptTill${i}`]: ''
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
    };

    const { handleSubmit, control, reset, watch, setValue } = useForm({ defaultValues });

    const bookNumbers = watch(['bookNo0', 'bookNo1', 'bookNo2', 'bookNo3', 'bookNo4', 'bookNo5', 'bookNo6', 'bookNo7', 'bookNo8', 'bookNo9']);

    useEffect(() => {
        // for setting receipt no from when user change the book no
        bookNumbers.forEach((bookNo, index) => {
            setValue(`receiptFrom${index}`, bookNo ? '1' : '');
        });
    }, [bookNumbers, setValue, departments]);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (user && user.sign_url) {
            setAproverSgnPreview(user.sign_url)
        }

        if (error) {
            dispatch(clearErrors());
        }

    }, [dispatch, error, user, t, closeDialog])

    // handle clear
    const handleClear = () => {
        reset();
        setAproverSgnPreview1('')
        setCatchID('')
    };

    // handle submit
    const onSubmit = (data) => {
        // Function to filter out empty values and duplicates by bookNo
        const filterEmptyAndDuplicates = (data) => {
            const seenBookNos = new Set(); // Track seen bookNos
            const result = {};

            Object.entries(data).forEach(([key, value]) => {
                // First filter out empty values
                if (value !== '') {
                    // Check if it's a bookNo entry
                    if (key.startsWith('bookNo')) {
                        // If the bookNo hasn't been seen yet, add it to the result
                        if (!seenBookNos.has(value)) {
                            seenBookNos.add(value); // Add to seen set
                            result[key] = value; // Add to result
                        }
                    } else {
                        // If it's not a bookNo, just add it to the result
                        result[key] = value;
                    }
                }
            });

            return result;
        };

        // Filter out empty values and duplicates from the data
        const cleanedData = filterEmptyAndDuplicates(data);

        const finalData = {
            id: nanoid(10),
            ...cleanedData,
            approver_sign: user ? user.sign_url : '',
            approver1_sign: aproverSgnPreview1 ? aproverSgnPreview1 : '',
            condition: 'unfinished'
        }

        // checking duplicate
        const bookNumbers = Object.keys(data).filter(key => key.startsWith('bookNo')).map(key => data[key]);
        for (let bookNo of bookNumbers) {
            const duplicate = all_approved_books?.find(r => r.book_no === parseInt(bookNo));
            if (duplicate) {
                return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
            }
        }

        if (Object.keys(cleanedData).length === 0) {
            return enqueueSnackbar(t('requireText'), { variant: 'error' });
        } else {
            dispatch(newReceiptBook(finalData, code))
        }

    };

    // for controling getcode window
    const [password, setPassword] = useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);
    const handleOpenGetCode = () => {
        setGetCodeOpen(true);
    };

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    // handle aprover sign by the code
    const userType = users.find(user => user.password === password);
    const handleUserSign = async (e) => {
        e.preventDefault();
        if (userType && userType.user_type === 'councilMember') {
            setAproverSgnPreview1(userType.sign_url)
            setGetCodeOpen(false);
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    const renderTableCell = (name, type = 'text', options = null, align = 'center', disabled = false) => (
        <TableCell align={align} sx={{ padding: '5px' }}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        size="small"
                        variant="standard"
                        type={type}
                        select={!!options}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: disabled,
                            sx: type === 'number' ? { '& input': { textAlign: 'center' } } : {},
                        }}
                    >
                        {options && options.map(({ value, label }, idx) => (
                            <MenuItem key={idx} value={value} sx={{ textAlign: 'left' }}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            />
        </TableCell>
    );

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'APROVING FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <GETCODE
                headingText={t('councilCode')}
                handleSign={handleUserSign}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                password={password}
                setPassword={setPassword}
            />
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('approvedReceiptBooks')} department={t('accounts')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container spacing={1}
                        sx={{ mt: 2 }}
                    >
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['bookNo', 'receiptFrom', 'receiptTill', 'department'].map((header, idx) => (
                                            <TableCell
                                                key={header}
                                                align="center"
                                                sx={{
                                                    fontSize: '1rem',
                                                    width: ['20%', '20%', '20%', '30%'][idx],
                                                    padding: '5px',
                                                }}
                                            >
                                                {t(header)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {[...Array(10)].map((_, idx) => (
                                        <TableRow key={idx}>
                                            {renderTableCell(`bookNo${idx}`, 'number')}
                                            {renderTableCell(`receiptFrom${idx}`, 'number', null, 'center', true)}
                                            {renderTableCell(`receiptTill${idx}`, 'number')}
                                            {renderTableCell(`department${idx}`, 'text', departments, 'left', false)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {aproverSgnPreview ?
                                    <Button component='label'>
                                        <Card elevation={0}>
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={aproverSgnPreview}
                                                alt="Aprover sign"
                                            />
                                        </Card>
                                    </Button>
                                    :
                                    <Button>
                                        {t('councilApproved')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} display='flex' justifyContent={'center'}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {aproverSgnPreview1 ?
                                    <Button component='label'>
                                        <Card elevation={0}
                                            onClick={handleOpenGetCode}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={aproverSgnPreview1}
                                                alt="Aprover sign-2"
                                            />
                                        </Card>
                                    </Button>
                                    :
                                    <Button
                                        onClick={handleOpenGetCode}
                                    >
                                        {t('councilApproved')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} columnSpacing='4' mb={2} mt={1} justifyContent={'right'}>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchID ?
                            <Grid item>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                >
                                    {t('update')}
                                </Button>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>

                </Box>
            </Box>
        </Container >
    );
}