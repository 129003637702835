// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MUI components and icons
import {
    Grid,
    Box,
    IconButton,
    Typography,
    Paper,
    Chip
} from '@mui/material';

import Divider from '@mui/material/Divider';
import {
    School as SchoolIcon,
    GroupAdd as GroupAddIcon,
    Person as PersonIcon,
    Category as CategoryIcon,
    Class as ClassIcon,
    Work as WorkIcon,
    Paid as PaidIcon,
    LocalLibrary as LocalLibraryIcon,
    StickyNote2 as StickyNote2Icon
} from '@mui/icons-material';
import { convertToBengaliDigits } from '../utils/converter';
import { useTranslation } from 'react-i18next';
import PageViewsBarChart from './BarCharts';
import SessionsChart from './SessionChart';
import useAuth from '../hooks/UseAuth';
import { getDashboardInfo } from '../../actions/othersActions';
import { LIMITCROSSALERT } from '../layout/MiniComponents';

export default function Dashboard() {
    const { t } = useTranslation();
    const { code, numberOfStudents } = useAuth();
    const dispatch = useDispatch();
    const [limitCross, setLimitCross] = React.useState(null);

    const { informations } = useSelector(state => state.dashboardInfo);
    const totals = informations[0]?.totals || [];
    const results = informations[0]?.results || [];

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(getDashboardInfo(code))

        if (numberOfStudents < totals?.total_students) {
            setLimitCross(true);
        }
    }, [dispatch, code, numberOfStudents, totals?.total_students])

    const data = [
        { title: t('officiants'), value: totals?.officiants || 0, symbol: t('people'), icon: <WorkIcon color="primary" /> },
        { title: t('teacher'), value: totals?.teachers || 0, symbol: t('people'), icon: <SchoolIcon color="primary" /> },
        { title: t('worker'), value: totals?.workers || 0, symbol: t('people'), icon: <SchoolIcon color="primary" /> },
        { title: t('students'), value: totals?.total_students || 0, symbol: t('people'), icon: <SchoolIcon color="primary" /> },
        { title: `${t('new')} ${t('students')}`, value: totals?.new_students || 0, symbol: t('people'), icon: <GroupAddIcon color="primary" /> },
        { title: `${t('old')} ${t('students')}`, value: totals?.old_students || 0, symbol: t('people'), icon: <PersonIcon color="primary" /> },
        { title: t('department'), value: totals?.departments || 0, symbol: t('the'), icon: <CategoryIcon color="primary" /> },
        { title: t('class'), value: totals?.class_name || 0, symbol: t('the'), icon: <ClassIcon color="primary" /> },
        { title: `${t('income')} (${t('accounts')})`, value: totals?.account_income || 0, symbol: t('moneySymbol'), icon: <PaidIcon color="success" /> },
        { title: `${t('expense')} (${t('accounts')})`, value: totals?.account_expence || 0, symbol: t('moneySymbol'), icon: <PaidIcon color="error" /> },
        // { title: `${t('income')} (${t('boarding')})`, value: totals?.boarding_income || 0, symbol: t('moneySymbol'), icon: <PaidIcon color="success" /> },
        // { title: `${t('expense')} (${t('boarding')})`, value: totals?.boarding_expence || 0, symbol: t('moneySymbol'), icon: <PaidIcon color="error" /> },
        { title: t('donor'), value: totals?.donors || 0, symbol: t('people'), icon: <PaidIcon color="primary" /> },
        { title: `${t('library')} ${t('book')}`, value: totals?.books || 0, symbol: t('the'), icon: <SchoolIcon color="primary" /> },
    ];

    // Convert `results` object into an array and dynamically create `data2`
    const data2 = Object.values(results).map(item => ({
        class: item.class_name || '',
        students: item.total_students?.toString() || '',
        pass: item.pass?.toString() || '',
        fail: item.fail?.toString() || '',
        absent: item.absent?.toString() || ''
    }));

    const handleContact = () => {
        window.open('https://web.whatsapp.com', '_blank');
    }

    return (
        <Box>
            <Box sx={{ padding: 1 }} textAlign={'center'}>

                {
                    limitCross ?
                        <LIMITCROSSALERT handleOpen={limitCross} handleOk={handleContact} />
                        : null
                }

                <Chip variant='outlined' sx={{ fontSize: '1rem', marginBottom: '5px', boxShadow: '0px 0px 5px 0.5px #001a961c', }} label={t('totalCount')} />
                <Grid container spacing={1}>
                    {data.map((item, index) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                            <Paper
                                elevation={0}
                                sx={{
                                    padding: 1,
                                    textAlign: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: '0px 0px 5px 0.5px #001a961c',
                                    borderRadius: 2,
                                }}
                            >
                                <IconButton size='small' aria-label="delete" sx={{ background: 'rgba(244,247,254,255)' }}>
                                    {item.icon}
                                </IconButton>
                                <Box marginLeft={1}>
                                    <Typography variant='body2'>
                                        {item.title}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {convertToBengaliDigits(item.value)} {item.symbol}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ padding: 1 }} textAlign={'center'}>
                <Chip variant='outlined' sx={{ fontSize: '1rem', marginBottom: '5px', boxShadow: '0px 0px 5px 0.5px #001a961c', }} label={t('results')} />
                <Grid container spacing={1}>
                    {data2.length > 0 ?
                        data2.map((item, index) => (
                            <Grid item xs={6} sm={4} md={2} key={index}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        padding: 1,
                                        textAlign: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow: '0px 0px 5px 0.5px #001a961c',
                                        borderRadius: 2,
                                    }}
                                >
                                    <Box marginLeft={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <SchoolIcon color='primary' fontSize='small' sx={{ marginRight: 1 }} />
                                            <Typography variant="body1">
                                                {item.class}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: 1 }} />

                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                            <StickyNote2Icon color='success' fontSize='small' sx={{ marginRight: 1 }} />
                                            <Typography variant='body2'>
                                                {t('passResult')}: {convertToBengaliDigits(item.pass)} {t('people')}, {t('failResult')}: {convertToBengaliDigits(item.fail)} {t('people')}, {t('absentResult')}: {convertToBengaliDigits(item.absent)} {t('people')}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: 1 }} />

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocalLibraryIcon color='secondary' fontSize='small' sx={{ marginRight: 1 }} />
                                            <Typography variant='body2'>
                                                {t('students')}: {convertToBengaliDigits(item.students)} {t('people')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))
                        :
                        <Grid item xs={6} sm={4} md={2}>
                            <Paper
                                elevation={0}
                                sx={{
                                    padding: 1,
                                    textAlign: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: '0px 0px 5px 0.5px #001a961c',
                                    borderRadius: 2,
                                }}
                            >
                                <Box marginLeft={1}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <SchoolIcon color='primary' fontSize='small' sx={{ marginRight: 1 }} />
                                        <Typography variant="body1">
                                            {t('className')}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ marginBottom: 1 }} />

                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                        <StickyNote2Icon color='success' fontSize='small' sx={{ marginRight: 1 }} />
                                        <Typography variant='body2'>
                                            {t('passResult')}: {convertToBengaliDigits(0)} {t('people')}, {t('failResult')}: {convertToBengaliDigits(0)} {t('people')}, {t('absentResult')}: {convertToBengaliDigits(0)} {t('people')}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ marginBottom: 1 }} />

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LocalLibraryIcon color='secondary' fontSize='small' sx={{ marginRight: 1 }} />
                                        <Typography variant='body2'>
                                            {t('students')}: {convertToBengaliDigits(0)} {t('people')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>}
                </Grid>
            </Box>
            <Box sx={{ padding: 1, paddingBottom: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <SessionsChart />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PageViewsBarChart />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

