// React and related hooks
import React, { useState, useEffect } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { allRegardings, newRegarding } from '../../actions/accountActions';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { nanoid } from 'nanoid';

// MUI components and icons
import {
    TextField,
    Box,
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Fab
} from '@mui/material';
import {
    Add,
    Remove,
    DeleteOutlineOutlined
} from '@mui/icons-material';

// Custom components and styles
import { CustomSmallButton } from '../styles/style';
import { NEW_REGARDING_RESET } from '../../constants/accountConstants';
import useAuth from '../hooks/UseAuth';
import { MetaData } from '../utils/metaData';

const CHANGEREGARDING = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [textAreas, setTextAreas] = useState({ mci: [], mce: [], mgi: [], mge: [] });
    const { regardings } = useSelector(state => state.allRegarding);
    const { success } = useSelector(state => state.regarding);

    useEffect(() => {
        dispatch(allRegardings(code));
    }, [dispatch, code]);

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_REGARDING_RESET })
        }
    }, [dispatch, success, enqueueSnackbar, t]);

    useEffect(() => {
        if (regardings) {
            setTextAreas({
                mci: regardings.mci_regardings?.flatMap(item => {
                    return item.regarding ? JSON.parse(item.regarding) : [];
                }) || [],
                mce: regardings.mce_regardings?.flatMap(item => {
                    return item.regarding ? JSON.parse(item.regarding) : [];
                }) || [],
                mgi: regardings.mgi_regardings?.flatMap(item => {
                    return item.regarding ? JSON.parse(item.regarding) : [];
                }) || [],
                mge: regardings.mge_regardings?.flatMap(item => {
                    return item.regarding ? JSON.parse(item.regarding) : [];
                }) || [],
            });
        }
    }, [regardings]);

    const handleAddTextArea = (countType) => setTextAreas(prev => ({ ...prev, [countType]: [...prev[countType], ''] }));
    const handleRemoveTextArea = (countType, index) => setTextAreas(prev => ({ ...prev, [countType]: prev[countType].filter((_, i) => i !== index) }));
    const handleRemoveLastTextArea = (countType) => setTextAreas(prev => ({ ...prev, [countType]: prev[countType].slice(0, -1) }));
    const handleRemoveAllTextAreas = (countType) =>
        setTextAreas(prev => ({ ...prev, [countType]: [] }));

    const handleSubmit = (e, countType) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('id', nanoid());
        formData.append('code', code);
        formData.append('fund', countType);

        // Assuming 'textAreas[countType]' is an array of texts
        const regardings = textAreas[countType];

        // Remove duplicates using Set
        const uniqueRegardings = [...new Set(regardings)];

        // Convert the unique regardings array to a JSON string and append it as a single field
        formData.append('regardings', JSON.stringify(uniqueRegardings));

        // Dispatch the formData
        dispatch(newRegarding(formData, code));
    };

    const renderForm = (countType, label) => (
        <Box sx={{ boxShadow: '0px 0px 5px 0.5px #e0ecff', borderRadius: '5px' }} mt={2} paddingBottom={2}>
            <MetaData title={'CHANGE REGARDING'} />
            <Grid container p={1}>
                <Grid item xs={6} sm={2} order={{ sm: 1, xs: 2 }}>
                    <ButtonGroup variant="outlined">
                        <CustomSmallButton onClick={() => handleRemoveLastTextArea(countType)}><Remove /></CustomSmallButton>
                        <CustomSmallButton onClick={() => handleAddTextArea(countType)}><Add /></CustomSmallButton>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={8} order={{ sm: 2, xs: 1 }} sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t(label)} />
                </Grid>
                <Grid item xs={6} sm={2} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'right', margin: 'auto' }}>
                    <ButtonGroup variant="outlined" color='error'>
                        <CustomSmallButton onClick={() => handleRemoveAllTextAreas(countType)}><DeleteOutlineOutlined /></CustomSmallButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <form onSubmit={(e) => handleSubmit(e, countType)}>
                <Grid container alignItems={'center'} display={'flex'} justifyContent={'center'}>
                    {textAreas[countType].map((text, index) => (
                        <Grid item key={index} sx={{ position: 'relative', '&:hover .removeButton': { visibility: 'visible' } }}>
                            <Box sx={{ justifyContent: 'right' }}>
                                <Fab
                                    className="removeButton"
                                    sx={{ visibility: 'hidden', position: 'absolute', top: -15, right: 0 }}
                                    size="small"
                                    onClick={() => handleRemoveTextArea(countType, index)}
                                    color="error"
                                >
                                    <Remove sx={{ fontSize: '1rem' }} />
                                </Fab>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={text}
                                multiline
                                size='small'
                                onChange={(e) => {
                                    const newTextAreas = [...textAreas[countType]];
                                    newTextAreas[index] = e.target.value;
                                    setTextAreas(prev => ({ ...prev, [countType]: newTextAreas }));
                                }}

                                InputProps={{
                                    // disableUnderline: true,
                                    style: {
                                        borderRadius: '0px'
                                    }
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid item mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        disabled={type !== 'accountant' && type !== 'superUser'}
                        variant="contained"
                        type="submit"
                        color='success'
                        size='small'
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </form>
        </Box>
    );

    return (
        <Box className="shapeDesign">
            <>
                <Box>{renderForm('mci', 'mci')}</Box>
                <Box mt={4}>{renderForm('mce', 'mce')}</Box>
                <Box mt={4}>{renderForm('mgi', 'mgi')}</Box>
                <Box mt={4} sx={{ paddingBottom: 2 }}>{renderForm('mge', 'mge')}</Box>
            </>
        </Box>
    );
};

export default CHANGEREGARDING;
