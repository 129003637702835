import React from 'react';
import {
    AppBar, Toolbar, Grid, Box, MenuItem, IconButton, Button, Menu, Typography,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Drawer,
    Dialog
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Language from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/UseAuth';
import ADMISSIONFORM from '../education/AdmitForm';
import { changeLanguage } from '../hooks/i18n';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

export default function WebsiteAppBar() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { code } = useAuth();

    const { articles } = useSelector(state => state.articles);
    const about = articles.find(article => article.subject === 'about');
    const notice = articles.find(article => article.subject === 'notice');
    const { allSettings } = useSelector(state => state.settings);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    // for focusing section
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAdmission, setOpenAdmission] = React.useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLang = (lng) => {
        changeLanguage(lng);
        setAnchorEl(null);
    };

    // for open the new admission dialouge
    const handleCloseAdmissionFrom = () => {
        setOpenAdmission(false);
    };

    // handle menus
    const handleClickMenu = (key) => {
        if (key === 'online_admission') {
            setOpenAdmission(true);
        } else if (key === 'officiants') {
            scrollToSection('officiants')
        } else if (key === 'result') {
            navigate(`/${code}/result`);
        } else if (key === 'home') {
            navigate(`/${code}/wb/bn`);
        } else if (key === 'about') {
            navigate(`/${code}/articles/${about?.id}`);
        } else if (key === 'notice') {
            navigate(`/${code}/articles/${notice?.id}`);
        }
    };

    const navItems = ['home', 'online_admission', 'result', 'about', allSettings?.notice ? 'notice' : null];
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2, fontFamily: 'Times New Roman' }}>
                TANZIMACADEMY
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton onClick={() => handleClickMenu(`${item}`)} sx={{ textAlign: 'left' }}>
                            <ListItemText primary={t(item)} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="sticky" color="success">
                <Toolbar>
                    <Grid container>
                        <Grid item xs={3} display="flex" textAlign="left" alignItems="center">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 1, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography sx={{ fontSize: '1.5rem', fontFamily: 'Times New Roman' }}>TANZIM</Typography>
                                <Typography fontFamily={'Times New Roman'} variant="caption" marginLeft="0.5px">
                                    ACADEMY
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                {navItems.map((item) => (
                                    <Button
                                        onClick={() => handleClickMenu(item)}
                                        key={item}
                                        sx={{ color: '#fff', fontSize: '1rem' }}
                                    >
                                        {t(item)}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={3} textAlign="right">
                            <IconButton color="inherit" aria-label="change language" onClick={handleClick}>
                                <Language />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => changeLang('en')}>ENGLISH</MenuItem>
                                <MenuItem onClick={() => changeLang('bn')}>BENGALI</MenuItem>
                                <MenuItem onClick={() => changeLang('ar')}>ARABIC</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>

            <Dialog
                open={openAdmission}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <ADMISSIONFORM visitor={true} closeDialog={handleCloseAdmissionFrom} />
            </Dialog>
        </>
    );
};