import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import { CssBaseline, TextField, InputLabel, Grid, Box, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Actions and Constants
import { LoginAction } from '../../actions/authActions';

// Utilities
import { MetaData } from '../utils/metaData';
import { CLEAR_ERRORS } from '../../constants/authConstants';

function Copyright(props) {
    return (
        <Typography variant="body2" sx={{ position: 'fixed', bottom: '0' }} color="text.secondary" align="center" {...props}>
            © {new Date().getFullYear()} TANZIM ACADEMY. All rights reserved.
        </Typography>
    );
}

export default function LOGIN() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { error, isAuthenticated, user } = useSelector(state => state.auth);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t(error), { variant: 'error' });
            dispatch({ type: CLEAR_ERRORS })
            return
        }

        if (isAuthenticated && user?.code && user?.id) {
            const code = user?.code;
            if (code) {
                // store basics to the localstore
                const basics = JSON.parse(localStorage.getItem('basics'));
                const currentSettings = {
                    academyType: basics?.academyType || user.academy_type || 'school',
                    language: basics?.language || 'en',
                    themeMode: basics?.themeMode || 'light'
                };

                localStorage.setItem('basics', JSON.stringify(currentSettings));

                // set cookie to use in the backend
                document.cookie = user.academy_type !== 'madrasa' && basics?.language === 'bn' ? `lang=bn_g; path=/` : `lang=${basics?.language || 'en'}; path=/`;

                // redirect to the software
                navigate(`/${code}`);
            }
        }
    }, [isAuthenticated, user, navigate, error, enqueueSnackbar, t, dispatch]);

    // define handleSubmit function
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        // Dispatch the login action
        dispatch(LoginAction({
            email: data.get('email'),
            password: data.get('password')
        }))
    }

    return (
        <Box sx={{
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%'
        }}>
            <CssBaseline enableColorScheme />
            <MetaData title={'LOGIN'} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2rem',
                    width: '400px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 15px 1px #e0ecff',
                }}
            >
                <img
                    src="https://res.cloudinary.com/plsshopit/image/upload/v1735219463/public/tgtx3jgcexpwbicaikwh.png"
                    alt="Logo"
                    style={{ width: 60, height: 60, borderRadius: '50%', backgroundColor: '#f50057' }}
                />
                <Typography component="h1" variant="h6">
                    {t('login')}
                </Typography>
                <Box component="form" width={'100%'} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <InputLabel>
                        {t('academicEmail')}
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        size='small'
                        placeholder="academic@email.com"
                    />
                    <InputLabel sx={{ marginTop: '18px' }}>
                        {t('password')}
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        size='small'
                        placeholder="••••••"
                    />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('login')}
                    </LoadingButton>
                    <Grid container mt={1}>
                        <Grid item>
                            {/* <Link href="#" variant="body2">
                                {t('forgotPassword')}
                            </Link> */}
                            <Link href="/new_academy" variant="body2">
                                {t('askLogin')}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright />
        </Box>
    );
}
