// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { newSalary, updateSalary } from '../../actions/accountActions';
import { allStaff } from '../../actions/adminisActions';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { nanoid } from 'nanoid';

// MUI components and icons
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Select,
    InputAdornment,
    IconButton
} from '@mui/material';
import {
    Close,
    Search
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and utilities
import { RECEIPTHEADING, GETCODE } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { formattedDates, officiantDetailsText, salaryCutted } from '../../constants/commonContstants';
import { CustomCrossButton } from '../styles/style';
import { numberToBanglaWords } from '../utils/converter';
import useAuth from '../hooks/UseAuth';

export default function SALARYFORM({ closeDialog, id }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, sign_url } = useAuth();
    const [catchID, setCatchID] = useState(id);
    const [increment, setIncrement] = useState(0);
    const formRef = useRef(null);

    // getting the value from redux according to the history info for using by default
    const { users } = useSelector(state => state.allUsers);
    const { loading } = useSelector(state => state.newReceipt);
    const { salaries } = useSelector(state => state.salaries);
    const { staff } = useSelector(state => state.allStaff);
    const { success } = useSelector(state => state.newSalary);

    const getDefaultValues = (salary = {}) => ({
        id: salary?.id || nanoid(10),
        EmployeeID: salary?.employee_id || '',
        Name: salary?.name || '',
        Designation: salary?.designation || '',
        TotalWorkingDays: salary?.total_working_days || '',
        TotalAttendance: salary?.total_attendance || '',
        PerDay: salary?.per_day || '',
        BasicSalary: salary?.basic_salary || '',
        ResponsibilityAllowance: salary?.responsibility_allowance || '',
        SupervisionAllowance: salary?.supervision_allowance || '',
        HouseRentAllowance: salary?.house_rent_allowance || '',
        TransportAllowance: salary?.transport_allowance || '',
        MedicalAllowance: salary?.medical_allowance || '',
        BreakfastAllowance: salary?.breakfast_allowance || '',
        OtherAllowances: salary?.other_allowances || '',
        IncrementPercentage: salary?.increment_percentage || '',
        Overtime: salary?.over_time || '',
        Bonus: salary?.bonus || '',
        TotalSalary: salary?.total_salary || '',
        Loan: salary?.loan || '',
        ElectricityBill: salary?.electricity_bill || '',
        FoodBill: salary?.food_bill || '',
        GasBill: salary?.gas_bill || '',
        CleaningBill: salary?.cleaning_bill || '',
        GovernmentTax: salary?.government_tax || '',
        MobileBill: salary?.mobile_bill || '',
        InternetBill: salary?.internet_bill || '',
        Others: salary?.others || '',
        TotalDeductions: salary?.total_deductions || '',
        totalGiven: salary?.total_given || '',
        inWords: salary?.in_words || '',
        date: salary?.date || Object.values(formattedDates)[1]
    });
    const salary = salaries.find(salary =>
        salary.id === catchID
    );
    const defaultValues = getDefaultValues(salary);
    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allStaff(code));

        if (salary?.increment_amount) { // Showing by default when user try to update
            setIncrement(salary.increment_amount)
        }

    }, [dispatch, t, salary?.increment_amount, code])

    useEffect(() => {
        if (success) {
            reset(getDefaultValues(''));
            setValue('id', nanoid(10))
            setCatchID('')
        }

    }, [success, reset, setValue])

    // handle clear
    const handleClear = () => {
        reset(getDefaultValues(''));
        setValue('id', nanoid(10))
        setCatchID('')
    };

    // handle submit
    const onSubmit = (data) => {
        if (!catchID) {
            // Add the new key-value pair to the data object
            const updatedData = {
                ...data,
                IncrementAmount: increment,
                provider_sign_url: sign_url,  // New key-value pair
            };

            // Dispatch the updated data
            dispatch(newSalary(updatedData, code));
            setValue('id', nanoid(10));
            setValue('EmployeeID', '');
            setValue('Name', '');
            setValue('Designation', '');
        } else {
            const updatedData = {
                ...data,
                IncrementAmount: increment
            };

            dispatch(updateSalary(updatedData, code));
        }
    };

    // for controling getcode window
    const [password, setPassword] = useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    // handle aprover sign by the code
    const userType = users.find(user => user.password === password);
    const handleUserSign = async (e) => {
        e.preventDefault();
        if (userType && userType.user_type === 'councilMember') {
            setGetCodeOpen(false);
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    const lbls = [
        'EmployeeID',
        'Name',
        'Designation',
        'TotalWorkingDays',
        'TotalAttendance',
        'PerDay',
        'BasicSalary',
        'ResponsibilityAllowance',
        'SupervisionAllowance',
        'HouseRentAllowance',
        'TransportAllowance',
        'MedicalAllowance',
        'BreakfastAllowance',
        'OtherAllowances',
        'IncrementPercentage',
        'Overtime',
        'Bonus',
        'TotalSalary'
    ];

    const lbls1 = [
        'Loan',
        'ElectricityBill',
        'FoodBill',
        'GasBill',
        'CleaningBill',
        'GovernmentTax',
        'MobileBill',
        'InternetBill',
        'Others',
        'TotalDeductions'
    ];

    // Watch for changes
    const valuesToWatch = [
        'TotalAttendance', 'PerDay', 'IncrementPercentage', 'ResponsibilityAllowance',
        'SupervisionAllowance', 'HouseRentAllowance', 'TransportAllowance',
        'MedicalAllowance', 'BreakfastAllowance', 'OtherAllowances',
        'Overtime', 'Bonus', 'Loan', 'ElectricityBill', 'FoodBill',
        'GasBill', 'CleaningBill', 'GovernmentTax', 'MobileBill',
        'InternetBill', 'Others'
    ].reduce((acc, label) => ({ ...acc, [label]: watch(label) }), {});

    useEffect(() => {
        const { TotalAttendance, PerDay, IncrementPercentage, ResponsibilityAllowance, SupervisionAllowance,
            HouseRentAllowance, TransportAllowance, MedicalAllowance, BreakfastAllowance, OtherAllowances,
            Overtime, Bonus, Loan, ElectricityBill, FoodBill, GasBill, CleaningBill, GovernmentTax, MobileBill,
            InternetBill, Others } = valuesToWatch;

        const basicSalary = (parseFloat(TotalAttendance) || 0) * (parseFloat(PerDay) || 0);
        setValue('BasicSalary', basicSalary);

        const IncrementAmount = (basicSalary * (parseFloat(IncrementPercentage) || 0) / 100).toFixed(2);
        setIncrement(IncrementAmount);

        const totalSalary = basicSalary +
            [ResponsibilityAllowance, SupervisionAllowance, HouseRentAllowance,
                TransportAllowance, MedicalAllowance, BreakfastAllowance, OtherAllowances,
                Overtime, Bonus].reduce((sum, val) => sum + (parseFloat(val) || 0), 0) +
            parseFloat(IncrementAmount) || 0;
        setValue('TotalSalary', totalSalary);

        const deductionAmount = [
            Loan, ElectricityBill, FoodBill, GasBill, CleaningBill, GovernmentTax,
            MobileBill, InternetBill, Others
        ].reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
        setValue('TotalDeductions', deductionAmount);

        const totalGiven = totalSalary - deductionAmount;
        setValue('totalGiven', totalGiven);

        setValue('inWords', `${numberToBanglaWords(totalGiven)} ${t('money')} ${t('just')}`)

    }, [valuesToWatch, setValue, t]);

    // check staff information
    const check = (idNo) => {
        const s = staff.find(s => s.staff_id === parseInt(idNo));
        if (s) {
            setValue('Name', s.name);
            setValue('Designation', s.designation);
        } else {
            enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });
        }
    };

    const checkSalary = () => {
        if (!getValues('EmployeeID')) return enqueueSnackbar(t('writeID'), { variant: 'error' });
        const previousMonth = getValues('date');

        const previousMonthSalary = salaries
            .filter(s => s.employee_id === getValues('EmployeeID')) // Ensure consistent case with 'EmployeeID'
            .find(s => s.date === previousMonth); // Access 's.date' directly in the find callback

        if (!previousMonthSalary) return enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });

        reset(getDefaultValues(previousMonthSalary));
        setValue('id', nanoid())
    };

    return (
        <Container component="main" maxWidth="md" sx={{
            borderRadius: '10px',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
        }}>
            <MetaData title={'SALARY FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <GETCODE
                headingText={t('councilCode')}
                handleSign={handleUserSign}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                password={password}
                setPassword={setPassword}
            />
            <CssBaseline />
            <RECEIPTHEADING text={t('givingSalaryForm')} department={t('accounts')} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '12px'
                }}
            >
                <Box component='form' width={'100%'} onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container
                        spacing={2}
                        sx={{
                            overflow: 'auto',
                            position: 'relative',
                            height: {
                                xs: 'auto',
                                sm: '500'
                            }
                        }}
                    >
                        {/* for handling id */}
                        <Controller
                            name={'id'}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{
                                        display: 'none'
                                    }}
                                />
                            )}
                        />
                        <Grid item xs={12} md={6}
                            sx={{
                                paddingTop: '10px',
                            }}
                        >
                            <TableContainer sx={{
                                maxHeight: {
                                    xs: 'auto',
                                    sm: '460px'
                                }
                            }}>
                                <Table stickyHeader aria-label="custom table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2}
                                                sx={{
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                    top: 0,
                                                    zIndex: 1
                                                }}>
                                                {t('officiantsDetailsAndSalary')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {officiantDetailsText.map((lbl, index) => (
                                            <TableRow key={`row-${index}`}>
                                                <TableCell align="left" sx={{ padding: '5px' }}>{lbl}</TableCell>
                                                <TableCell align="center" sx={{ padding: '5px' }}>
                                                    <Controller
                                                        name={`${lbls[index]}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="small"
                                                                fullWidth
                                                                disabled={catchID && index === 0 ? true : false}
                                                                aria-readonly
                                                                type={index === 1 || index === 2 ? 'text' : 'number'}
                                                                autoFocus={index === 0 && !catchID}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {index === 0 ?
                                                                                <IconButton size='small' onClick={() => check(field.value)}>
                                                                                    <Search />
                                                                                </IconButton > :
                                                                                index === 1 || index === 2 ? '' :
                                                                                    index === 3 || index === 4 ? t('day') :
                                                                                        index === 14 ? `${increment} ${t('moneySymbol')}` : t('moneySymbol')}
                                                                        </InputAdornment>
                                                                    ),
                                                                    disableUnderline: true,
                                                                    readOnly: index === 6 || index === 17 ? true : false
                                                                }}
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInputBase-root': {
                                                                        padding: '2px',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={6}
                            sx={{
                                paddingTop: '10px',
                            }}
                        >
                            <TableContainer sx={{
                                maxHeight: {
                                    xs: 'auto',
                                    sm: '400px'
                                }

                            }}>
                                <Table stickyHeader aria-label="custom table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2} sx={{
                                                fontSize: '1rem',
                                                width: '100%',
                                                padding: '10px',
                                                top: 0,
                                                zIndex: 1
                                            }}>
                                                {t('salaryCutteDetails')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {salaryCutted.map((lbl, index) => (
                                            <TableRow key={`row-${index}`}>
                                                <TableCell align="left" sx={{ padding: '5px' }}>{lbl}</TableCell>

                                                <TableCell align="center" sx={{ padding: '5px' }}>
                                                    <Controller
                                                        name={`${lbls1[index]}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                size="small"
                                                                fullWidth
                                                                type='number'
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">{t('moneySymbol')}</InputAdornment>,
                                                                    disableUnderline: true,
                                                                    readOnly: index === 9 ? true : false
                                                                }}
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInputBase-root': {
                                                                        padding: '2px',
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2} sx={{ fontSize: '1rem', width: '100%', padding: '10px' }}>
                                                {t('totalGivingSalary')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {[t('totalGiven'), t('inWords'), t('date')].map((lbl, index) => (
                                            <TableRow key={`row-${index}`}>
                                                <TableCell align="left" sx={{ padding: '5px' }}>{lbl}</TableCell>
                                                <TableCell align="left" sx={{ padding: '5px' }}>
                                                    {index === 0 || index === 1 ?
                                                        <Controller
                                                            name={index === 0 ? 'totalGiven' : 'inWords'}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    size="small"
                                                                    fullWidth
                                                                    multiline={index === 1 ? true : false}
                                                                    type={index === 0 ? 'number' : 'text'}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">{index === 0 ? t('moneySymbol') : ''}</InputAdornment>,
                                                                        disableUnderline: true,
                                                                        readOnly: index === 0 ? true : false
                                                                    }}
                                                                    variant="standard"
                                                                    sx={{
                                                                        '& .MuiInputBase-root': {
                                                                            padding: '2px',
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        :

                                                        <Controller
                                                            key={`controller-${lbl}`}
                                                            name={'date'}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    size="small"
                                                                    sx={{
                                                                        '.MuiSelect-select': {
                                                                            height: '1.4375em',
                                                                            padding: '0 0 0 5px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        },
                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: 'none',
                                                                        },
                                                                    }}
                                                                >
                                                                    {Object.entries(formattedDates).map(([key, value]) => (
                                                                        <MenuItem key={key} value={value}>
                                                                            <Typography component="div">
                                                                                {value}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        />
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid item container spacing='5' marginBottom={2} marginTop={2.8} justifyContent={'right'}>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="error"
                                        onClick={handleClear}
                                    >
                                        {t('clear')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={checkSalary}
                                    >
                                        {t('checkSalary')}
                                    </Button>
                                </Grid>
                                {catchID ?
                                    <Grid item>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            loading={loading ? true : false}
                                            variant="contained"
                                        >
                                            <span>{t('update')}</span>
                                        </LoadingButton>
                                    </Grid>
                                    :
                                    <Grid item>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            color="success"
                                            loading={loading ? true : false}
                                            variant="contained"
                                        >
                                            <span>{t('save')}</span>
                                        </LoadingButton>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}