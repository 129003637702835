import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'

import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// importing reducers to store data in redux local storage 
import { authReducer, allUsersReducer, userReducer } from './reducers/authReducers';
import { allRegardingReducer, newReceiptReducer, allReciptReducer, receiptReducer, getCountingDataReducer, searchCountingDataReducer, countingDataActionReducer, newVoucherReducer, allVoucherReducer, voucherReducer, newRegardingReducer, newSalaryReducer, allSalaryReducer, salaryReducer, allApprovedBooksReducer, newFeeReducer, allFeesReducer, approvedBooksReducer, feeReducer, approvedBookReducer, newApprovedBookReducer, newReportReducer, reportBalanceReducer, reportReducer } from './reducers/accountReducers';
import { actionResultReducer, addStudentAttendReducer, allClassReducer, allRoutinesReducer, allStudentAttendance, allStudentLeaveReducer, allStudentsReducer, classReducer, getResultReducer, newClassReducer, newResultReducer, newStudentLeaveReducer, newStudentReducer, routineReducer, studentLeaveReducer, studentReducer } from './reducers/eduReducers';
import { addOfficiantAttendReducer, allDutiesReducer, allFurnitureReducer, allGivenFurnitureReducer, allOfficiantAttendance, allStaffLeaveReducer, allStaffReducer, allStaffReportReducer, furnitureReducer, dutyReducer, newFurnitureReducer, newGivenFurnitureReducer, newStaffLeaveReducer, newStaffReducer, newStaffReportReducer, staffLeaveReducer, staffReducer, staffReportReducer } from './reducers/adminisReducers';
import { allStudentReportsReducer, newStudentReportReducer, studentReportReducer } from './reducers/hostelReducers';
import { acceptProvidedBookReducer, allBooksReducer, allProvidedBooksReducer, bookReducer, newBookReducer, newProvidedBookReducer, providedBookReducer } from './reducers/libraryReducers';
import { allArticlesReducer, allProductReducer, allSoldProductReducer, dashboardReducer, newArticleReducer, newProductReducer, newSellingProductReducer, PDFReducer, productReducer, sendSMSReducer, settingsReducer } from './reducers/othersReducers';
import { allDonationsReducer, allDonorsReducer, donationReducer, donorReducer, newDonationReducer, newDonorReducer } from './reducers/donorReducers';
import { academyReducer, allAcadmiesReducer, emailVarifyReducer, newAcademyReducer } from './reducers/superAdminReducers';

const reducer = combineReducers({
    // super admin
    newAcademy: newAcademyReducer,
    allAcademies: allAcadmiesReducer,
    // currentAcademy: singleAcadmyReducer,
    academy: academyReducer,
    emailVarify: emailVarifyReducer,

    // administration
    auth: authReducer,
    regarding: newRegardingReducer,
    allRegarding: allRegardingReducer,
    newReceipt: newReceiptReducer,
    newVoucher: newVoucherReducer,
    user: userReducer,
    receipt: receiptReducer,
    voucher: voucherReducer,
    allReceipt: allReciptReducer,
    allVoucher: allVoucherReducer,
    allCountingData: getCountingDataReducer,
    allUsers: allUsersReducer,
    searchedCountingData: searchCountingDataReducer,
    countingDataAction: countingDataActionReducer,
    newSalary: newSalaryReducer,
    salaries: allSalaryReducer,
    salary: salaryReducer,
    allApprovedBooks: allApprovedBooksReducer,
    approvedBooks: approvedBooksReducer,
    approvedBook: approvedBookReducer,
    newApprovedBook: newApprovedBookReducer,
    newStaffLeave: newStaffLeaveReducer,
    allStaffLeaves: allStaffLeaveReducer,
    staffLeave: staffLeaveReducer,
    allDuties: allDutiesReducer,

    // counting report
    newReport: newReportReducer,
    reportBalance: reportBalanceReducer,
    report: reportReducer,

    // for boarding department
    newFee: newFeeReducer,
    allFees: allFeesReducer,
    fee: feeReducer,

    // for education department
    newClass: newClassReducer,
    allClass: allClassReducer,
    class: classReducer,
    newStudent: newStudentReducer,
    student: studentReducer,
    allStudent: allStudentsReducer,
    newResult: newResultReducer,
    result: getResultReducer,
    resultActions: actionResultReducer,
    studentAttendance: addStudentAttendReducer,
    allStudentAttendance: allStudentAttendance,
    newStudentLeave: newStudentLeaveReducer,
    allStudentLeaves: allStudentLeaveReducer,
    studentLeave: studentLeaveReducer,
    allRoutines: allRoutinesReducer,
    newRoutine: routineReducer,

    // for library department
    newBook: newBookReducer,
    book: bookReducer,
    allBooks: allBooksReducer,
    newProvidedBooks: newProvidedBookReducer,
    providedBooks: allProvidedBooksReducer,
    providedBook: providedBookReducer,
    acceptBook: acceptProvidedBookReducer,

    // for administrator department
    newStaff: newStaffReducer,
    allStaff: allStaffReducer,
    staff: staffReducer,
    newStaffReport: newStaffReportReducer,
    allStaffReports: allStaffReportReducer,
    staffReport: staffReportReducer,
    newFurniture: newFurnitureReducer,
    allFurniture: allFurnitureReducer,
    newGivenFurniture: newGivenFurnitureReducer,
    allGivenFurniture: allGivenFurnitureReducer,
    furniture: furnitureReducer,
    officinatAttend: addOfficiantAttendReducer,
    allOfficiantAttend: allOfficiantAttendance,
    duty: dutyReducer,

    // hostel
    newStudentReport: newStudentReportReducer,
    std_reports: allStudentReportsReducer,
    studentReport: studentReportReducer,

    // others
    newProduct: newProductReducer,
    allProduct: allProductReducer,
    newSellingProduct: newSellingProductReducer,
    allSoldProduct: allSoldProductReducer,
    product: productReducer,
    dashboardInfo: dashboardReducer,
    settings: settingsReducer,
    messages: sendSMSReducer,
    PDF: PDFReducer,
    newArticle: newArticleReducer,
    articles: allArticlesReducer,

    // for donation department
    newDonor: newDonorReducer,
    donor: donorReducer,
    allDonors: allDonorsReducer,
    newDonation: newDonationReducer,
    donations: allDonationsReducer,
    donation: donationReducer
})

// making store 
const middleware = [thunk]
let store;

if (process.env.NODE_ENV === 'development') {
    store = createStore(
        reducer,
        composeWithDevTools(applyMiddleware(...middleware))
    );
} else {
    store = createStore(
        reducer,
        applyMiddleware(...middleware)
    );
}

export default store;