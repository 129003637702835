// React and related hooks
import React, { useEffect, useState } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteReport,
    getCountingData,
    getReportBalance,
    newReport
} from '../../actions/accountActions';
import { countingReportPdf } from '../../actions/pdfActions';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import {
    Grid,
    Box,
    Button,
    ButtonGroup,
    Typography,
    Menu,
    MenuItem,
    Select,
    IconButton,
    Chip,
    Input
} from '@mui/material';
import {
    Save,
    Delete,
    ContentPasteSearch,
    ReplayCircleFilled,
    Search,
    FileDownloadOutlined
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import { formattedDates, formattedDatesYear } from '../../constants/commonContstants';
import {
    SEARCHED_COUNTING_DATA_RESET,
    DELETE_REPORT_RESET,
    NEW_REPORT_RESET
} from '../../constants/accountConstants';
import useAuth from '../hooks/UseAuth';

function CustomToolbar({ typetxt }) {
    return (
        <GridToolbarContainer sx={{
            padding: '5px',
            justifyContent: 'center',
        }}>
            <Chip label={typetxt} />
        </GridToolbarContainer>
    );
}

export default function MONTHLYREPORT({ countType }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');
    const theme = useTheme();

    // for storing basic data according to the countType
    let type;
    if (countType === 'mcr') {
        type = 'mcr';
    } else if (countType === 'mgr') {
        type = 'mgr';
    } else if (countType === 'ycr') {
        type = 'ycr';
    } else if (countType === 'ygr') {
        type = 'ygr';
    } else {
        type = 'mcr';
    }

    // define state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const formatDate = (type === 'mcr' || type === 'mgr')
        ? Object.values(formattedDates)[0]
        : Object.values(formattedDatesYear)[0];
    const [countDate, setCountDate] = useState(formatDate);

    const preDate = (type === 'mcr' || type === 'mgr')
        ? Object.values(formattedDates)[1]
        : Object.values(formattedDatesYear)[1];

    // fetch data from redux store
    const { countingData, dates, loading, searched } = useSelector(state => state.allCountingData);
    const { isSaved } = useSelector((state) => state.newReport);
    const { isDeleted } = useSelector((state) => state.report);
    const { balances } = useSelector((state) => state.reportBalance);
    const { PDFLoading } = useSelector(state => state.PDF);

    const pre_balance = parseFloat(balances?.pre_balance) || 0;
    const balance = parseFloat(balances?.balance) || 0;

    // fetch data from redux store when component is mounted
    useEffect(() => {
        const data = {
            type: type,
            date: countDate
        };
        dispatch(getReportBalance(data, code))
        dispatch(getCountingData(data, code));
        dispatch({ type: SEARCHED_COUNTING_DATA_RESET })

        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_REPORT_RESET })
        }
    }, [dispatch, type, code, countDate, isSaved, t, enqueueSnackbar]);

    // for change mui defualt localtext of row selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: type === 'mgr' || type === 'mcr' ? t('monthlyReportText') : t('yearlyReportText'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    useEffect(() => {
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            const data = {
                type: type,
                date: countDate
            };
            dispatch(getReportBalance(data, code))
            dispatch(getCountingData(data, code));
            dispatch({ type: DELETE_REPORT_RESET })
        }
    }, [dispatch, type, enqueueSnackbar, t, countDate, code, isDeleted]);

    // for handling date drop down when saving the monthly data
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    // for handling date drop down when search the monthly data
    const handleChangeDate = (event) => {
        setCountDate(event.target.value);
    };

    const handleSearch = (value) => {
        const data = {
            type: type,
            date: value,
            search: true
        };
        dispatch(getReportBalance(data, code));
        dispatch(getCountingData(data, code));
        setAnchorEl2(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    // actions
    const handleDelete = () => {
        const data = {
            type: type,
            date: countDate,
        };
        dispatch(deleteReport(data, code))
    }

    const handleRefresh = () => {
        const data = {
            type: type,
            date: countDate,
            preDate: preDate
        };
        dispatch(getReportBalance(data, code))
        dispatch(getCountingData(data, code));
        dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
    };

    // // MUI DataGrid column configuration
    const createColumns = (label1, label2) => [
        { field: 'col1', headerName: t(label1), headerAlign: 'center', flex: 1, disableColumnMenu: true, sortable: false },
        { field: 'col2', headerName: t(label2), headerAlign: 'center', flex: 1, type: 'number', disableColumnMenu: true, sortable: false },
        { field: 'col3', headerName: t(label1), headerAlign: 'center', flex: 1, disableColumnMenu: true, sortable: false },
        { field: 'col4', headerName: t(label2), headerAlign: 'center', flex: 1, type: 'number', disableColumnMenu: true, sortable: false }
    ];
    const columnsIncome = createColumns('regarding', 'money');
    const columnsExpense = createColumns('regarding', 'money');

    // handle quick search query
    const [incomeRows, setIncomeRows] = useState([]);
    const [expenseRows, setExpenseRows] = useState([]);
    const [filteredIncomeRows, setFilteredIncomeRows] = useState([]);
    const [filteredExpenseRows, setFilteredExpenseRows] = useState([]);

    // Function to consolidate counts by summing values under the same headings
    const consolidateCounts = (counts, headings, excludedKeys = ['id', 'date', 'total', 'book_no', 'receipt_no', 'detailed_receipt_no', 'month']) => {
        const consolidatedData = {};
        counts.slice(-1).forEach((count) => {
            Object.keys(count).forEach((key) => {
                if (excludedKeys.includes(key)) return;
                const heading = headings[key];
                consolidatedData[heading] = (consolidatedData[heading] || 0) + count[key];
            });
        });
        return consolidatedData;
    };

    // Function to format consolidated data into rows for DataGrid display
    const formatDataGridRows = (consolidatedData, additionalRows = []) => {
        const rows = [];
        const headings = Object.keys(consolidatedData);
        for (let i = 0; i < headings.length; i += 2) {
            rows.push({
                id: Math.floor(i / 2) + 1,
                col1: headings[i] || '',
                col2: consolidatedData[headings[i]] || '',
                col3: headings[i + 1] || '',
                col4: consolidatedData[headings[i + 1]] || ''
            });
        }
        return rows.concat(additionalRows);
    };

    // Effect to run when countingData changes
    useEffect(() => {
        if (countingData) {
            // Consolidate income and expense data
            const consolidatedIncome = consolidateCounts(countingData[0]?.counts || [], countingData[0]?.headings || {});
            const consolidatedExpense = consolidateCounts(countingData[1]?.counts || [], countingData[1]?.headings || [], ['id', 'date', 'total', 'voucher_no', 'month']);

            // Create formatted rows with totals for DataGrid
            const totalIncomeValue = Object.values(consolidatedIncome).reduce((acc, val) => acc + val, 0);
            const totalExpenseValue = Object.values(consolidatedExpense).reduce((acc, val) => acc + val, 0);


            const formattedIncomeRows = formatDataGridRows(consolidatedIncome, [
                { id: 'totalIncome', col1: '', col2: '', col3: t('totalIncome'), col4: totalIncomeValue },
                { id: 'remain', col1: '', col2: '', col3: type === 'mcr' || type === 'mgr' ? t('monthlyBalance') : t('yearlyBalance'), col4: (totalIncomeValue || totalExpenseValue) && !searched ? balance : searched ? pre_balance : 0 }
            ]);

            const formattedExpenseRows = formatDataGridRows(consolidatedExpense, [
                { id: 'totalExpense', col1: '', col2: '', col3: t('totalExpense'), col4: totalExpenseValue }
            ]);

            // Calculate final values and append summary rows
            const totalIncome = formattedIncomeRows.find(row => row.id === 'totalIncome')?.col4 || 0;
            const totalExpense = formattedExpenseRows.find(row => row.id === 'totalExpense')?.col4 || 0;
            const remain = formattedIncomeRows.find(row => row.id === 'remain')?.col4 || 0;
            const overallIncome = totalIncome + remain;

            const summaryRows = [
                { id: 'justTotal', col1: '', col2: '', col3: t('justTotal'), col4: overallIncome },
                { id: 'empty', col1: '', col2: '', col3: '', col4: '' },
                { id: 'allTotalIncome', col1: '', col2: '', col3: t('allTotalIncome'), col4: overallIncome },
                { id: 'allTotalExpense', col1: '', col2: '', col3: t('allTotalExpense'), col4: totalExpense },
                { id: 'balance', col1: '', col2: '', col3: t('balance'), col4: overallIncome - totalExpense }
            ];

            // Append summary rows to income rows
            const updatedIncomeRows = [...formattedIncomeRows, ...summaryRows];

            // Update the state with formatted rows, searching will be handled by these
            setIncomeRows(updatedIncomeRows);
            setExpenseRows(formattedExpenseRows);

            // Update the filtered rows with the new income rows
            setFilteredIncomeRows(updatedIncomeRows);
            setFilteredExpenseRows(formattedExpenseRows);
        }
    }, [countingData, t, balance, type, pre_balance, searched]);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter income rows
        const filteredIncome = incomeRows.filter(row =>
            Object.values(row).some(value =>
                String(value).toLowerCase().includes(query)
            )
        );

        // Filter expense rows
        const filteredExpense = expenseRows.filter(row =>
            Object.values(row).some(value =>
                String(value).toLowerCase().includes(query)
            )
        );

        // Update filtered rows
        setFilteredIncomeRows(filteredIncome);
        setFilteredExpenseRows(filteredExpense);
    };

    const saveData = () => {
        const preBalance = filteredIncomeRows.find(row => row.id === 'remain')?.col4 || 0;
        const balance = filteredIncomeRows.find(row => row.id === 'balance').col4;

        const data = {
            type: type,
            date: countDate,
            preBalance: preBalance,
            balance: balance
        }

        dispatch(newReport(data, code));
    };

    // for prints option selection
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        const data = {
            heading: `${t(type)}, ${countDate}`,
            columns: [t('regarding'), t('money'), t('regarding'), t('money')],
            rowsIncome: filteredIncomeRows,
            rowsExpense: filteredExpenseRows
        }
        setCurrentRows(data)
    };

    const handlePdf = () => {
        dispatch(countingReportPdf(currentRows));
    };

    return (
        <Box className="globalShapeDesign" >
            <MetaData title={'COUNTING REPORTS'} />
            <Box mt={0.7}>
                <Grid container alignItems='center' sx={{ padding: '0px 0px 10px 0px' }}>
                    <Grid xs={6} sm={2} order={{ sm: 1, xs: 2 }} item>
                        <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                            <Button
                                aria-label="Delete icon"
                                onClick={handleDelete}
                                disabled={!searched ? true : false}
                            >
                                <Delete />
                            </Button>
                            <Button
                                aria-label="Add icon"
                                onClick={saveData}
                                disabled={filteredIncomeRows.length <= 7 || searched ? true : false}
                            ><Save />
                            </Button>
                            <Button
                                aria-label="refresh icon"
                                onClick={handleRefresh}
                                disabled={!searched ? true : false}
                            >
                                <ReplayCircleFilled />
                            </Button>
                            <Button
                                aria-label="Print icon"
                                onClick={handleDownloadFile}
                            >
                                <FileDownloadOutlined />
                            </Button>
                        </ButtonGroup>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        </Menu>
                    </Grid>
                    <Grid xs={12} sm={8} order={{ sm: 2, xs: 1 }} item>
                        <Box display='flex' justifyContent='center' flexDirection={{ xs: 'column', sm: 'row' }} alignItems={'center'}>
                            <Typography sx={{ fontSize: '1.1rem' }} component="div">{t(type)}।</Typography>
                            {/* {!foundMonthlyReport ? */}
                            <Box display={'flex'}>
                                <Select
                                    value={countDate}
                                    onChange={handleChangeDate}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    size="small"
                                    sx={{
                                        '.MuiSelect-select': {
                                            'padding': '0 0 0 5px',
                                            'display': 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                '& .MuiTypography-root': {
                                                    fontSize: '1rem',
                                                }
                                            },
                                        },
                                    }}
                                >
                                    {Object.entries((type === 'mcr' || type === 'mgr') ? formattedDates : formattedDatesYear).map(([key, value]) => (
                                        <MenuItem key={key} value={value}>
                                            <Typography component="div" sx={{ fontSize: '1.1rem' }}>
                                                {value}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <IconButton
                                        onClick={handleClick2}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open2 ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open2 ? 'true' : undefined}
                                    >
                                        <ContentPasteSearch />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Menu
                                anchorEl={anchorEl2}
                                id="account-menu"
                                open={open2}
                                onClose={handleClose2}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        maxHeight: 500,
                                        overflow: 'auto',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {dates && dates.map((item, index) => (
                                    <MenuItem key={index} onClick={() => handleSearch(item.date)}>
                                        {item.date}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={2} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'end', paddingRight: 1, alignItems: 'end' }}>
                        <Search
                            sx={{
                                marginRight: '5px',
                                fontSize: '1.25rem'
                            }}
                        />
                        <Input
                            placeholder={t('typeHere')}
                            size='small'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: '92.8%', width: '100%', display: 'flex' }} flexDirection={{ xs: 'column', sm: 'row' }}>
                <DataGrid
                    rows={countingData.length > 0 ? filteredIncomeRows : []}
                    columns={columnsIncome}
                    density="compact"
                    pageSizeOptions={[5, 10, 20, 50, 99]}
                    checkboxSelection
                    scrollbarSize={0}
                    localeText={localeText}
                    loading={loading}
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}
                    sx={{
                        marginRight: '10px',
                        '& .MuiDataGrid-row:nth-last-of-type(-n+7) .MuiDataGrid-cell': {
                            border: 'none'
                        },
                        '& .MuiDataGrid-row:nth-last-of-type(7)': {
                            borderTop: '0.5px solid #ccc'
                        },
                        '& .MuiDataGrid-row:nth-last-of-type(5)': {
                            borderTop: '0.5px solid lightblue',
                            backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#f0f0f0'
                        },
                        '& .MuiDataGrid-row:nth-last-of-type(1)': {
                            borderTop: '0.5px solid lightblue',
                            backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#f0f0f0'
                        },

                        // Align text to the right in column4 of the last 3 rows
                        '& .MuiDataGrid-row:nth-last-of-type(-n+7) .MuiDataGrid-cell:nth-of-type(4)': {
                            textAlign: 'right',
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 20 },
                        },
                        columns: {
                            columnVisibilityModel: {
                                __check__: false,
                            },
                        },
                    }}
                    slots={{
                        toolbar: () => <CustomToolbar typetxt={t('incomeReport')} />,
                        pagination: CustomPagination,
                    }}
                />

                <DataGrid
                    rows={countingData.length > 0 ? filteredExpenseRows : []}
                    columns={columnsExpense}
                    density="compact"
                    pageSizeOptions={[5, 10, 20, 50, 99]}
                    checkboxSelection
                    scrollbarSize={0}
                    localeText={localeText}
                    loading={loading || PDFLoading}
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}
                    sx={{
                        '& .MuiDataGrid-row:nth-last-of-type(-n+1) .MuiDataGrid-cell': {
                            border: 'none'
                        },
                        '& .MuiDataGrid-row:nth-last-of-type(1)': {
                            border: 'none',
                            borderTop: '0.5px solid lightblue',
                            backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#f0f0f0',
                            textAlign: 'right',
                        },
                        // Align text to the right in column4 of the last 1 rows
                        '& .MuiDataGrid-row:nth-last-of-type(1) .MuiDataGrid-cell:nth-of-type(4)': {
                            textAlign: 'right',
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 20 },
                        },
                        columns: {
                            columnVisibilityModel: {
                                __check__: false,
                            },
                        },
                    }}
                    slots={{
                        toolbar: () => <CustomToolbar typetxt={t('expenseReport')} />,
                        pagination: CustomPagination,
                    }}
                />
            </Box>
        </Box>
    )

}