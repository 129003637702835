import React from 'react';
import { Toolbar, Grid, Box, Container, Card, CardContent, Typography, Chip, Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { allArticles, getSettings } from '../../actions/othersActions';
import { allStaff } from '../../actions/adminisActions';

import { MetaData } from '../utils/metaData';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { LoginAction } from '../../actions/authActions';
import { allClass, allStudents, getResult } from '../../actions/eduActions';
import useAuth from '../hooks/UseAuth';
import WebsiteAppBar from './AppBar';

export default function ACADEMICWEBSITE() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { code } = useParams();
    const { academyPhoto } = useAuth();
    const isFirstRender = React.useRef(true);

    const { staff } = useSelector(state => state.allStaff);
    const { allSettings } = useSelector(state => state.settings);

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            dispatch(LoginAction({ visitorCode: code }))
            dispatch(getSettings(code))
            dispatch(allStaff(code))
            dispatch(allClass(code))
            dispatch(allStudents(code))
            dispatch(getResult(code));
            dispatch(allArticles(code));
        }
    }, [dispatch, code]);

    return (
        <Box>
            <MetaData title={'WEBSITE- HOME'} />
            <Box sx={{ alignItems: 'center' }} padding={1}>
                <RECEIPTHEADING visitor={true} />
            </Box>
            <WebsiteAppBar />
            <Box component={'main'} width={'100%'}>
                <Box
                    sx={{
                        height: "67vh",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "100vw",
                            height: "100%",
                            backgroundImage: `url(${academyPhoto})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                    />
                </Box>

                <Container
                    id="officiants"
                    sx={{
                        marginTop: 5,
                        textAlign: "center",
                        display: allSettings?.officiants ? 'block' : 'none'
                    }}
                >
                    <Chip
                        label={t('ourOfficiants')}
                        sx={{ fontSize: '1.7rem', marginBottom: '1rem', padding: 3, borderRadius: '50px' }}
                    />
                    <Grid container spacing={4} justifyContent="center">
                        {staff?.map((teacher, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{
                                        maxWidth: 300,
                                        margin: "auto",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                        "&:hover": {
                                            transform: "translateY(-10px)",
                                            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                                        },
                                    }}
                                >
                                    <Avatar sx={{ width: 90, height: 90, marginTop: 2 }} alt={teacher.name} src={teacher.photo_url} />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                            textAlign="center"
                                        >
                                            {teacher.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            textAlign="center"
                                        >
                                            {teacher.designation}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container >
            </Box>

            <Box elevation={0} sx={{ padding: 1, marginTop: 4 }}>
                <Toolbar variant='dense' sx={{ justifyContent: 'center', fontFamily: 'Times New Roman' }}>
                    COPYRIGHT 2024 TANZIMIT
                </Toolbar>
            </Box>
        </Box>
    );
}