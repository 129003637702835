export const getPastTenYears = () => {
    const currentYear = new Date().getFullYear();
    const pastTenYears = [];

    for (let i = 0; i < 10; i++) {
        pastTenYears.push(currentYear - i);
    }

    return pastTenYears;
};

// Utility function to check if the message contains non-language letters
export const containsNonLanguageLetter = (text) => {
    const nonLanguageRegex = /[^A-Za-z0-9\s]/; // Regex to detect non-English, non-digit, and special characters
    return nonLanguageRegex.test(text);
};

export const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
};