// React and related hooks
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import {
    Avatar,
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container
} from '@mui/material';
import {
    LoadingButton
} from '@mui/lab';
import {
    Close
} from '@mui/icons-material';

// MUI DatePicker components
import {
    LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Custom components and utilities
import { CustomCrossButton } from '../styles/style';
import { convertToBengaliDigits } from '../utils/converter';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import { addStaffDuty } from '../../actions/adminisActions';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { MetaData } from '../utils/metaData';

function DUTYFORM({ closeDialog, rowData }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const { code } = useAuth();

    const { loadingDuty } = useSelector(state => state.duty);

    const defaultValues = {
        cardNumber: rowData?.card || '',
        dutyStart: rowData?.duty_start || '',
        dutyEnd: rowData?.duty_end || '',
        residenceType: rowData?.residenceType || '',
        pin: rowData?.pin || '',
        shift: rowData?.shift || '',
        teamName: rowData?.teamName || ''
    };
    const { handleSubmit, control } = useForm({ defaultValues });

    // handle submit
    // handle submit
    const onSubmit = async (data) => {
        const isUpdate = rowData.dutyStart ? true : false;
        dispatch(addStaffDuty({ idNo: rowData.id, ...data, isUpdate: isUpdate }, code));
    }

    return (
        <Container maxWidth={false} sx={{
            borderRadius: '10px',
            maxWidth: '700px',
        }}>
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <MetaData title={'DUTY FORM'} />
            {/* Sidebar */}
            <Grid container spacing={3} paddingTop={4} paddingBottom={4}>
                <Grid item xs={12} sm={5}>
                    <Paper sx={{ padding: 2, height: '100%' }}>
                        <Avatar
                            alt="Profile Picture"
                            src={rowData?.imageUrl} // Replace with your avatar image path
                            sx={{ width: 100, height: 100, margin: '0 auto', mb: 2, border: '1px solid #ccc' }}
                        />
                        {/* User Info Section */}
                        <Typography variant="h7" component="div" fontWeight="bold">
                            {t('idNo')}: {convertToBengaliDigits(rowData?.idNo || '')}
                        </Typography>
                        <Typography variant="h7" component="div" fontWeight="bold">
                            {t('name')}: {rowData?.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('designation')}: {rowData?.designation}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('father')}: {rowData?.fatherName}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {rowData?.address}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('mobile')}: {convertToBengaliDigits(rowData?.mobile || '')}
                        </Typography>
                    </Paper>
                </Grid>

                {/* Account Settings Form */}
                <Grid item xs={12} sm={7}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {t('officiantDuty')}
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'
                        >
                            <Grid container spacing={1.5} item xs={12}>
                                {['teamName', 'shift', 'residenceType', 'dutyStart', 'dutyEnd'].map((lbl, index) => (
                                    <Grid item
                                        xs={12}
                                        key={`lbl${index}`}
                                    >
                                        {lbl === 'dutyStart' || lbl === 'dutyEnd' ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    name={lbl}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TimePicker
                                                            {...field}
                                                            value={field.value ? dayjs(field.value) : null}
                                                            slotProps={{
                                                                textField: {
                                                                    size: 'small', fullWidth: true, required: true,
                                                                    label: t(lbl)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                        ) : (
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        label={t(lbl)}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>

                            <LoadingButton
                                loading={loadingDuty}
                                type='submit'
                                variant="contained"
                                color="success" sx={{ mt: 2 }}>
                                {t('save')}
                            </LoadingButton>
                            <Button variant="contained" color="error" sx={{ mt: 2, marginLeft: '10px' }}>
                                {t('clear')}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DUTYFORM;
