// React and related hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

// MUI components and icons
import {
    Checkbox, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box, Typography,
    TextField, InputAdornment, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl
} from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and utilities
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { CustomCrossButton } from '../styles/style';
import RECEIPTFORM from '../account/ReciptForm';
import { convertDigit, convertToBengaliDigits } from '../utils/converter';
import useAuth from '../hooks/UseAuth';

// Actions and Constants
import { newFee, updateFee } from '../../actions/accountActions';
import { receiptsPdf } from '../../actions/pdfActions';
import { NEW_RECEIPT_RESET } from '../../constants/accountConstants';
import { nanoid } from 'nanoid';
import { MetaData } from '../utils/metaData';
import { months_values } from '../../constants/commonContstants';

export default function SubjectFeeTable({ closeDialog, id }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { fees } = useSelector(state => state.allFees);
    const { students } = useSelector(state => state.allStudent);
    const { staff } = useSelector(state => state.allStaff);
    const { code } = useAuth();
    const months = months_values(t)

    const [receiptData, setReceiptData] = useState({})
    const [userInfo, setUserInfo] = useState([])
    const [tableType, setTableType] = useState('studentTable')
    const [provider, setProvider] = useState(false)
    const [catchID, setCatchID] = useState(id)
    const [serchID, setSerchID] = useState(null)

    // Set default values for form fields
    const { control, handleSubmit, watch, getValues, setValue, reset } = useForm({
        defaultValues: {
            idNo: '',
            name: '',
            fatherName: '',
            classOrLevel: '',
            address: '',
            mobile: '',
            note: '',
            year: convertToBengaliDigits(new Date().getFullYear()),
            selectedSubjects: {}
        }
    });

    // Define subjects with label and value
    const subjects = [
        { label: t('boardingFee'), value: 'boardingFee', amount: parseInt(getValues('boardingFee')) || 0 },
        { label: t('monthlyFee'), value: 'monthlyFee', amount: parseInt(getValues('monthlyFee')) || 0 },
        { label: t('residentialFee'), value: 'residentialFee', amount: parseInt(getValues('residentialFee')) || 0 },
        { label: t('electricityFee'), value: 'electricityFee', amount: parseInt(getValues('electricityFee')) || 0 }
    ];

    // for showing default values when provider is the same
    useEffect(() => {
        if (catchID) {
            const fee = fees.find(f => f.id === catchID.split('|')[0]);
            if (fee && fee.receipt_data) {
                // setProvidedMonths(fee.receipt_data)
                const defaultValues = {
                    idNo: fee.provider_id,
                    name: fee.name,
                    fatherName: fee.father_name,
                    classOrLevel: fee.class_or_level,
                    address: fee.address,
                    mobile: fee.mobile,
                    note: fee.note,
                    year: convertToBengaliDigits(fee.year),
                    selectedSubjects: fee.receipt_data.reduce((acc, receipt) => {
                        // Only proceed if `receipt` and its `selectedMonths` exist
                        if (receipt && receipt.selectedMonths) {
                            // Create an array for selected months, filtering out undefined values
                            const validSelectedMonths = Array(12).fill(null).map((_, i) => {
                                const monthNames = [
                                    "January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"
                                ];
                                return receipt.selectedMonths.includes(monthNames[i]) ? monthNames[i] : null;
                            }).filter(Boolean); // Remove nulls

                            // Set the subject and selectedMonths
                            acc[receipt.subject] = {
                                // isSelected: true, // Uncomment if needed
                                selectedMonths: validSelectedMonths
                            };
                        }
                        return acc;
                    }, {}) // Initialize as an empty object
                };
                reset(defaultValues);

                // Set the fees
                fee.receipt_data.reduce((acc, receipt) => {
                    if (receipt) {
                        setValue(receipt.subject, receipt.fee);
                    }
                    return acc;
                }, {});
            }
        }
    }, [fees, reset, catchID, setValue]);

    const lbls = ['idNo', 'name', 'fatherName', 'classOrLevel'];
    const lbls1 = ['address', 'mobile', 'note'];

    const [showRightBox, setShowRightBox] = useState(false);

    const handleToggle = () => {
        const data = getValues();

        const selectedData = Object.keys(data.selectedSubjects)
            .filter((subjectValue) => data.selectedSubjects[subjectValue].isSelected)
            .map((subjectValue) => {
                const subjectDetails = subjects.find((s) => s.value === subjectValue);
                if (!subjectDetails) return null;

                const selectedMonths = data.selectedSubjects[subjectValue].selectedMonths || [];
                const preSelectedMonths = fees?.find(am => am.subject === subjectValue)?.selectedMonths || [];

                // Find the subject data in receipt_data
                const perticulerFee = fees.find((f) => f.provider_id === serchID || catchID.split('|')[0]);
                const subjectData = (catchID || serchID) && perticulerFee?.receipt_data.find(fee => fee.subject === subjectValue);

                // Exclude months already present in preSelectedMonths and subjectData.selectedMonths
                const validMonths = selectedMonths.filter(month =>
                    !preSelectedMonths.includes(month) && !(subjectData?.selectedMonths || []).includes(month)
                );

                // Calculate totalAmount based on valid months
                const totalAmount = validMonths.reduce((total, month) => {
                    return total + subjectDetails.amount;
                }, 0);

                if (totalAmount === 0) {
                    return '';
                };
                const fee = subjectDetails.amount;

                return {
                    id: nanoid(10),
                    subject: subjectDetails.value,
                    selectedMonths: validMonths,  // Only the valid months
                    totalAmount,
                    fee
                };
            })
            .filter(Boolean);

        // Check if there's no valid selected data or the total amount is 0
        if (selectedData.length === 0 || selectedData[0].totalAmount === 0) {
            return enqueueSnackbar(t('requireText'), { variant: 'error' });
        }

        // For showing receipts
        const userInfo = {
            id: nanoid(10),
            idNo: data.idNo,
            name: data.name,
            fatherName: data.fatherName,
            classOrLevel: data.classOrLevel,
            address: data.address,
            mobile: data.mobile,
            note: data.note,
            year: parseInt(convertDigit(data.year))
        };
        setUserInfo(userInfo);
        setReceiptData(selectedData);

        // Go to next step
        setShowRightBox(!showRightBox);
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const [finished, setFinished] = useState(false);
    const [cutteReceipts, setCutteReceipts] = useState([]); // Store the cutted receipts

    // Handle the save action to move to the next subject
    const handleSaveReceipt = (cuttedReceiptData) => {
        if (currentIndex < receiptData.length - 1) {
            setCurrentIndex(currentIndex + 1); // Move to the next subject
        } else {
            setFinished(true)

            // combine datas to save in boarding department table
            const finalData = {
                ...userInfo,
                receiptData,
            }

            if (!catchID) {
                dispatch(newFee(finalData, code))
            } else {
                const updateData = {
                    id: catchID,
                    receiptData: receiptData
                }
                dispatch(updateFee(updateData, code))
            }
            dispatch({ type: NEW_RECEIPT_RESET })
        }

        // Update the cutteReceipts state by appending the new receipt data
        setCutteReceipts(prevReceipts => [...prevReceipts, cuttedReceiptData]);

    };

    const handleSearch = (id) => {
        setSerchID(id);
        const student = students.find((s) => s.admit_no === parseInt(id));

        const employee = staff.find((s) => s.id === id);
        const fee = fees.find((f) => f.provider_id === id);

        if (student && tableType === 'studentTable') {
            // Set default values for the table
            if (fee && fee.receipt_data) {
                const defaultValues = {
                    idNo: fee.provider_id,
                    name: fee.name,
                    fatherName: fee.father_name,
                    classOrLevel: fee.class_or_level,
                    address: fee.address,
                    mobile: fee.mobile,
                    note: fee.note,
                    year: convertToBengaliDigits(fee.year),
                    selectedSubjects: fee.receipt_data.reduce((acc, receipt) => {
                        // Only proceed if `receipt` and its `selectedMonths` exist
                        if (receipt && receipt.selectedMonths) {
                            // Create an array for selected months, filtering out undefined values
                            const validSelectedMonths = Array(12).fill(null).map((_, i) => {
                                const monthNames = [
                                    "January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"
                                ];
                                return receipt.selectedMonths.includes(monthNames[i]) ? monthNames[i] : null;
                            }).filter(Boolean); // Remove nulls

                            // Set the subject and selectedMonths
                            acc[receipt.subject] = {
                                isSelected: true, // Uncomment if needed
                                selectedMonths: validSelectedMonths
                            };
                        }
                        return acc;
                    }, {}) // Initialize as an empty object
                };
                reset(defaultValues);

                // Set the fees
                fee.receipt_data.reduce((acc, receipt) => {
                    if (receipt) {
                        setValue(receipt.subject, receipt.fee);
                    }
                    return acc;
                }, {});
                setProvider(true)
            } else {
                setValue('boardingFee', student.boarding_fee || 0);
                setValue('monthlyFee', student.monthly_fee || 0);
                setValue('residentialFee', student.living_fee || 0);
                setValue('electricityFee', student.electricity_bill || 0);
                setValue('name', student.name || '');
                setValue('fatherName', student.father_name || '');
                setValue('classOrLevel', student.admitted_class || '');
                setValue('address', `${student.village}, ${student.sub_district}, ${student.district}` || '');
                setValue('mobile', student.father_mobile || 0);
                setProvider(true)
            }
        } else if (employee && tableType === 'staffTable') {
            setValue('boardingFee', employee.boarding_fee || 0);
            setValue('monthlyFee', 0);
            setValue('residentialFee', 0);
            setValue('electricityFee', 0);
            setValue('name', employee.name || '');
            setValue('fatherName', employee.father_name || '');
            setValue('classOrLevel', employee.level || '');
            setValue('address', `${employee.permanent_village}, ${employee.permanent_sub_district}, ${employee.permanent_district}` || '');
            setValue('mobile', employee.mobile || 0);
            setProvider(true)
        } else {
            enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });
        }
    };

    const handleClear = () => {
        const defaultValues = {
            idNo: '',
            name: '',
            fatherName: '',
            classOrLevel: '',
            address: '',
            mobile: '',
            note: '',
            year: convertToBengaliDigits(new Date().getFullYear()),
            selectedSubjects: {}
        }
        reset(defaultValues);
        setProvider(false);
        setCatchID('');
    };

    const handlePdf = () => {
        const data = {
            department: 'boarding',
            selectedRows: cutteReceipts
        }
        dispatch(receiptsPdf(data, code));
        closeDialog();
    };

    const handleTableType = (type) => {
        setTableType(type);
    };

    return (
        <Container
            component="main" maxWidth="md" sx={{
                borderRadius: '10px',
            }}>
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <MetaData title={'MONEY ACCEPT FORM'} />
            <Box style={{ display: showRightBox ? 'None' : '' }} mt={3}>
                <RECEIPTHEADING text={t('acceptMoneyForm')} department={t('boarding')} />
                <Box component={'form'} onSubmit={handleSubmit(handleToggle)} encType='multipart/form-data'>
                    <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                        <Table aria-label="subject fee table">
                            <TableHead>
                                <TableRow>
                                    {lbls.map((lbl) => (
                                        lbl === 'idNo' ? (
                                            <TableCell
                                                align="center"
                                                rowSpan={2}
                                                sx={{ fontSize: '1rem', padding: '0px' }}
                                                key={lbl}
                                            >
                                                <Controller
                                                    name={'idNo'}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type='number'
                                                            variant="filled"
                                                            fullWidth
                                                            autoFocus
                                                            size="small"
                                                            label={t('idNo')}
                                                            required
                                                            disabled={catchID ? true : false}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            size='small'
                                                                            onClick={() => handleSearch(field.value)}
                                                                        >
                                                                            <Search />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                align="center"
                                                colSpan={lbl === 'name' ? '5' : '4'}
                                                sx={{ padding: '0px' }}
                                                key={lbl}
                                            >
                                                <Controller
                                                    name={lbl}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="filled"
                                                            fullWidth
                                                            required
                                                            size="small"
                                                            label={t(lbl)}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        )
                                    ))}
                                </TableRow>
                                <TableRow>
                                    {lbls1.map((lbl) => (
                                        <TableCell
                                            align="center"
                                            colSpan={lbl === 'address' ? '5' : '4'}
                                            sx={{ padding: '0px' }}
                                            key={lbl}
                                        >
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="filled"
                                                        fullWidth
                                                        required={lbl !== 'note'}
                                                        size="small"
                                                        label={t(lbl)}
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={'14'} align="center" sx={{ padding: '0px' }}>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography
                                                fontSize={'1rem'}
                                            >
                                                {t('yearlyAcceptMoneyRecord')}
                                            </Typography>

                                            <Controller
                                                name='year'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        hiddenLabel
                                                        id="filled-hidden-label-small"
                                                        variant="filled"
                                                        size="small"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: {
                                                                width: '110px',
                                                                height: '40px',
                                                                fontSize: '1rem'
                                                            },
                                                            inputProps: {
                                                                style: {
                                                                    paddingLeft: '5px'
                                                                },
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontSize: '15px', padding: '10px 3px 8px 3px', width: '18%' }}>{t('subjects')}</TableCell>
                                    {months.map(({ label, value }) => (
                                        <TableCell key={value} align="center" sx={{ fontSize: '15px', padding: '3px', width: '7%' }}>
                                            {label}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center" sx={{ fontSize: '15px', padding: '3px' }}>{t('amount')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {subjects?.map(({ label, value, amount }) => {
                                    const fee = fees.find((f) => f.provider_id === serchID || catchID.split('|')[0]);

                                    // Get the pre-selected months for this specific subject
                                    const preSelectedMonths = fee && (catchID || serchID) ? fees.flatMap(item =>
                                        item.receipt_data
                                            ?.filter(receipt => receipt.subject === value)
                                            .map(receipt => receipt.selectedMonths) || []
                                    ).flat() : [];

                                    return (
                                        <TableRow key={value}>
                                            <TableCell align="left" sx={{ display: 'flex', fontSize: '15px', padding: '5px', whiteSpace: 'nowrap' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Controller
                                                        name={`selectedSubjects.${value}.isSelected`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value || false}
                                                                sx={{ padding: '5px' }}
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    {label}
                                                    <Controller
                                                        name={value}
                                                        control={control}
                                                        defaultValue={amount}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                hiddenLabel
                                                                id="filled-hidden-label-small"
                                                                variant="filled"
                                                                size="small"
                                                                type='number'
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        width: '100px',
                                                                        height: '20px'
                                                                    },
                                                                    inputProps: {
                                                                        style: {
                                                                            paddingLeft: '0.1px'
                                                                        },
                                                                    },
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Typography>
                                                                                {t('moneySymbol')}
                                                                            </Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </TableCell>
                                            {months.map(({ value: monthValue }) => {
                                                // Check if the current month is pre-selected
                                                const isPreSelected = preSelectedMonths.includes(monthValue);

                                                return (
                                                    <TableCell key={monthValue} align="center" sx={{ fontSize: '15px', padding: '0px' }}>
                                                        <Controller
                                                            name={`selectedSubjects.${value}.selectedMonths`}
                                                            control={control}
                                                            defaultValue={''}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    checked={isPreSelected || field.value?.includes(monthValue)}
                                                                    size="small"
                                                                    sx={{ padding: '5px' }}
                                                                    disabled={isPreSelected || !watch(`selectedSubjects.${value}.isSelected`)}
                                                                    onChange={(e) => {
                                                                        if (isPreSelected) return;

                                                                        const selectedMonths = field.value || [];
                                                                        const newMonths = e.target.checked
                                                                            ? [...selectedMonths, monthValue]
                                                                            : selectedMonths.filter((m) => m !== monthValue);
                                                                        field.onChange(newMonths);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </TableCell>
                                                );
                                            })}

                                            <TableCell align="center" sx={{ fontSize: '15px', padding: '0px' }}>
                                                {watch(`selectedSubjects.${value}.isSelected`)
                                                    ? (watch(`selectedSubjects.${value}.selectedMonths`) || [])
                                                        // Filter out months already included in preSelectedMonths and your fees' receipt_data selectedMonths
                                                        .filter(month => !preSelectedMonths.includes(month))
                                                        .filter(month => {
                                                            // Find the corresponding subject data from your fees
                                                            const fee = fees.find((f) => f.provider_id === serchID || catchID.split('|')[0]);
                                                            const subjectData = (catchID || serchID) && fee?.receipt_data.find(fee => fee.subject === value);

                                                            return !(subjectData?.selectedMonths || []).includes(month); // Ignore months in receipt_data.selectedMonths
                                                        })
                                                        .reduce((total, month) => {
                                                            return total + amount;
                                                        }, 0)
                                                    : 0}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                <TableRow>
                                    <TableCell align="right" colSpan={13} sx={{ fontSize: '15px', padding: '3px' }}>
                                        {t('allTotal')}
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontSize: '15px', padding: '3px' }}>
                                        {Object.keys(watch('selectedSubjects') || {}).reduce((total, subjectValue) => {
                                            const isSelected = watch(`selectedSubjects.${subjectValue}.isSelected`);
                                            const selectedMonths = watch(`selectedSubjects.${subjectValue}.selectedMonths`) || [];
                                            const preSelectedMonths = fees?.find(am => am.subject === subjectValue)?.selectedMonths || [];
                                            const amount = subjects.find(s => s.value === subjectValue)?.amount || 0;

                                            // Find the subject data in your fees' receipt_data
                                            const fee = fees.find((f) => f.provider_id === serchID || catchID.split('|')[0]);
                                            const subjectData = (catchID || serchID) && fee?.receipt_data.find(fee => fee.subject === subjectValue);

                                            // Exclude pre-selected months and the months in receipt_data.selectedMonths
                                            const validMonths = selectedMonths.filter(month =>
                                                !preSelectedMonths.includes(month) && !(subjectData?.selectedMonths || []).includes(month)
                                            );

                                            // Calculate the total based on valid months
                                            return total + (isSelected ? validMonths.length * amount : 0);
                                        }, 0)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid container mt={2} mb={2}>
                        <Grid item xs={5}>
                            <FormControl component="fieldset">
                                <Controller
                                    name="tableType"
                                    control={control}
                                    defaultValue={tableType}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            row
                                            value={tableType}
                                            onChange={(event) => {
                                                field.onChange(event); // Ensure the field's onChange event is triggered
                                                handleTableType(event.target.value); // Custom handler
                                            }}

                                            sx={{
                                                display: 'flex',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 18,
                                                },
                                            }}
                                        >
                                            <FormControlLabel
                                                value="studentTable"
                                                control={<Radio />}
                                                label={`${t("student")} ${t("table")}`}
                                            />
                                            <FormControlLabel
                                                value="staffTable"
                                                control={<Radio />}
                                                label={`${t("staff")} ${t("table")}`}
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={7}>
                            <Grid container columnSpacing={1} justifyContent={'right'}>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        onClick={handleClear}
                                    >
                                        {t('clear')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        variant="outlined"
                                        disabled={!provider && !catchID}
                                    >
                                        <span>{t('next1')}</span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box style={{ display: !showRightBox ? 'None' : '' }}>
                <Box>
                    {Array.isArray(receiptData) && receiptData.length > 0 && !finished ? (
                        <Box>
                            <Paper sx={{ marginTop: '30px' }}>
                                <RECEIPTFORM
                                    receiptDepartment={'boarding'}
                                    text={`${t('receipt')} (${t(receiptData[currentIndex].subject)})`}
                                    receiptInfo={{
                                        receiptData: receiptData[currentIndex],
                                        userInfo: userInfo,
                                        type: 'acceptMoney'
                                    }}
                                    onSave={handleSaveReceipt} // Passing the save handler to RECEIPTFORM
                                />
                            </Paper>
                            <Grid container columnSpacing={1} mb={1.5} mt={1.5} justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item xs={7}>
                                    <Typography variant='body1'>
                                        {convertToBengaliDigits(receiptData.length - currentIndex)} {t('remainCuttingReceipt')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        onClick={handleToggle}
                                        variant="outlined"
                                    >
                                        {t('prev')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <Box sx={{ marginTop: '30px', marginBottom: '20px', padding: '10px', width: '400px' }}>
                            <Typography variant='body1'>
                                {t('successMessage')} {t('receiptDownload')}
                            </Typography>
                            <Grid container columnSpacing={1} mb={1.5} mt={1.5} justifyContent={'right'}>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color='success'
                                        onClick={handlePdf}
                                    >
                                        {t('yes')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color='error'
                                        onClick={closeDialog}
                                    >
                                        {t('no')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container >
    );
}
