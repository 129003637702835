import axios from 'axios';

import {
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
    ALL_PRODUCT_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    UPDATE_MATCHING_PRODUCT_REQUEST,
    UPDATE_MATCHING_PRODUCT_SUCCESS,
    UPDATE_MATCHING_PRODUCT_FAIL,
    ACCEPT_ALL_PRODUCT_REQUEST,
    ACCEPT_ALL_PRODUCT_SUCCESS,
    ACCEPT_ALL_PRODUCT_FAIL,
    NEW_SELLING_PRODUCT_REQUEST,
    NEW_SELLING_PRODUCT_SUCCESS,
    NEW_SELLING_PRODUCT_FAIL,
    ALL_SOLD_PRODUCT_REQUEST,
    ALL_SOLD_PRODUCT_SUCCESS,
    ALL_SOLD_PRODUCT_FAIL,
    UPDATE_SOLD_PRODUCT_REQUEST,
    UPDATE_SOLD_PRODUCT_SUCCESS,
    UPDATE_SOLD_PRODUCT_FAIL,
    DELETE_SOLD_PRODUCT_REQUEST,
    DELETE_SOLD_PRODUCT_SUCCESS,
    DELETE_SOLD_PRODUCT_FAIL,
    DASHBOARD_INFO_REQUEST,
    DASHBOARD_INFO_SUCCESS,
    DASHBOARD_INFO_FAIL,
    SETTINGS_UPDATE_REQUEST,
    SETTINGS_UPDATE_SUCCESS,
    SETTINGS_UPDATE_FAIL,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_FAIL,
    SEND_SMS_REQUEST,
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL,
    CLEAR_ERRORS,
    NEW_ARTICLE_REQUEST,
    NEW_ARTICLE_SUCCESS,
    NEW_ARTICLE_FAIL,
    ALL_ARTICLE_REQUEST,
    ALL_ARTICLE_SUCCESS,
    DELETE_ARTICLE_REQUEST,
    DELETE_ARTICLE_SUCCESS,
    DELETE_ARTICLE_FAIL,
    ALL_ARTICLE_FAIL,
    CHATGPT_REQUEST,
    CHATGPT_SUCCESS,
    CHATGPT_FAIL
} from '../constants/othersConstants'

// add new product by adminis 
export const newProduct = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PRODUCT_REQUEST })

        // passing formData to backend by this route 
        const { data } = await axios.post(`/api/others/product/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all products
export const getProducts = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_PRODUCT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/others/products/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PRODUCT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update products
export const updateProduct = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PRODUCT_REQUEST })

        // connect with backend for proudct update
        const { data } = await axios.put(`/api/others/product/update/${code}`, formData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update matching product
export const updateMatchedProducts = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_MATCHING_PRODUCT_REQUEST })

        // connect with backend for product update
        const { data } = await axios.put(`/api/others/product/matched/update/${code}`, formData)

        // showing success status if the product is updated
        dispatch({
            type: UPDATE_MATCHING_PRODUCT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_MATCHING_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete product
export const deleteProduct = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCT_REQUEST
        })

        // Sending a delete request to the backend to delete the product
        const { data } = await axios.delete(`/api/others/product/delete/${code}/${id}`)

        // showing success status if the product is deleted
        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new sold product
export const newSellingProduct = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_SELLING_PRODUCT_REQUEST })

        // passing formData to backend by this route 
        const { data } = await axios.post(`/api/others/product/selling/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_SELLING_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_SELLING_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all sold products
export const getSoldProducts = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_SOLD_PRODUCT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/others/sold/products/${code}`)

        // storing all sold products in payload
        dispatch({
            type: ALL_SOLD_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_SOLD_PRODUCT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update product
export const updateSoldProduct = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_SOLD_PRODUCT_REQUEST })

        // connect with backend for product update
        const { data } = await axios.put(`/api/others/product/sold_update/${code}`, formData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_SOLD_PRODUCT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_SOLD_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete product
export const deleteSoldProduct = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SOLD_PRODUCT_REQUEST
        })

        // Sending a delete request to the backend to delete the product
        const { data } = await axios.delete(`/api/others/sold_product/delete//${code}`, id)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_SOLD_PRODUCT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_SOLD_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Accept product
export const acceptProduct = (rowData, code) => async (dispatch) => {
    try {
        dispatch({
            type: ACCEPT_ALL_PRODUCT_REQUEST
        })

        // Sending a delete request to the backend to delete the product
        const { data } = await axios.post(`/api/others/product/accept/${code}`, rowData)

        // showing success status
        dispatch({
            type: ACCEPT_ALL_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ACCEPT_ALL_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getDashboardInfo = (code) => async (dispatch) => {
    try {

        dispatch({ type: DASHBOARD_INFO_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/others/dashboard/${code}`)

        // storing all users in payload
        dispatch({
            type: DASHBOARD_INFO_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DASHBOARD_INFO_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const updateSettings = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: SETTINGS_UPDATE_REQUEST })

        // passing formData to backend by this route 
        const { data } = await axios.post(`/api/others/settings/update/${code}`, formData)

        // store data in payload 
        dispatch({
            type: SETTINGS_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_UPDATE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getSettings = (code) => async (dispatch) => {
    try {

        dispatch({ type: SETTINGS_REQUEST })

        // connect with backend to get all settings
        const { data } = await axios.get(`/api/others/settings/${code}`)

        // storing all users in payload
        dispatch({
            type: SETTINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SETTINGS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const sendSMS = (messageData, code) => async (dispatch) => {
    try {

        dispatch({ type: SEND_SMS_REQUEST })

        // passing messageData to backend by this route 
        const { data } = await axios.post(`/api/others/sms_send/${code}`, messageData)

        // store data in payload 
        dispatch({
            type: SEND_SMS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SEND_SMS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const newArticle = (articleData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_ARTICLE_REQUEST })

        // passing messageData to backend by this route 
        const { data } = await axios.post(`/api/others/new_article/${code}`, articleData)

        // store data in payload 
        dispatch({
            type: NEW_ARTICLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_ARTICLE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const allArticles = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_ARTICLE_REQUEST })

        // connect with backend to get all articles
        const { data } = await axios.get(`/api/others/articles/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_ARTICLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_ARTICLE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const updateArticle = (articleData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_ARTICLE_REQUEST })

        // update article
        const { data } = await axios.post(`/api/others/articles/update/${code}`, articleData)

        // storing all users in payload
        dispatch({
            type: NEW_ARTICLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_ARTICLE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const deleteArticle = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ARTICLE_REQUEST
        })

        // Sending a delete request to the backend to delete the product
        const { data } = await axios.delete(`/api/others/article/delete/${code}/${id}`)

        // showing success status if the product is deleted
        dispatch({
            type: DELETE_ARTICLE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_ARTICLE_FAIL,
            payload: error.response.data.message
        })
    }
}


export const chatGPT = (code) => async (dispatch) => {
    try {
        dispatch({
            type: CHATGPT_REQUEST
        })

        // Sending a delete request to the backend to delete the product
        const { data } = await axios.delete(`/api/others/chatgpt//${code}`)

        // showing success status if the product is deleted
        dispatch({
            type: CHATGPT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CHATGPT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
