// React and related hooks
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { Box, Button, Grid, ButtonGroup, Menu, MenuItem, Autocomplete, TextField, Chip } from '@mui/material';
import {
    Edit, DeleteOutlined, SaveOutlined,
    Cancel, AddCircle, FileDownloadOutlined
} from '@mui/icons-material';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';

// Constants
import { bookNames, defaultClasses, defaultDepartments, defaultGroupNames } from '../../constants/commonContstants';

import {
    DataGrid,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    useGridApiContext,
    GridToolbarQuickFilter,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import { MetaData } from '../utils/metaData';
import { newClass, allClass, deleteClass } from '../../actions/eduActions';
import { classesListPdf } from '../../actions/pdfActions';
import { DELETE_CLASS_RESET, NEW_CLASS_RESET } from '../../constants/eduConstants';
import useAuth from '../hooks/UseAuth';
import { nanoid } from 'nanoid';
import { useDataGridColumns } from '../utils/useDataGridColumns';

function EditToolbar(props) {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        // const id = (props.rows ? props.rows.length : 0) + 1;
        const id = nanoid();
        setRows((oldRows) => [...oldRows, { id, department: '', groupname: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'department' },
        }));
    };

    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        // Get the current visibility model
        const excludedFields = ['__check__', 'actions'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('departAndBookList'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    const handlePdf = () => {
        dispatch(classesListPdf(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button
                            disabled={type !== 'education' && type !== 'superUser'}
                            aria-label="Add icon"
                            onClick={handleClick}
                        ><AddCircle />
                        </Button>
                        <Button
                            aria-label="Print icon"
                            onClick={handleDownloadFile}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('departAndBookList')} />
                </Grid>
                <Grid item xs={6} sm={3} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function CLASSLIST() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, type } = useAuth();
    // get redux state
    const { classes, loading } = useSelector(state => state.allClass);
    const { success } = useSelector(state => state.newClass);
    const { isDeletedOrUpdated } = useSelector(state => state.class);
    const { PDFLoading } = useSelector(state => state.PDF);

    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [rowId, setRowId] = React.useState(null);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
        dispatch(deleteClass({ id: id }, code))
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setRowId(id);
    };

    // for running when component is rendered
    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_CLASS_RESET })
        }
        if (isDeletedOrUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_CLASS_RESET })
        }
    }, [dispatch, success, t, enqueueSnackbar, isDeletedOrUpdated]);

    useEffect(() => {
        dispatch(allClass(code))
    }, [dispatch, code]);

    // Update rows state when classes change
    useEffect(() => {
        if (!loading && classes.length > 0) {
            const initialRows = classes?.map((classItem, index) => ({
                serial: index + 1,
                id: classItem.id,
                department: classItem.department,
                classname: classItem.class_name,
                groupname: classItem.group_name,
                book1: classItem.book1,
                book2: classItem.book2,
                book3: classItem.book3,
                book4: classItem.book4,
                book5: classItem.book5,
                book6: classItem.book6,
                book7: classItem.book7,
                book8: classItem.book8,
                book9: classItem.book9,
                book10: classItem.book10,
                book11: classItem.book11,
                book12: classItem.book12,
                book13: classItem.book13,
                book14: classItem.book14,
                book15: classItem.book15,
            }));
            setRows(initialRows);
        }
    }, [loading, classes]);

    // for saving new class
    const prevRowsRef = useRef(rows);
    useEffect(() => {
        // Check if rows have actually changed
        if (prevRowsRef.current !== rows && rowId) {
            const values = rows.find(row => row.id === rowId);
            if (values && !values.isNew) {
                const data = {
                    ...values
                }
                dispatch(newClass(data, code))
                setRowId(null);
            }
        }
        prevRowsRef.current = rows;
    }, [rowId, rows, dispatch, classes, code]);

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        {
            field: 'department',
            headerName: t('nameOfDepartment'),
            editable: true,
            renderEditCell: (params) => {
                const handleInputChange = (event, newValue) => {
                    params.api.setEditCellValue({
                        ...params,
                        value: newValue,
                    });
                };

                return (
                    <Autocomplete
                        freeSolo
                        value={params.value || ''}
                        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
                        options={defaultDepartments}
                        fullWidth
                        sx={{
                            marginLeft: '8px'
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true, // Remove the bottom border
                                }}
                            />
                        )}
                    />
                );
            },
        },
        {
            field: 'classname',
            headerName: t('className'),
            editable: true,
            renderEditCell: (params) => {
                const handleInputChange = (event, newValue) => {
                    params.api.setEditCellValue({
                        ...params,
                        value: newValue,
                    });
                };

                return (
                    <Autocomplete
                        freeSolo
                        value={params.value || ''}
                        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
                        options={defaultClasses}
                        fullWidth
                        sx={{
                            marginLeft: '8px'
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true, // Remove the bottom border
                                }}
                            />
                        )}
                    />
                );
            },
        },

        {
            field: 'groupname',
            headerName: t('group'),
            minWidth: 80,
            width: 80,
            flex: 0,
            editable: true,
            renderEditCell: (params) => {
                const handleInputChange = (event, newValue) => {
                    params.api.setEditCellValue({
                        ...params,
                        value: newValue,
                    });
                };

                return (
                    <Autocomplete
                        freeSolo
                        value={params.value || ''}
                        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
                        options={defaultGroupNames}
                        fullWidth
                        sx={{
                            marginLeft: '8px',
                        }}
                        renderInput={(inputParams) => (
                            <TextField
                                {...inputParams}
                                variant="standard"
                                InputProps={{
                                    ...inputParams.InputProps,
                                    disableUnderline: true,
                                }}
                            />
                        )}
                    />
                );
            },
        },

        ...Array.from({ length: 15 }, (_, index) => ({
            field: `book${index + 1}`,
            headerName: t(`${index + 1}Class`),
            editable: true,
            renderEditCell: createRenderEditCell(bookNames),
        })),

        {
            field: 'actions',
            type: 'actions',
            headerName: t('actions'),
            minWidth: 80,
            width: 80,
            flex: 0,
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            disabled={type !== 'education' && type !== 'superUser'}
                            icon={<SaveOutlined />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        disabled={type !== 'education' && type !== 'superUser'}
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        disabled={type !== 'education' && type !== 'superUser'}
                        icon={<DeleteOutlined />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]);

    function createRenderEditCell(options) {
        return (params) => {
            const handleInputChange = (event, newValue) => {
                params.api.setEditCellValue({
                    ...params,
                    value: newValue,
                });
            };

            return (
                <Autocomplete
                    freeSolo
                    value={params.value || ''}
                    onInputChange={(event, newValue) => handleInputChange(event, newValue)}
                    options={[...options]}
                    fullWidth
                    sx={{
                        marginLeft: '8px'
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true, // Remove the bottom border
                            }}
                        />
                    )}
                />
            );
        };
    }

    // for change mui defualt localtext of row selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'CLASS LIST'} />
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                loading={loading || PDFLoading}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={localeText}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showCellVerticalBorder
                showColumnVerticalBorder
                checkboxSelection
                sx={{
                    '& .MuiDataGrid-row--editing': {
                        boxShadow: 'none',
                    }
                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false, // for disabled checkbox selection
                            book11: false,
                            book12: false,
                            book13: false,
                            book14: false,
                            book15: false,
                            book16: false,
                            book17: false
                        },
                    },
                }}

                slots={{
                    toolbar: EditToolbar,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: { setRows, setRowModesModel, rows },
                }}
            />
        </Box>
    );
}
