// React and Hooks
import React, { useEffect } from 'react';
import { t } from "i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Material UI Components
import { Grid, Button, ButtonGroup, Box, Chip, Menu, MenuItem, Dialog } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, GridActionsCellItem, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';

// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Custom Components
import { MetaData } from '../utils/metaData';
import ADDNEWBOOKFORM from './AddBookForm';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Redux Actions
import { allBooks, deleteBook } from '../../actions/libraryActions';
import { barcodePDF, bookListPdf } from '../../actions/pdfActions';

// Constants
import { DELETE_BOOK_RESET, NEW_BOOK_RESET, UPDATE_BOOK_RESET } from '../../constants/libraryConstants';

// Custom Hooks
import useAuth from '../hooks/UseAuth';

function CustomToolbar({ handleOpen }) {
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    const [currentRows, setCurrentRows] = React.useState('');

    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'publisher' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('bookList'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(bookListPdf(currentRows, code))
    };

    const handleBarcodePDF = () => {
        dispatch(barcodePDF(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'managerAcademicLibrary' && type !== 'superUser'} onClick={handleOpen}><AddCircleIcon /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlinedIcon />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        <MenuItem onClick={handleBarcodePDF}>{t('barcodeFile')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('bookList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function BOOKLIST() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, type } = useAuth();
    const [selectedInfo, setSelectedInfo] = React.useState(null);

    const { books, loading } = useSelector(state => state.allBooks);
    const { isSaved } = useSelector(state => state.newBook);
    const { isDeleted, isUpdated } = useSelector(state => state.book);
    const { PDFLoading } = useSelector(state => state.PDF);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allBooks(code))
    }, [dispatch, code])

    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allBooks(code));
            dispatch({ type: NEW_BOOK_RESET })
        }
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allBooks(code));
            dispatch({ type: UPDATE_BOOK_RESET })
            setOpen(false);
        }
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allBooks(code));
            dispatch({ type: DELETE_BOOK_RESET })
        }
    }, [dispatch, isSaved, enqueueSnackbar, isUpdated, isDeleted, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'bookName', headerName: t('bookName') },
        { field: 'writer', headerName: t('writer') },
        { field: 'subject', headerName: t('justSubject') },
        { field: 'bookNo', headerName: t('bookNo'), minWidth: 80, sortable: true, flex: 0 },
        {
            field: 'part', headerName: t('part'), width: 80,
            minWidth: 80,
            flex: 0,
        },
        {
            field: 'price', headerName: t('price'), width: 80,
            minWidth: 80,
            flex: 0,
        },
        { field: 'storageLocation', headerName: t('storageLocation') },
        { field: 'publisher', headerName: t('publisher') },
        {
            field: 'status',
            headerName: t('status'),
            width: 80,
            minWidth: 80,
            flex: 0,
            renderCell: (params) => (
                params.row.status === 'in' ?
                    <CheckBoxOutlineBlankIcon fontSize='small' sx={{
                        marginTop: '7px',
                    }} />
                    :
                    <CheckBoxOutlinedIcon fontSize='small' sx={{
                        marginTop: '7px',
                    }} />
            ),
        },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            width: 80,
            minWidth: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'managerAcademicLibrary' && type !== 'superUser'}
                    icon={<EditIcon />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'managerAcademicLibrary' && type !== 'superUser'}
                    icon={<DeleteIcon
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform books array into the desired format
    const rows = books?.map((b, index) => {
        return {
            id: index + 1,
            bookNo: b.book_no,
            bookName: b.book_name,
            writer: b.writer,
            subject: b.subject,
            part: b.part,
            price: b.price,
            storageLocation: b.storage_location,
            publisher: b.publisher,
            status: b.condition,
        };
    });

    const handleEdit = (rowData) => {
        if (rowData.condition === 'out') { // prevent form editing if the monthly count is saved of this receipt
            enqueueSnackbar(t('sorryForProvided'), { variant: 'error' });
        } else {
            setSelectedInfo(rowData);
            setOpen(true);
        }
    };

    const handleDelete = (rowData) => {
        dispatch(deleteBook({ bookNo: rowData.bookNo }, code));
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setSelectedInfo(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'BOOK LIST'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <ADDNEWBOOKFORM closeDialog={handleClose} bookInfo={selectedInfo} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                loading={loading || PDFLoading}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            publisher: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />
                }}
            />
        </Box>
    );
}
