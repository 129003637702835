// React and related hooks
import React, { useEffect } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { updateApprovedBooks } from '../../actions/accountActions';

// MUI components and icons
import {
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    GridActionsCellItem
} from '@mui/x-data-grid';

import {
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Box,
    Dialog
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    FileDownloadOutlined
} from '@mui/icons-material';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Custom components and hooks
import useAuth from '../hooks/UseAuth';
import { CustomDataGrid, useDataGridColumns } from '../utils/useDataGridColumns';

// Utilities and constants
import { MetaData } from '../utils/metaData';
import { NEWARTICLE } from './NewArticle';
import { enqueueSnackbar } from 'notistack';
import { allArticles, deleteArticle } from '../../actions/othersActions';
import { stripHtmlTags } from '../utils/commonUtils';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleExportCsv}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('articles')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function ARTICLES() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const apiRef = useGridApiRef();

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    // fetch data from redux store
    const { PDFLoading } = useSelector(state => state.PDF);
    const { success } = useSelector(state => state.newArticle);
    const { articles } = useSelector(state => state.articles);

    const [pageCutted, setPageCuttedAmount] = React.useState('');

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            setOpen(false);
        }
        dispatch(allArticles(code))
    }, [dispatch, success, t, code]);

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('dataId') },
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'title', headerName: t('heading') },
        { field: 'subject', headerName: t('subject') },
        { field: 'author', headerName: t('writer') },
        { field: 'content', headerName: t('article'), editable: true },
        { field: 'date', headerName: t('date'), minWidth: 80, flex: 0 },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform articles array into the desired format
    const rows = articles?.map((article, index) => {
        const plainTextContent = stripHtmlTags(article.content); // Strip HTML tags
        const truncatedContent = plainTextContent.substring(0, 100); // Get the first 50 characters
        return {
            id: article.id,
            serial: index + 1,
            title: article.title,
            subject: t(article.subject),
            subject2: article.subject,
            author: article.author,
            content: truncatedContent,
            styledContent: article.content,
            date: dayjs(article.created_at).format('YYYY-MM-DD'),
        };
    });

    const handleEdit = (data) => {
        setOpen(true);
        setRowData(data);
    };

    const handleCellEditStart = (params) => {
        setPageCuttedAmount(params.row.cutted)
    };

    const handleProcessRowUpdate = (newRow) => {

        // handle not allowed amounts
        const newAprovedAmount = parseInt(newRow.pages);

        if (newAprovedAmount < pageCutted || newAprovedAmount <= 0) {
            apiRef.current.stopCellEditMode({ id: newRow.id, field: 'pages' });
            enqueueSnackbar(t('notWorked'), { variant: 'info' });
            return newRow;
        }

        // updating furniture given amount
        const info = {
            id: newRow.id,
            amount: newAprovedAmount
        }

        if (newRow.pages !== newAprovedAmount) {
            dispatch(updateApprovedBooks(info, code));
        }
        return newRow;
    };

    const handleDelete = (id) => {
        dispatch(deleteArticle(id, code))
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'ARTICLES'} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
            >
                <NEWARTICLE closeDialog={handleClose} rowData={rowData} />
            </Dialog>

            <CustomDataGrid
                rows={rows}
                columns={columns}
                localeText={localeText}
                apiRef={apiRef}
                loading={PDFLoading}
                onCellEditStart={handleCellEditStart}
                processRowUpdate={handleProcessRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => {
                    console.error('Row update failed: ', error);
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            aprover1: false,
                            id: false,
                        },
                    },
                }}
                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />
                }}
            />
        </Box>
    );
}