import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react'
import Avatar from '@mui/material/Avatar';
import { Card, CardMedia } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { MetaData } from '../utils/metaData'
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux'
import { registerUser, updateProfile, clearErrors } from '../../actions/authActions'
import { getUserTypes } from '../../constants/commonContstants';
import { useSnackbar } from 'notistack';
import { CustomCrossButton } from '../styles/style';
import CloseIcon from '@mui/icons-material/Close';
import { nanoid } from 'nanoid';
import useAuth from '../hooks/UseAuth';
import FormHelperText from '@mui/material/FormHelperText';

export default function SignUp({ id, closeDialog }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const userTypes = getUserTypes(t);
    const [catchID, setCatchID] = useState(id);
    const formRef = useRef(null);
    const [signPreview, setSignPreview] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('')
    const { code, email, type } = useAuth();

    // getting the value from resux according to the id for using by default
    const { users, error } = useSelector(state => state.allUsers);
    const findUser = users.find(user => user.id === id);

    const defaultValues = {
        id: findUser?.id || nanoid(), // nanoid for generating unique new id
        code: code,
        userType: findUser?.type || '',
        firstName: findUser?.first_name || '',
        lastName: findUser?.last_name || '',
        email: email,
        password: findUser?.password || '',
        cpassword: findUser?.password || '',
    };

    const { handleSubmit, control, reset } = useForm({ defaultValues });
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const dispatch = useDispatch();

    // import these info from reducers those are bound in store.js
    const { loading } = useSelector(state => state.auth);

    useEffect(() => {
        // for showing user and profile
        if (findUser && findUser.sign_url) {
            setSignPreview(findUser.sign_url);
        }

        if (findUser && findUser.avatar_url) {
            setAvatarPreview(findUser.avatar_url);
        }

        if (error) {
            dispatch(clearErrors());
        }

    }, [dispatch, findUser, error, t])


    // handle submit
    const onSubmit = (data) => {

        if (data.password.toString().length < 5) {
            return enqueueSnackbar(t('requireText'), { variant: 'error' });
        }
        // checking duplicate
        const findUser = users?.find(user => user.password === data.password);

        if (findUser && !catchID) {
            return enqueueSnackbar(t('strongPassword'), { variant: 'error' });
        }

        // password confirmation
        if (data.password !== data.cpassword) {
            return enqueueSnackbar(t('notConfirmedPassword'), { variant: 'error' });
        }

        // Create FormData object
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            if (key === 'avatar' || key === 'sign') {
                if (data[key] instanceof File) {
                    formData.append(key, data[key]);
                }
            } else {
                formData.append(key, data[key]);
            }
        });

        if (catchID) {
            dispatch(updateProfile(formData, code));
        } else {
            dispatch(registerUser(formData, code));
        }
    };

    // handle clear
    const handleClear = () => {
        const emptyValues = {
            userType: '',
            firstName: '',
            lastName: ''
        };
        reset(emptyValues);
        setSignPreview('')
        setAvatarPreview('')
        setCatchID('')
    };

    const lbls = ['userType', 'email', 'firstName', 'lastName'];

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'USER REGISTER'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('registerForm')} department={t('users')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                key={`grid-${index}`}
                            >
                                {lbl === 'userType' ? (
                                    <Controller
                                        key={`controller-${index}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                size="small"
                                                id={`id${index}`}
                                                select
                                                label={t('selectUserType')}
                                                disabled={type !== 'admin'}
                                                required
                                            >
                                                {userTypes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                ) : (
                                    <Controller
                                        key={`controller-${index}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                size="small"
                                                id={`id${index}`}
                                                label={t(lbl)}
                                                disabled={lbl === 'email' ? true : undefined}
                                                required
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        ))}

                        <Grid item xs={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size='small'
                            >
                                <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            required
                                        />
                                    )}
                                />
                                <FormHelperText>* {t('passwordHelperText')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size='small'
                            >
                                <InputLabel htmlFor="outlined-adornment-password">{t('confirmPassword')}</InputLabel>
                                <Controller
                                    name="cpassword"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            id="cpassword"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="confirmPassword"
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container item justifyContent={'center'}>
                            <Grid item xs={5} display={'flex'} justifyContent={'center'} borderBottom={'1px solid #ecc'} paddingBottom={1}>
                                <FormControl>
                                    <Controller
                                        name="sign"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <>
                                                {signPreview ?
                                                    <Button component='label'>
                                                        <Card elevation={0}>
                                                            <CardMedia
                                                                component="img"
                                                                height="40"
                                                                image={signPreview}
                                                                alt="signpreview"
                                                            />
                                                        </Card>
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files[0]); // Update sign form value
                                                                const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.onload = () => {
                                                                    setSignPreview(reader.result); // Update sign preview
                                                                };
                                                                if (file) {
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                    :
                                                    <Button
                                                        component="label"
                                                    >
                                                        {t('uploadSign')}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files[0]); // Update sign form value
                                                                const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setSignPreview(reader.result); // Update sign preview
                                                                };
                                                                if (file) {
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                                {/* <Divider orientation="vertical" /> */}
                            </Grid>
                            <Grid item xs={5} paddingBottom={1} display={'flex'} justifyContent={'center'} borderBottom={'1px solid #ecc'}>
                                <FormControl>
                                    <Controller
                                        name="avatar"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <>
                                                {avatarPreview ?
                                                    <Box display='flex'>
                                                        <Avatar
                                                            alt="avatar_pic"
                                                            src={avatarPreview}
                                                            sx={{ width: 50, height: 50 }}
                                                        />
                                                        <Button
                                                            component="label">
                                                            {t('uploadAvatar')}
                                                            <input
                                                                type="file"
                                                                hidden
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.files[0]); // Update avatar value
                                                                    const file = e.target.files[0];
                                                                    field.onChange(file);
                                                                    const reader = new FileReader();
                                                                    reader.onloadend = () => {
                                                                        setAvatarPreview(reader.result); // Update avatar preview
                                                                    };
                                                                    if (file) {
                                                                        reader.readAsDataURL(file);
                                                                    }
                                                                }}
                                                            />
                                                        </Button>
                                                    </Box>
                                                    :
                                                    <Button
                                                        component="label"
                                                    >
                                                        {t('uploadAvatar')}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files[0]); // Update avatar value
                                                                const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setAvatarPreview(reader.result); // Update avatar preview
                                                                };
                                                                if (file) {
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} columnSpacing='4' mb={3} mt={2} alignItems={'center'}>
                        {/* <Grid item xs={7} sx={{
                            justifyContent: 'left',
                            alignItems: 'left',
                            display: 'flex',
                        }}>
                            <Button
                                onClick={handleLogin}
                            >
                                <Link href="#" style={{ color: 'green' }}>
                                    {t('poweredBy')}
                                </Link>
                            </Button>
                        </Grid> */}
                        <Grid item container columnSpacing={1} xs={12} sx={{
                            justifyContent: 'right',
                            display: 'flex',
                        }}>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='error'
                                    onClick={handleClear}
                                >
                                    {t('clear')}
                                </Button>
                            </Grid>
                            {catchID ?
                                <Grid item>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                    >
                                        {t('update')}
                                    </Button>
                                </Grid>
                                :
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading ? true : false}
                                        variant="contained"
                                    >
                                        <span>{t('registration')}</span>
                                    </LoadingButton>
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}