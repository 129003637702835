import {
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
    ALL_PRODUCT_FAIL,
    NEW_PRODUCT_RESET,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_RESET,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_RESET,
    ACCEPT_ALL_PRODUCT_REQUEST,
    ACCEPT_ALL_PRODUCT_SUCCESS,
    ACCEPT_ALL_PRODUCT_FAIL,
    ACCEPT_ALL_PRODUCT_RESET,
    NEW_SELLING_PRODUCT_REQUEST,
    NEW_SELLING_PRODUCT_SUCCESS,
    NEW_SELLING_PRODUCT_FAIL,
    NEW_SELLING_PRODUCT_RESET,
    UPDATE_SOLD_PRODUCT_REQUEST,
    DELETE_SOLD_PRODUCT_REQUEST,
    UPDATE_SOLD_PRODUCT_SUCCESS,
    UPDATE_SOLD_PRODUCT_RESET,
    UPDATE_SOLD_PRODUCT_FAIL,
    DELETE_SOLD_PRODUCT_FAIL,
    DELETE_SOLD_PRODUCT_SUCCESS,
    DELETE_SOLD_PRODUCT_RESET,
    ALL_SOLD_PRODUCT_REQUEST,
    ALL_SOLD_PRODUCT_SUCCESS,
    ALL_SOLD_PRODUCT_FAIL,
    DASHBOARD_INFO_REQUEST,
    DASHBOARD_INFO_SUCCESS,
    DASHBOARD_INFO_FAIL,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SETTINGS_FAIL,
    SEND_SMS_REQUEST,
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL,
    SEND_SMS_RESET,
    CLEAR_ERRORS,
    NEW_ARTICLE_REQUEST,
    NEW_ARTICLE_SUCCESS,
    NEW_ARTICLE_FAIL,
    ALL_ARTICLE_REQUEST,
    ALL_ARTICLE_SUCCESS,
    ALL_ARTICLE_FAIL,
    DELETE_ARTICLE_REQUEST,
    DELETE_ARTICLE_SUCCESS,
    DELETE_ARTICLE_FAIL
} from '../constants/othersConstants'
import { DOWNLOAD_PDF_FAILURE, DOWNLOAD_PDF_REQUEST, DOWNLOAD_PDF_SUCCESS } from '../constants/pdfConstants'

// for add new product
export const newProductReducer = (state = { product: {} }, action) => {
    switch (action.type) {

        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_PRODUCT_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                product: action.payload
            }

        case NEW_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false
            }

        // for actions
        case UPDATE_PRODUCT_REQUEST:
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isProductUpdated: action.payload
            }

        case UPDATE_PRODUCT_RESET:
            return {
                ...state,
                isProductUpdated: false
            }

        case UPDATE_PRODUCT_FAIL:
        case DELETE_PRODUCT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isProductDeleted: action.payload
            }

        case DELETE_PRODUCT_RESET:
            return {
                ...state,
                isProductDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all products
export const allProductReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case ALL_PRODUCT_REQUEST:
            return {
                loading: true,
                products: []
            }

        case ALL_PRODUCT_SUCCESS:
            return {
                loading: false,
                products: action.payload.products
            }

        case ALL_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new selling product
export const newSellingProductReducer = (state = { soldProduct: {} }, action) => {
    switch (action.type) {

        case NEW_SELLING_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_SELLING_PRODUCT_SUCCESS:
            return {
                loading: false,
                sellingSuccess: action.payload,
                soldProduct: action.payload
            }

        case NEW_SELLING_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_SELLING_PRODUCT_RESET:
            return {
                ...state,
                sellingSuccess: false
            }

        // for actions
        case UPDATE_SOLD_PRODUCT_REQUEST:
        case DELETE_SOLD_PRODUCT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_SOLD_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: action.payload
            }

        case UPDATE_SOLD_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_SOLD_PRODUCT_FAIL:
        case DELETE_SOLD_PRODUCT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_SOLD_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isDeleted: action.payload
            }

        case DELETE_SOLD_PRODUCT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all sold products
export const allSoldProductReducer = (state = { soldProducts: [] }, action) => {
    switch (action.type) {
        case ALL_SOLD_PRODUCT_REQUEST:
            return {
                loading: true,
                soldProducts: []
            }

        case ALL_SOLD_PRODUCT_SUCCESS:
            return {
                loading: false,
                soldProducts: action.payload.soldProducts
            }

        case ALL_SOLD_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// product actions
export const productReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCEPT_ALL_PRODUCT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case ACCEPT_ALL_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isAccepted: true
            }

        case ACCEPT_ALL_PRODUCT_RESET:
            return {
                ...state,
                isAccepted: false
            }

        case ACCEPT_ALL_PRODUCT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const dashboardReducer = (state = { informations: {} }, action) => {
    switch (action.type) {
        case DASHBOARD_INFO_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DASHBOARD_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                informations: action.payload.data
            }

        case DASHBOARD_INFO_FAIL:
            return {
                ...state,
                loading: false,
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const settingsReducer = (state = { allSettings: {} }, action) => {
    switch (action.type) {
        case SETTINGS_REQUEST:
            return {
                loading: true,
                allSettings: {}
            }

        case SETTINGS_SUCCESS:
            return {
                loading: false,
                allSettings: action.payload.settigns
            }

        case SETTINGS_FAIL:
            return {
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const sendSMSReducer = (state = { messages: {} }, action) => {
    switch (action.type) {
        case SEND_SMS_REQUEST:
            return {
                loading: true,
                messages: {}
            }

        case SEND_SMS_SUCCESS:
            return {
                loading: false,
                success_sms: true,
                messages: action.payload.messages
            }

        case SEND_SMS_RESET:
            return {
                success_sms: false
            }

        case SEND_SMS_FAIL:
            return {
                error_sms: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error_sms: null
            }

        default:
            return state;
    }
}

export const PDFReducer = (state = {}, action) => {
    switch (action.type) {
        case DOWNLOAD_PDF_REQUEST:
            return {
                PDFLoading: true,
            }

        case DOWNLOAD_PDF_SUCCESS:
            return {
                PDFLoading: false,
            }

        case DOWNLOAD_PDF_FAILURE:
            return {
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const newArticleReducer = (state = {}, action) => {
    switch (action.type) {
        case NEW_ARTICLE_REQUEST:
        case DELETE_ARTICLE_REQUEST:
            return {
                loading: true
            }

        case NEW_ARTICLE_SUCCESS:
        case DELETE_ARTICLE_SUCCESS:
            return {
                loading: false,
                success: action.payload
            }

        case NEW_ARTICLE_FAIL:
        case DELETE_ARTICLE_FAIL:
            return {
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error_sms: null
            }

        default:
            return state;
    }
}

export const allArticlesReducer = (state = { articles: [] }, action) => {
    switch (action.type) {
        case ALL_ARTICLE_REQUEST:
            return {
                loading: true,
                articles: []
            }

        case ALL_ARTICLE_SUCCESS:
            return {
                loading: false,
                articles: action.payload.articles
            }

        case ALL_ARTICLE_FAIL:
            return {
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}