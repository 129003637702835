// for store management
export const NEW_PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST'
export const NEW_PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS'
export const NEW_PRODUCT_FAIL = 'NEW_PRODUCT_FAIL'
export const NEW_PRODUCT_RESET = 'NEW_PRODUCT_RESET'

export const ALL_PRODUCT_REQUEST = 'ALL_PRODUCT_REQUEST'
export const ALL_PRODUCT_SUCCESS = 'ALL_PRODUCT_SUCCESS'
export const ALL_PRODUCT_RESET = 'ALL_PRODUCT_RESET'
export const ALL_PRODUCT_FAIL = 'ALL_PRODUCT_FAIL'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const ALL_SOLD_PRODUCT_REQUEST = 'ALL_SOLD_PRODUCT_REQUEST'
export const ALL_SOLD_PRODUCT_SUCCESS = 'ALL_SOLD_PRODUCT_SUCCESS'
export const ALL_SOLD_PRODUCT_RESET = 'ALL_SOLD_PRODUCT_RESET'
export const ALL_SOLD_PRODUCT_FAIL = 'ALL_SOLD_PRODUCT_FAIL'

export const UPDATE_SOLD_PRODUCT_REQUEST = 'UPDATE_SOLD_PRODUCT_REQUEST'
export const UPDATE_SOLD_PRODUCT_SUCCESS = 'UPDATE_SOLD_PRODUCT_SUCCESS'
export const UPDATE_SOLD_PRODUCT_RESET = 'UPDATE_SOLD_PRODUCT_RESET'
export const UPDATE_SOLD_PRODUCT_FAIL = 'UPDATE_SOLD_PRODUCT_FAIL'

export const DELETE_SOLD_PRODUCT_REQUEST = 'DELETE_SOLD_PRODUCT_REQUEST'
export const DELETE_SOLD_PRODUCT_SUCCESS = 'DELETE_SOLD_PRODUCT_SUCCESS'
export const DELETE_SOLD_PRODUCT_RESET = 'DELETE_SOLD_PRODUCT_RESET'
export const DELETE_SOLD_PRODUCT_FAIL = 'DELETE_SOLD_PRODUCT_FAIL'

export const ACCEPT_ALL_PRODUCT_REQUEST = 'ACCEPT_ALL_PRODUCT_REQUEST'
export const ACCEPT_ALL_PRODUCT_SUCCESS = 'ACCEPT_ALL_PRODUCT_SUCCESS'
export const ACCEPT_ALL_PRODUCT_RESET = 'ACCEPT_ALL_PRODUCT_RESET'
export const ACCEPT_ALL_PRODUCT_FAIL = 'ACCEPT_ALL_PRODUCT_FAIL'

export const UPDATE_MATCHING_PRODUCT_REQUEST = 'UPDATE_MATCHING_PRODUCT_REQUEST'
export const UPDATE_MATCHING_PRODUCT_SUCCESS = 'UPDATE_MATCHING_PRODUCT_SUCCESS'
export const UPDATE_MATCHING_PRODUCT_RESET = 'UPDATE_MATCHING_PRODUCT_RESET'
export const UPDATE_MATCHING_PRODUCT_FAIL = 'UPDATE_MATCHING_PRODUCT_FAIL'

export const NEW_SELLING_PRODUCT_REQUEST = 'NEW_SELLING_PRODUCT_REQUEST'
export const NEW_SELLING_PRODUCT_SUCCESS = 'NEW_SELLING_PRODUCT_SUCCESS'
export const NEW_SELLING_PRODUCT_FAIL = 'NEW_SELLING_PRODUCT_FAIL'
export const NEW_SELLING_PRODUCT_RESET = 'NEW_SELLING_PRODUCT_RESET'

export const DASHBOARD_INFO_REQUEST = 'DASHBOARD_INFO_REQUEST'
export const DASHBOARD_INFO_SUCCESS = 'DASHBOARD_INFO_SUCCESS'
export const DASHBOARD_INFO_FAIL = 'DASHBOARD_INFO_FAIL'

export const SETTINGS_UPDATE_REQUEST = 'SETTINGS_UPDATE_REQUEST'
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS'
export const SETTINGS_UPDATE_FAIL = 'SETTINGS_UPDATE_FAIL'

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const SETTINGS_FAIL = 'SETTINGS_FAIL'

export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST'
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS'
export const SEND_SMS_FAIL = 'SEND_SMS_FAIL'
export const SEND_SMS_RESET = 'SEND_SMS_RESET'

export const NEW_ARTICLE_REQUEST = 'NEW_ARTICLE_REQUEST'
export const NEW_ARTICLE_SUCCESS = 'NEW_ARTICLE_SUCCESS'
export const NEW_ARTICLE_FAIL = 'NEW_ARTICLE_FAIL'

export const ALL_ARTICLE_REQUEST = 'ALL_ARTICLE_REQUEST'
export const ALL_ARTICLE_SUCCESS = 'ALL_ARTICLE_SUCCESS'
export const ALL_ARTICLE_FAIL = 'ALL_ARTICLE_FAIL'

export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'

export const CHATGPT_REQUEST = 'CHATGPT_REQUEST'
export const CHATGPT_SUCCESS = 'CHATGPT_SUCCESS'
export const CHATGPT_FAIL = 'CHATGPT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'