import {
    DataGrid
} from '@mui/x-data-grid';
import CustomPagination from '../layout/Pagination';

// Step 1: Define global default column settings
const defaultColumnSettings = {
    sortable: false,
    flex: 1,
    minWidth: 120,
    disableColumnMenu: true,
};

// Step 2: Utility function to apply default settings and allow selective overrides
export const useDataGridColumns = (columns) => {
    return columns.map((col) => ({ ...defaultColumnSettings, ...col }));
};

export const CustomDataGrid = ({ rows,
    columns,
    loading,
    initialState,
    localeText,
    getRowClassName,
    isRowSelectable,
    sx,
    slots,
    onRowSelectionModelChange,
    apiRef,
    onCellEditStart,
    processRowUpdate,
    experimentalFeatures,
    onProcessRowUpdateError,
    selectionModel
}) => {
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            density={'compact'}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            checkboxSelection
            scrollbarSize={0}
            onRowSelectionModelChange={onRowSelectionModelChange}
            selectionModel={selectionModel}
            localeText={localeText}
            showCellVerticalBorder={true}
            showColumnVerticalBorder={true}
            getRowClassName={getRowClassName}
            isRowSelectable={isRowSelectable}
            apiRef={apiRef}
            onCellEditStart={onCellEditStart}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={experimentalFeatures}
            onProcessRowUpdateError={onProcessRowUpdateError}
            sx={{
                '& .highlight-row': {
                    backgroundColor: '#FFFFF0'
                },
                '& .zeroRemainRow': {
                    backgroundColor: '#FFEAEB',
                },
                '& .warning-row': {
                    backgroundColor: '#FFEAEB',
                },
                '& .danger-row': {
                    backgroundColor: '#eaeaea',
                },
                // '& .warning-row': {
                //     backgroundColor: theme.palette.mode === 'dark' ? '#430000' : '#FFEAEB'
                // },
                // '& .danger-row': {
                //     backgroundColor: theme.palette.mode === 'dark' ? '#440000' : '#eaeaea'
                // },
                ...sx,
            }}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 100 },
                },
                ...initialState
            }}
            slots={{
                pagination: CustomPagination,
                ...slots,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                }
            }}
        />
    );
};
